const initialState = {
  purchaseDetailsLoading: true,
  purchaseDetails: null,

  addbidLoading: false,

  razorPaySuccessCaptureLoading: false,
  razorPayData: {},

  bidTransactions: {},
  bidTransactionsLoading: false,
};

const bidPaymentReducer = (state = initialState, action) => {
  switch (action.type) {
    //GET PURCHASES INFORMATION
    case "GET_BID_TRANSACTIONS":
      return {
        ...state,
        bidTransactionsLoading: true,
      };
    case "GET_BID_TRANSACTIONS_SUCCESS":
      return {
        ...state,
        bidTransactionsLoading: false,
        bidTransactions: action.payload,
      };
    case "GET_BID_TRANSACTIONS_FAILED":
      return {
        ...state,
        bidTransactionsLoading: false,
      };
    //GET PURCHASE INFORMATION
    case "GET_BID_PURCHASE_INFORMATION":
      return {
        ...state,
        purchaseDetailsLoading: true,
      };
    case "GET_BID_PURCHASE_INFORMATION_SUCCESS":
      return {
        ...state,
        purchaseDetailsLoading: false,
        purchaseDetails: action.payload,
      };
    case "GET_BID_PURCHASE_INFORMATION_FAILED":
      return {
        ...state,
        purchaseDetailsLoading: false,
      };

    //USER ADD SUBSCRIPTIO PLAN

    case "USER_POST_BID":
      return {
        ...state,
        addbidLoading: true,
      };
    case "USER_POST_BID_SUCCESS":
      return {
        ...state,
        addbidLoading: false,
        addSubscriptionPlanDetails: action.payload,
      };
    case "USER_POST_BID_FAILED":
      return {
        ...state,
        addbidLoading: false,
      };

    //RAZOR PAY CAPTURE CCAVENUE

    case "USER_BID_RAZORPAY_PAYMENT_CAPTURE":
      return {
        ...state,
        razorPaySuccessCaptureLoading: true,
      };
    case "USER_BID_RAZORPAY_PAYMENT_CAPTURE_SUCCESS":
      return {
        ...state,
        razorPaySuccessCaptureLoading: false,
        razorPayData: action.payload,
      };
    case "USER_BID_RAZORPAY_PAYMENT_CAPTURE_FAILED":
      return {
        ...state,
        razorPaySuccessCaptureLoading: false,
      };

    default:
      return state;
  }
};

export default bidPaymentReducer;
