import { isValidParam, getObjectParam } from "./paramVerifier";
import store from "../store";

import crypto from "crypto";
const algo = "aes-256-cbc";

const iv = "c999cbd1f130db1d";
const key = "5b0904cfada01b8182bcc029b928244d";

export function parseJWT(token) {
  let base64Url = token.split(".")[1];
  let base64 = base64Url.replace("-", "+").replace("_", "/");
  return JSON.parse(window.atob(base64));
}

export const isLoggedIn = () => {
  return localStorage.getItem("jwt") ? true : false;
};

export const getToken = () => {
  return localStorage.getItem("jwt");
};

export const setToken = (token) => {
  localStorage.setItem("jwt", token);
};

export const deleteToken = () => {
  localStorage.clear("jwt");
};

export const formatDate = (date) => {
  let formatedDated = "";
  if (isValidParam(date) && date != "") {
    date = new Date(date);
    let month = "" + (date.getMonth() + 1),
      day = "" + date.getDate(),
      year = date.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    formatedDated = [day, month, year].join("-");
  }
  return formatedDated;
};

export const parseDate = (date) => {
  let parseDate = null;
  if (isValidParam(date) && date != "") {
    parseDate = new Date(date);
  }
  return parseDate;
};

export const showSnackBar = (msg, props) => {
  try {
    if (isValidParam(msg)) {
      let state = store.getState();
      let snackBar = getObjectParam(state.appReducer.snackBar);

      props = getObjectParam(props);
      props = { ContentProps: { style: { fontSize: 14 } }, ...props };
      snackBar.enqueueSnackbar(msg, props);
    }
  } catch (error) {
    console.error("Error in globalFunctions.js => showSnackBar(): " + error);
  }
};

export const encryptText = (data) => {
  try {
    const cipher = crypto.createCipheriv(algo, key, iv);
    // console.log(data);
    var encrypted = cipher.update(data, "utf8", "base64");
    // console.log("encrypted", encrypted);
    encrypted += cipher.final("base64");
    return encrypted.toString("base64");
  } catch (error) {
    console.log(error);
  }
};

export const decryptText = (data) => {
  try {
    const decipher = crypto.createDecipheriv(algo, key, iv);
    // console.log("decipher=", decipher);

    var decrypted = decipher.update(data, "base64", "utf8");
    // console.log("decrypted=", decrypted);

    decrypted += decipher.final("utf8");
    // console.log("decrypted=", decrypted);

    return decrypted.toString("utf8");
  } catch (error) {
    console.log(error);
  }
};

export const kFormatter = (num) => {
  switch (true) {
    case 1000000 > num && num > 999:
      return (num / 1000).toFixed(1) + "K";
    case 1000000000 > num && num > 999999:
      return (num / 1000).toFixed(1) + "M";
    case 1000000000000 > num && num > 999999999:
      return (num / 1000).toFixed(1) + "B";
    default:
      return num;
  }
};
