const initialState = {
  users: null,
  currentUserProfile: null,
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_USERS_SUCCESS":
      return {
        ...state,
        users: action.payload.data.data,
      };
    case "CLEAR_USERS_SUCCESS":
      return {
        ...state,
        users: null,
      };
    case "GET_USER_PROFILE_DETAILS":
      return {
        ...state,
        currentUserProfile: action.payload.data.data,
      };

    default:
      return state;
  }
};

export default usersReducer;
