import { createAction } from "redux-actions";
import request from "../helpers/requestEngine";
import request1 from "../helpers/request";
import getURL from "../helpers/apis";
import snackbar from "snackbar";
import { openDialog, closeDialog } from "./appActions";
import qs from 'qs';
import { authenticateLoginCredentials } from "./loginActions";

const requestCountriesSuccess = createAction("FETCH_ALL_COUNTRIES_SUCCESS");
const requestCountriesFailure = createAction("FETCH_ALL_COUNTRIES_FAILURE");

export function requestCountries() {
  return (dispatch) =>
    request("get", "GET_COUNTRIES").then(
      (response) => dispatch(requestCountriesSuccess(response)),
      (error) => dispatch(requestCountriesFailure(error))
    );
}

const requestStatesSuccess = createAction("FETCH_STATES_SUCCESS");
const requestStatesFailure = createAction("FETCH_STATES_FAILURE");

export function requestStates(countryName) {
  return (dispatch) =>
    request1("get", `${getURL("GET_STATES")}?country=${countryName}`).then(
      (response) => dispatch(requestStatesSuccess(response)),
      (error) => dispatch(requestStatesFailure(error))
    );
}

const requestDistrictsSuccess = createAction("FETCH_DISTRICTS_SUCCESS");
const requestDistrictsFailure = createAction("FETCH_DISTRICTS_FAILURE");

export function requestDistricts(countryName, stateName) {
  return (dispatch) =>
    request1("get", `${getURL("GET_DISTRICTS")}?state=${stateName || ''}&country=${countryName}`).then(
      (response) => dispatch(requestDistrictsSuccess(response)),
      (error) => dispatch(requestDistrictsFailure(error))
    );
}

// const requestIndustriesSuccess = createAction("FETCH_INDUSTRIES_SUCCESS");
// const requestIndustriesFailure = createAction("FETCH_INDUSTRIES_FAILURE");

// export function requestIndustries() {
//   return (dispatch) =>
//     request("get", "GET_INDUSTRIES").then(
//       (response) => dispatch(requestIndustriesSuccess(response)),
//       (error) => dispatch(requestIndustriesFailure(error))
//     );
// }

export const onEmailChanged = (e) => {
  return {
    type: "REGISTER_EMAIL_CHANGED",
    email: e.target.value,
  };
};

export const onPasswordChanged = (e) => {
  return {
    type: "REGISTER_PASSWORD_CHANGED",
    password: e.target.value,
  };
};

export const onUsernameChanged = (e) => {
  return {
    type: "REGISTER_USERNAME_CHANGED",
    username: e.target.value,
  };
};

export const onFirstNameChanged = (e) => {
  return {
    type: "REGISTER_FIRSTNAME_CHANGED",
    firstname: e.target.value,
  };
};
export const onLastNameChanged = (e) => {
  return {
    type: "REGISTER_LASTNAME_CHANGED",
    lastname: e.target.value,
  };
};

export const onCheckBoxToggle = (e) => {
  return {
    type: "REGISTER_CHECKBOX_TOGGLE",
  };
};

export const setInitialRegisterState = () => {
  return {
    type: "SET_INITIAL_REGISTER_STATE",
  };
};

const registering = () => {
  return {
    type: "REGISTERING_USER",
  };
};

export const onRegisterFormSubmitted = (formData, callBack) => {
  let newFormData = {
    firstname: formData.firstname,
    lastname: formData.lastname,
    email: formData.email,
    username: formData.username,
    bidAlias: formData.bidAlias,
    password: formData.password,
    confirmPassword: formData.confirmPassword,
    gender: formData.gender,
    dob: formData.dob,
    phone: formData.phone,
    // houseno: formData.houseno,
    // street: formData.street,
    // city: formData.city,
    country: formData.country.country,
    // state: formData.state,
    // district: formData.district.district,
    // pincode: formData.pincode,
  };

  console.log("newFormData :>> ", newFormData);

  if (!formData.termsOfServicesCheckBox) {
    return snackbar.show(
      "Please agree to terms and conditions to move forward."
    );
  }

  return (dispatch) => {
    dispatch(registering());
    request("post", "SIGN_UP", false, qs.stringify(newFormData)).then(
      (response) => {
        if (response.data.error) {
          // snackbar.show("Something went wrong. Please try again.");
          dispatch(
            openDialog(
              response.data.data || "Something went wrong. Please try again."
            )
          );
        }
        if (response.data.data) {
          // snackbar.show("Registered Successfully.");
          localStorage.setItem("userId", response.data.existingUser._id);
          localStorage.setItem("jwt", response.data.data);
          localStorage.setItem("username", response.data.existingUser.username);
          callBack();
          return dispatch(registeredSuccessful(response.data));
        }
      },
      (err) => {
        if (err.response.data.error) {
          let errors = err.response.data.errors;
          try {
            if (errors && errors.constructor.name === "Array") {
              if (errors[0].msg === "USER_ALREADY_EXISTS") {
                // snackbar.show("Already signed up.");
                dispatch(openDialog("Already signed up."));
              }
              if (errors[0].isEmailExist) {
                // snackbar.show("This email already exists.");
                dispatch(openDialog("This email already exists."));
              }
              if (errors[0].isMobileExist) {
                // snackbar.show("This phone number already exists.");
                dispatch(openDialog("This phone number already exists."));
              }
            } else {
              // snackbar.show("Something went wrong. Please try again.");
              dispatch(
                openDialog(
                  err.response.data.data ||
                    "Something went wrong. Please try again."
                )
              );
            }
          } catch (e) {
            // snackbar.show("Something went wrong. Please try again.");
            dispatch(
              openDialog(
                err.response.data.data ||
                  "Something went wrong. Please try again."
              )
            );
          }
        }
      }
    );
  };
};

export const registeredSuccessful = ({ data, existingUser }) => {
  return {
    type: "REGISTRATION_SUCCESS",
    jwt: data,
    userDetail: existingUser,
  };
};

export function requestNeighbourhood(countryName, stateName, districtName) {
  return (dispatch) =>
    request1("get", `${getURL("GET_NEIGHBOURHOOD")}?district=${districtName}&country=${countryName}&state=${stateName || ''}`).then(
      (response) => dispatch(requestNeighbourhoodSuccess(response)),
      (error) => dispatch(requestNeighbourhoodFailure(error))
    );
}

const requestNeighbourhoodSuccess = createAction("FETCH_NEIGHBOURHOOD_SUCCESS");
const requestNeighbourhoodFailure = createAction("FETCH_NEIGHBOURHOOD_FAILURE");