// import React from "react";
// import { Button } from "../components/Buttons";
// import { AddOnEmailInput, AddOnPasswordInput } from "../components/AddOnInputs";
// import { AuGroup } from "../components/AuFormComponent";
// import { resetPassword } from "../actions/loginActions";
import withRouter from "react-router-dom/es/withRouter";
// import Link from "react-router-dom/es/Link";
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
// import { Grid, TextField, Typography } from "@material-ui/core";

import React, {useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { resetPassword } from "../actions/loginActions";
import ShowPasswordComponent from "../components/ShowPasswordComponent";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://ibids.in/">
        iBids
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function ResetPassword(props) {
  const classes = useStyles();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const onSubmitForm = (e)=>{
    e.preventDefault();
    const q = props.match.params.q;
    const formData = {
      password: password,
      rePassword: confirmPassword,
      q: q,
    };
    props.dispatch(resetPassword(formData, props.history))
  }

  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false)



  return (
    <Container component="main" maxWidth="xs" style={{ margin: "auto" }}>
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          iBids
        </Typography>
        <form className={classes.form} noValidate onSubmit={e=>onSubmitForm(e)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type={showNewPassword ? "text": "password"}
                id="password"
                value={password}
                onChange={e=>setPassword(e.target.value)}
                InputProps={{
                  endAdornment: <ShowPasswordComponent showPassword={showNewPassword} changeFunction={setShowNewPassword} />
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
              required
                fullWidth
                name="confirm_password"
                label="Confirm Password"
                type={showConfirmNewPassword ? "text" :"password"}
                id="confirm_password"
                value={confirmPassword}
                onChange={e=>setConfirmPassword(e.target.value)}
                InputProps={{
                  endAdornment: <ShowPasswordComponent showPassword={showConfirmNewPassword} changeFunction={setShowConfirmNewPassword} />
                }}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Reset Password
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              I remember it now, take me back to &nbsp;{" "}
              <Link variant="body2" component={RouterLink} to={"/login"}>
                Login
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}

export default connect(null)(withRouter(ResetPassword));
