import axios from "axios";
import getURL from "./apis";
import { logout } from "../actions/appActions";
import { deleteToken } from "./globalFunctions";
import snackbar from "snackbar";

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    if (
      (err.response.status === 403 || err.response.data.status === 403) &&
      err.response.data.msg === "TOKEN_EXPIRED"
    ) {
      deleteToken();
      snackbar.show("Your session expired. Please login again.");
    } else {
      throw err;
    }
  }
);

const request = (
  type = "get",
  urlName,
  auth = false,
  payload = null,
  params = {},
  extraConfig
) => {
  console.log("payload", payload);
  const authToken = auth ? localStorage.getItem("jwt") : null;
  let headers = {};
  if (authToken) {
    headers["Authorization"] = authToken;
  }

  if (typeof extraConfig !== "undefined") {
    Object.keys(extraConfig).map((config) => {
      headers[config] = extraConfig[config];
    });
  }

  return axios({
    method: type,
    url: getURL(urlName),
    data: payload,
    headers: headers,
    params: params,
  });
};

export default request;

export const sendHTTPRequest = (
  type = "get",
  url,
  auth = false,
  payload = null,
  params = {},
  extraConfig
) => {
  const authToken = auth ? localStorage.getItem("jwt") : null;
  let headers = {};
  if (authToken) {
    headers["Authorization"] = authToken;
  }

  if (typeof extraConfig !== "undefined") {
    Object.keys(extraConfig).map((config) => {
      headers[config] = extraConfig[config];
    });
  }

  return new Promise((resolve, reject) => {
    if (authToken != null) {
      axios({
        method: type,
        url: url,
        data: payload,
        headers: headers,
        params: params,
      })
        .then((resp) => {
          if (resp.data.error) {
            alert("Server error!");
          } else {
            resolve(resp.data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    }
  });
};
