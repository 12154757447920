import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Typography,
  Button,
  Box,
  Grid,
  Select,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { constants } from "../../helpers/constants";
import { addBid } from "../../actions/AdbidActions";
import { openDialog } from "../../actions/appActions";
import Moment from "react-moment";
import moment from "moment";

const BidForm = ({ addBid, id, classes, userDetail, openDialog }) => {
  const baseamt = 200000;
  const user_id = localStorage.getItem("userId");
  // const [subs, setSubs] = useState([]);

  const [formData, setformData] = useState({
    user_id: user_id,
    // company: "",
    bidamt: "",
    type: "",
    biddate: moment().format(),
    slotdate: moment(id).format(),
  });
  const handleChange = (e) => {
    setformData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.user_id) {
      return openDialog("Please login first");
    } else if (
      // formData.company === "" ||
      formData.type === "" ||
      formData.type === "Select Type" ||
      formData.bidamt === ""
    ) {
      return openDialog("Please enter all details");
    } else if (formData.bidamt <= baseamt) {
      return openDialog("Please Enter amount more than base amount.");
    }
    //  else if (formData.company.length < 3) {
    //   return openDialog("Mininum bid username length is 3");
    // } else if (new RegExp(subs.join("|")).test(formData.company)) {
    //   return openDialog(
    //     "Bid username can’t contain any 3 consecutive characters of the login user name."
    //   );
    // }
    else {
      setformData({ ...formData, biddate: moment().format() });
      addBid(formData);
    }
  };
  // const getAllSubstrings = (str, size) => {
  //   var i,
  //     j,
  //     result = [];
  //   size = size || 0;
  //   for (i = 0; i < str.length; i++) {
  //     for (j = str.length; j - i >= size; j--) {
  //       result.push(str.slice(i, j));
  //     }
  //   }
  //   return result;
  // };

  // useEffect(() => {
  //   if (userDetail) {
  //     var arr = getAllSubstrings(userDetail.username, 3);
  //     setSubs(arr);
  //   }
  // }, [userDetail]);
  return (
    <Box style={{ margin: 12 }}>
      <Typography component="div" variant="h5">
        Post Bid
      </Typography>
      <form onSubmit={(e) => handleSubmit(e)}>
        <Grid container spacing={2} justify="center">
          <Grid item xs={6} spacing={1}>
            <Grid container spacing={1}>
              <Grid item xs={5}>
                <Typography
                  style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                >
                  Alias Name*
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  name="company"
                  placeholder="Alias Name"
                  value={userDetail && userDetail.bidAlias}
                  disabled
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={5}>
                <Typography
                  style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                >
                  Bid Type*
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Select
                  name="type"
                  value={formData.type}
                  onChange={(e) => handleChange(e)}
                  fullWidth
                >
                  <MenuItem disabled>Select Type</MenuItem>
                  <MenuItem value={"Open"}>Open</MenuItem>
                  <MenuItem value={"Hidden"}>Hidden</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                >
                  Bid Amount*
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  type="number"
                  name="bidamt"
                  placeholder="Bid amount"
                  value={formData.bidamt}
                  onChange={(e) =>
                    (Number(e.target.value) > 0 || e.target.value === "") &&
                    setformData({ ...formData, bidamt: e.target.value })
                  }
                  fullWidth
                  required
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={1} justify="center">
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default connect(null, { addBid, openDialog })(BidForm);
