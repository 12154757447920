import { deleteToken, parseJWT } from "../helpers/globalFunctions";
import request from "../helpers/requestEngine";
import { sendHTTPRequest } from "../helpers/requestEngine";
// // import snackbar from "snackbar";
import { setToken } from "../helpers/globalFunctions";
import getUrl from "../helpers/apis";
import { getUserDetails as fetchUserDetails } from "./userDetailsAction";

export const getUserAddress = (params) => {
  let url = getUrl("GET_USER_ADDRESS");
  let promise = Promise.resolve(sendHTTPRequest("get", url, true, {}, params));
  return promise;
};

export function updateProfile(formData) {
  return (dispatch) => {
    return request("post", "UPDATE_PROFILE", true, formData).then(
      (response) => {
        if (!response.data.error) {
          dispatch(openDialog("Profile updated successfully!"));

          return dispatch({ type: "UPDATE_USER_DETAILS", response });
        }
        response.data.errors.map((err) => {
          // snackbar.show(err);
          dispatch(openDialog(err));
        });
        return response.data.error;
      },
      (err) => {
        // snackbar.show("Could not update your profile.");
        dispatch(openDialog("Could not update your profile."));

        return err;
      }
    );
  };
}


// write reducer to handle socket connection

export const getCategories = () => {
  return (dispatch) => {
    dispatch(fetchingCategories());

    return dispatch(fetchCategories());
  };
};

function fetchingCategories() {
  return {
    type: "GET_CATEGORIES",
  };
}

function fetchCategories() {
  return (dispatch) => {
    request("get", "GET_CATEGORIES", true).then(
      (response) => {
        console.log("response==========", response.data.data);
        dispatch(getCategoriesSuccess(response.data.data));
      },
      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(
          openDialog(
            err.response.data.data || "Something went wrong. Please try again."
          )
        );

        dispatch(getCategoriesFailed(err));
      }
    );
  };
}

function getCategoriesSuccess(data) {
  return {
    type: "GET_CATEGORIES_SUCCESS",
    payload: data,
  };
}

function getCategoriesFailed(err) {
  return {
    type: "GET_CATEGORIES_FAILED",
  };
}
export const openDialog = (text, description) => {
  return (dispatch) =>
    dispatch({ type: "OPEN_DIALOG", payload: { text, description } });
};

export const closeDialog = () => {
  return (dispatch) => dispatch({ type: "CLOSE_DIALOG" });
};

export const openAvatarModal = (imageSrc) => {
  return (dispatch) =>
    dispatch({ type: "OPEN_AVATAR_MODAL", payload: { imageSrc } });
};

export const closeAvatarModal = () => {
  return (dispatch) => dispatch({ type: "CLOSE_AVATAR_MODAL" });
};

export const refillJWT = () => {
  const jwt = localStorage.getItem("jwt");
  const userId = localStorage.getItem("userId");

  if (jwt && userId) {
    return (dispatch) => dispatch(fetchUserDetails(jwt, userId));
  }

  return (dispatch) => dispatch(logout());
};

function saveUserToState(jwt, userDetail) {
  return {
    jwt,
    userDetail,
    type: "LOGIN_SUCCESS",
  };
}

export function logout() {
  deleteToken();
  return {
    type: "LOGGED_OUT",
  };
}

export const getUserDetails = (user_id) => {
  let promise = Promise.resolve(
    sendHTTPRequest("get", getUrl("GET_USER_INFO"), true, {}, { user_id })
  );
  return promise;
  // return request('get', 'GET_USER_INFO', true, {}, {user_id})
  //     .then(response=>{ return response.data.data });
};

export const getSubCategories = (category) => {
  return (dispatch) => {
    dispatch(fetchingSubCategories());

    return dispatch(fetchSubCategories(category));
  };
};

function fetchingSubCategories() {
  return {
    type: "GET_SUB_CATEGORIES",
  };
}

function fetchSubCategories(category) {
  return (dispatch) => {
    
    request("get", "GET_SUB_CATEGORIES", true, null, {category: category}).then(
      (response) => {
        console.log("response==========", response.data.data);
        dispatch(getSubCategoriesSuccess(response.data.data));
      },
      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(
          openDialog(
            err.response.data.data || "Something went wrong. Please try again."
          )
        );

        dispatch(getSubCategoriesFailed(err));
      }
    );
  };
}

function getSubCategoriesSuccess(data) {
  return {
    type: "GET_SUB_CATEGORIES_SUCCESS",
    payload: data,
  };
}

function getSubCategoriesFailed(err) {
  return {
    type: "GET_SUB_CATEGORIES_FAILED",
  };
}

export function getManufacturerMake(subCategory) {
  return (dispatch) => {
    
    request("get", "GET_MANUFACTURER_MAKE", true, null, {subcategory: subCategory}).then(
      (response) => {
        dispatch(getManufacturerMakeSuccess(response.data.data));
      },
      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(
          openDialog(
            err.response.data.data || "Something went wrong. Please try again."
          )
        );

        dispatch(getManufacturerMakeFailed(err));
      }
    );
  };
}

function getManufacturerMakeSuccess(data) {
  return {
    type: "GET_MANUFACTURER_MAKE_SUCCESS",
    payload: data,
  };
}

function getManufacturerMakeFailed(err) {
  return {
    type: "GET_MANUFACTURER_MAKE_FAILED",
  };
}

export function getManufacturerModel(make, subCategory) {
  return (dispatch) => {
    
    request("get", "GET_MANUFACTURER_MODEL", true, null, {subcategory: subCategory, make: make}).then(
      (response) => {
        dispatch(getManufacturerModelSuccess(response.data.data));
      },
      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(
          openDialog(
            err.response.data.data || "Something went wrong. Please try again."
          )
        );

        dispatch(getManufacturerModelFailed(err));
      }
    );
  };
}

function getManufacturerModelSuccess(data) {
  return {
    type: "GET_MANUFACTURER_MODEL_SUCCESS",
    payload: data,
  };
}

function getManufacturerModelFailed(err) {
  return {
    type: "GET_MANUFACTURER_MODEL_FAILED",
  };
}

export function getManufacturerYear(subCategory, make) {
  return (dispatch) => {
    
    request("get", "GET_MANUFACTURER_YEAR", true, null, {}).then(
      (response) => {
        dispatch(getManufacturerYearSuccess(response.data.data));
      },
      (err) => {
        // snackbar.show("Something went wrong.");
        dispatch(
          openDialog(
            err.response.data.data || "Something went wrong. Please try again."
          )
        );

        dispatch(getManufacturerYearFailed(err));
      }
    );
  };
}

function getManufacturerYearSuccess(data) {
  return {
    type: "GET_MANUFACTURER_YEAR_SUCCESS",
    payload: data,
  };
}

function getManufacturerYearFailed(err) {
  return {
    type: "GET_MANUFACTURER_YEAR_FAILED",
  };
}