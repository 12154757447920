import React from "react";
import { Controller } from "react-hook-form";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { IconButton, InputAdornment, TextField } from "@material-ui/core";
export default function ControlledInput({
  helperText,
  error,
  type = "text",
  ...props
}) {
  return (
    <Controller
      {...props}
      render={({ field }) => (
        <TextField
          {...field}
          defaultValue=""
          type={props.showPassword ? "text" :type}
          helperText={helperText}
          error={error}
          fullWidth
            InputProps={type==="password" ? {
            endAdornment: <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={()=>props.ChangeVisibilityFunction(!props.showPassword)}
              // onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {props.showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>,
          }: {}}
        />
      )}
    />
  );
}
