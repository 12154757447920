// const base_url = "http://localhost:3002";
export const base_url = "https://api.ibids.in";

const urlBank = {
  BASE_URL: "/",
  SIGN_IN: "/api/auth/signin",
  SIGN_UP: "/api/auth/signup",
  GET_USER_SETTINGS: "/api/user/getAll_User_Settings",
  CHANGE_SETTING: "/api/user/",
  GET_USER_INFO: "/api/user",
  GET_USER_DETAILS_BY_USERNAME: "/api/user/name?user_name=",
  UPDATE_PROFILE: "/api/user/editProfile",
  GET_ALL_USER_PROFILES: "/api/user/pagination",
  GET_USER_ACCESS_PAGES: "/api/user/getUserAccessPages",
  UPLOADS: "/uploads/",
  FORGOT_PASSWORD: "/api/auth/forgotPassword",
  RESET_PASSWORD: "/api/resetPassword",
  CHANGE_PASSWORD: "/api/user/changePassword",
  GALLERY_UPLOAD: "/api/gallery",
  GALLERY_REMOVE: "/api/gallery/remove",
  THUMBNAIL_UPLOAD: "/api/gallery/thumbnail/upload",
  GET_COUNTRIES: "/api/address/getCountry",
  GET_STATES: "/api/address/getState",
  GET_DISTRICTS: "/api/address/getDistrict",
  GET_INDUSTRIES: "/api/auth/industries",
  GET_USER_ADDRESS: "/api/user/getUserAddress",
  GET_USER_DETAILS: "/api/user/id?user_id=",
  GET_USERS: "/api/user/getusers",
  GET_USERS_BY_KEYWORD: "/api/user/searchUser",
  GET_CATEGORIES: "/api/posts/category",
  GET_ITEM_ADS_BY_CATEGORY: "/api/posts/filter",
  GET_GLOBAL_ITEM_ADS_BY_CATEGORY: "/api/globalAds",
  GET_MY_ADS_BY_CATEGORY: "/api/posts/myPosts",
  GET_AD_GALLERIES_BY_ID: "/api/gallery/media",
  GET_ITEM_AD_GALLERIES_FOR_UPDATE: "/api/gallery/mediaUpdate",
  POST_ITEM_AD: "/api/posts/",
  UPDATE_ITEM_AD: "/api/posts/edit",
  GET_AD_BY_UID: "/api/posts/postUID",
  GET_AD_BY_ID: "/api/posts/post",
  POST_BID: "/api/bid/",
  GET_BIDS: "/api/bid/bids/",
  GET_PRODUCT_FEATURES_LIST: "/api/product",
  GET_PRODUCT_FEATURES_BY_TYPE: "/api/product/usertype?userType=",
  GET_USER_SUBSCRIPTION: "/api/cart/subscription/list?status=",
  GET_UPGRADE_INFO: "/api/cart/subscription/upgradeinfo/",
  GET_PURCHASE_INFO: "/api/cart/subscription/purchaseInfo/",
  USER_CHOOSE_SUBSCRIPTION_PLAN: "/api/cart/subscription",
  USER_ADD_SUBSCRIPTION_TO_CART: "/api/cart/add",
  USER_RAZORPAY_PAYMENT_CAPTURE_SUCCESS: "/api/cart/payment/ccavenue/success",

  POST_ADBID: "/api/adbid/",
  GET_ADBIDS: "/api/adbid/bids/",
  GET_ADBID_EVENTS: "/api/adbid/bid-events",

  GET_ADSPACE_DETAILS: "/api/space/space",
  GET_ADSPACE_PURCHASE_DETAILS: "/api/space/purchaseInfo/",
  ADD_ADSPACE_TO_CART: "/api/space/add/",
  ADSPACE_RAZORPAY_PAYMENT_CAPTURE_SUCCESS:
    "/api/space/payment/ccavenue/success",
  GET_USER_ADSPACE: "/api/space/space/list?status=",
  POST_ADGALLERIES: "/api/space/",
  GET_AD_GALLERIES_FOR_UPDATE: "/api/space/mediaUpdate",
  ADGALLERY_REMOVE: "/api/space/removeGallery",
  GET_ADS_BETWEEN_POSTS: "/api/space/active",

  USER_BID_RAZORPAY_PAYMENT_CAPTURE_SUCCESS: "/api/bidpay/payment/success",
  GET_BID_PURCHASE_INFO: "/api/bidpay/purchaseInfo/",
  USER_POST_BID: "/api/bidpay/add/",
  GET_BID_TRANSACTIONS: "/api/bidpay/listPurchases",

  GET_MEMBERSHIP_TRANSACTIONS: "/api/cart/listTransactions",

  FINAL_CALL: "/api/bid/finalCall",
  CLOSE_BID: "/api/bid/closeBid",

  USER_REVIEW: "/api/review/",

  POST_SHARE_BID: "/api/buyShare/",
  GET_SHARE_BIDS: "/api/buyShare/bids/",
  USER_SHARE_BID_RAZORPAY_PAYMENT_CAPTURE_SUCCESS:
    "/api/buyShare/payment/success",
  GET_SHARE_BID_PURCHASE_INFO: "/api/buyShare/purchaseInfo/",
  USER_POST_SHARE_BID: "/api/buyShare/add/",
  GET_SHARE_BID_TRANSACTIONS: "/api/buyShare/listPurchases",
  MAKE_REQUEST_TO_SHOW_BUYER_MOBILE_NUMBER: "/api/request",
  GET_REQUEST_STATUS: "/api/request/status",
  GET_NOTIFICATIONS: "/api/notification",
  UPDATE_NOTIFICATION_STATUS: "/api/request/status",
  UPDATE_PROFILE_PIC: "/api/user/uploadProfilePic",

  GET_ALL_USER_BIDS: "/api/bid/getBids",
  GET_LOGIN_USER_BIDS: "/api/bid/getUserBids",

  SEND_CONTACT: "/api/contactus",
  GET_SUB_CATEGORIES: "/api/posts/subcategory",

  GET_NEIGHBOURHOOD: "/api/address/getNeighbourhood",
  GET_MANUFACTURER_MAKE: "/api/manufacturer/getMakes",
  GET_MANUFACTURER_MODEL: "/api/manufacturer/getModels",
  GET_MANUFACTURER_YEAR: "/api/posts/manufacturingYears",

  GET_AD_BY_SEARCH: "/api/posts/searchAds",
  OTP_PHONE: "/api/auth/signin/otp/phone/send",
  OTP_PHONE_VERIFY: "/api/auth/signin/otp/phone/verify",
  RESEND_OTP: "/api/auth/signin/otp/phone/resend",
};

const getURL = (key) => {
  if (key && typeof urlBank[key] != "undefined") {
    return base_url + urlBank[key];
  } else {
    throw new Error("Url is absent from url bank.");
  }
};

export default getURL;
