import React, { useState, useEffect, useMemo, useRef } from "react";
import { connect } from "react-redux";
import { getBids, finalCall, closeBid, getAllUsersBids } from "../../actions/bidActions";
import Moment from "react-moment";
import {
  Typography,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tab,
  Tabs,
  AppBar,
  Grid,
  Avatar,
  Link,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import PersonIcon from "@material-ui/icons/Person";
import { openAvatarModal } from "../../actions/appActions";
import getURL from "../../helpers/apis";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import moment from "moment";
import { Pagination } from "@material-ui/lab";
import TablePagination from '@material-ui/core/TablePagination';


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const CustomTab = withStyles({
  root: {
    textTransform: "capitalize"
  }
})(Tab);

const BidFeed = (props) => {
  const {
    bids,
    itemAd,
    highestOpenBidAmount,
    highestHiddenBidAmount,
    dispatch,
    owner,
    isAllUserBids,
    setIsAllUserBids,
    setIsAfterFinalCall,
    isAfterFinalCall,
    setPage,
    page
  } = props
  console.log(props, 123)
  const [overall, setOverall] = useState(null);
  const [highestOpen, setHighestOpen] = useState(null);
  const [highestHidden, setHighestHidden] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isFinalCall, setIsFinalCall] = useState(0);
  var history = useHistory();




  const params = useRef({
    isAfterFinalCall: false,
    isAllUserBids: true,
    page: 1
  });

  useEffect(() => {
    params.current = {
      isAfterFinalCall: isAfterFinalCall,
      isAllUserBids: isAllUserBids,
      page: page
    }
    getBidsFromAPi()
  }, [isAfterFinalCall, isAllUserBids, page]);

  const selectedBids = useMemo(
    () => (itemAd.finalCallMade ? isFinalCall ? bids.afterFinalCallBids : bids.beoreFinalCallBids : bids.beoreFinalCallBids),
    [bids, isFinalCall]
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    // setAnchorEl(null);
  };
  useEffect(() => {
    getBidsFromAPi()

    const intervalRef = setInterval(() => {
      getBidsFromAPi()
    }, 10 * 1000);

    return () => clearInterval(intervalRef);
  }, [dispatch, itemAd._id]);

  const getBidsFromAPi = () => {
    // dispatch(getBids({ item_id: itemAd._id, page: 1, limit: 5 }));
    dispatch(getAllUsersBids({ item_id: itemAd._id, page: params.current.page, limit: 10, isAfterFinalCall: params.current.isAfterFinalCall }, params.current.isAllUserBids, props.userDetails));
  }

  const handleFinalCall = (event, value) => {
    event.preventDefault();
    event.stopPropagation();
    setIsFinalCall(value);
    setPage(1)
    setIsAfterFinalCall(value)
  };

  useEffect(() => {
    window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });
  }, [isFinalCall]);
  const [open, setOpen] = useState(false)
  const [date, setDate] = useState(null)

  const renderHighestBidComponent = (text, username, amount, userId) => {
    return(
      <Typography variant="h6" color="secondary">
        <span style={{ color: 'black' }}>{text}</span> {itemAd.currencycode || '₹'}{" "}
        {Number(amount).toLocaleString("en-IN")} {owner ? <><span style={{ color: 'black' }}>-</span> <Link style={{ cursor: 'pointer' }} onClick={() => history.push(`/user_profile/${userId}`)} >{username}</Link></> : <></>}
      </Typography>
    )
  }

  const getHighestBids = () => {
    if (owner) {
      if (itemAd.finalCallMade) {
        return (
          <>
            { bids.beforeFinalCallHighestOpenUsername && renderHighestBidComponent("Before final call highest open bid:", bids.beforeFinalCallHighestOpenUsername, bids.beforeFinalCallHighestOpenBid, bids.beforeFinalCallHighestOpenUserId)}
            { bids.beforeFinalCallHighestHiddenUsername && renderHighestBidComponent("Before final call highest hidden bid:", bids.beforeFinalCallHighestHiddenUsername, bids.beforeFinalCallHighestHiddenBid, bids.beforeFinalCallHighestHiddenUserId)}
            { bids.afterFinalCallHighestOpenUsername && renderHighestBidComponent("After final call highest open bid:", bids.afterFinalCallHighestOpenUsername, bids.afterFinalCallHighestOpenBid, bids.afterFinalCallHighestOpenUserId)}
            { bids.afterFinalCallHighestHiddenUsername && renderHighestBidComponent("After final call highest hidden bid:", bids.afterFinalCallHighestHiddenUsername, bids.afterFinalCallHighestHiddenBid, bids.afterFinalCallHighestHiddenUserId)}
          </>
        )
      } else {
        return (
          <>
            { bids.beforeFinalCallHighestOpenUsername && renderHighestBidComponent("Highest open bid:", bids.beforeFinalCallHighestOpenUsername, bids.beforeFinalCallHighestOpenBid, bids.beforeFinalCallHighestOpenUserId)}
            { bids.beforeFinalCallHighestHiddenUsername && renderHighestBidComponent("Highest hidden bid:", bids.beforeFinalCallHighestHiddenUsername, bids.beforeFinalCallHighestHiddenBid, bids.beforeFinalCallHighestHiddenUserId)}
          </>
        )
      }
    } else {
      if (!itemAd.finalCallMade) { // finalCall false
        return (
          <>
            { bids.beforeFinalCallHighestOpenUsername && renderHighestBidComponent("Highest open bid:", bids.beforeFinalCallHighestOpenUsername, bids.beforeFinalCallHighestOpenBid, bids.beforeFinalCallHighestOpenUserId)}
          </>
        )
      } else { //finalCall true
        if(itemAd.bidOpen){
          return (
            <>
              { bids.beforeFinalCallHighestOpenUsername && renderHighestBidComponent("Before final call highest open bid:", bids.beforeFinalCallHighestOpenUsername, bids.beforeFinalCallHighestOpenBid, bids.beforeFinalCallHighestOpenUserId)}
              { bids.beforeFinalCallHighestHiddenUsername && renderHighestBidComponent("Before final call highest hidden bid:", bids.beforeFinalCallHighestHiddenUsername, bids.beforeFinalCallHighestHiddenBid, bids.beforeFinalCallHighestHiddenUserId)}
              { bids.afterFinalCallHighestOpenUsername && renderHighestBidComponent("After final call highest open bid:", bids.afterFinalCallHighestOpenUsername, bids.afterFinalCallHighestOpenBid, bids.afterFinalCallHighestOpenUserId)}
            </>
          )
        } else {
          return (
            <>
              { bids.beforeFinalCallHighestOpenUsername && renderHighestBidComponent("Before final call highest open bid:", bids.beforeFinalCallHighestOpenUsername, bids.beforeFinalCallHighestOpenBid, bids.beforeFinalCallHighestOpenUserId)}
              { bids.beforeFinalCallHighestHiddenUsername && renderHighestBidComponent("Before final call highest hidden bid:", bids.beforeFinalCallHighestHiddenUsername, bids.beforeFinalCallHighestHiddenBid, bids.beforeFinalCallHighestHiddenUserId)}
              { bids.afterFinalCallHighestOpenUsername && renderHighestBidComponent("After final call highest open bid:", bids.afterFinalCallHighestOpenUsername, bids.afterFinalCallHighestOpenBid, bids.afterFinalCallHighestOpenUserId)}
              { bids.afterFinalCallHighestHiddenUsername && renderHighestBidComponent("After final call highest hidden bid:", bids.afterFinalCallHighestHiddenUsername, bids.afterFinalCallHighestHiddenBid, bids.afterFinalCallHighestHiddenUserId)}
            </>
          )
        }
        
      }
    }
  }

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      alignContent="center"
      style={{ margin: 12 }}
    >
      <Grid item xs={12} style={{ margin: 12 }}>
        {owner && itemAd.bidOpen && (
          <Grid
            container
            spacing={1}
            justify="space-evenly"
            alignContent="center"
            alignItems="center"
            style={{ marginBottom: 12 }}
          >
            {!itemAd.finalCallMade && ( //change here add ! in start
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClick}
                  // onClick={() => setOpen(true)}
                  disabled={!bids.beoreFinalCallBids.length}
                >
                  Final Call
                </Button>
                <StyledMenu
                  id="customized-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <ListSubheader>
                    Choose Date and Time to expire ad
                  </ListSubheader>

                  <TextField
                    type='datetime-local'
                    fullWidth
                    variant="outlined"
                    inputProps={{
                      min: new Date().toISOString().slice(0, 16),
                    }}
                    onChange={(e) => {
                      setDate(e.target.value);
                      console.log(e.target.value)
                    }}
                  // finalCall({ item_id: itemAd._id, noOfDays: 1 })
                  />
                  <div style={{ float: 'right', display: 'flex' }}>
                    <Button onClick={e => {
                      setAnchorEl(null)
                    }} color='secondary'>Cancel</Button>
                    <Button style={{ float: 'right' }} onClick={e => {
                      if (date) {
                        console.log({ item_id: itemAd._id, finalCallDateTime: moment(date) }, 'props')
                        dispatch(finalCall({ item_id: itemAd._id, finalCallDateTime: moment(date) }))
                        setAnchorEl(null)
                      }
                    }} color='primary'>Submit</Button>
                  </div>

                  {/* <MenuItem>
                    <ListItemText
                      primary="2"
                      onClick={() =>
                        dispatch(
                          finalCall({ item_id: itemAd._id, noOfDays: 2 })
                        )
                      }
                    />
                  </MenuItem> */}
                </StyledMenu>
              </Grid>
            )}
            {/* {bids && bids.length > 0 && ( */}
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => dispatch(closeBid({ item_id: itemAd._id }))}
              >
                Close Bid
              </Button>
            </Grid>
            {/* )} */}
          </Grid>
        )}
        {/* <Typography variant="h6">Previous Bids</Typography> */}
        {bids.overallHighestBidAmount && bids.overallHighestBidAmountUsername && (
          <Typography variant="h4" color="secondary">
          <span style={{ color: 'black' }}>Highest Overall Bid</span> {itemAd.currencycode || '₹'}{" "}
          {Number(bids.overallHighestBidAmount).toLocaleString("en-IN")} {owner ? <><span style={{ color: 'black' }}>-</span> <Link style={{ cursor: 'pointer' }} onClick={() => history.push(`/user_profile/${bids.overallHighestBidUserId}`)}>{bids.overallHighestBidAmountUsername}</Link></> : <></>}
        </Typography>
        )}
        {
          getHighestBids()
        }
        {bids.noOfBuyers && (
          <Typography variant="h6" color="secondary">
          <span style={{ color: 'black' }}>No of buyers:</span> {bids.noOfBuyers || "0"}
        </Typography>
        )}
        {/* {bids.beoreFinalCallBids?.length || bids.afterFinalCallBids?.length ? <Typography variant="h6" color="secondary">
          <span style={{ color: 'black' }}>Highest Open Bid:</span> {itemAd.currencycode || '₹'}{" "}
          {Number(highestOpenBidAmount).toLocaleString("en-IN")} {bids.highestOpenBidUsername && <><span style={{ color: 'black' }}>-</span> <Link style={{ cursor: 'pointer' }} onClick={() => history.push(`/user_profile/${bids.highestOpenBidUserId}`)} >{bids.highestOpenBidUsername}</Link></>}
        </Typography> : <></>}
        {owner && highestHiddenBidAmount != null && (
          <Typography variant="h6" color="secondary">
            <span style={{ color: 'black' }}>Highest Hidden Bid:</span> {itemAd.currencycode || '₹'}{" "}
            {Number(highestHiddenBidAmount).toLocaleString("en-IN")} {bids.highestHiddenBidUsername && <><span style={{ color: 'black' }}>-</span> <Link style={{ cursor: 'pointer' }} onClick={() => history.push(`/user_profile/${bids.highestHiddenBidUserId}`)} >{bids.highestHiddenBidUsername}</Link></>}
          </Typography>
        )} */}

        {itemAd.finalCallMade && <Paper style={{ marginTop: 20 }}>
          <Tabs
            value={isFinalCall}
            onChange={handleFinalCall}
            aria-label="simple tabs example"
          >
            <CustomTab label={`Before final call ${bids?.allBeforeBidsCount ? `- ${bids?.allBeforeBidsCount}` : '- 0'}`} {...a11yProps(0)} />
            {bids?.allAfterBidsCount && <CustomTab label={`After final call ${bids?.allAfterBidsCount ? `- ${bids?.allAfterBidsCount}` : ' - 0'}`} {...a11yProps(1)} />}
          </Tabs>
        </Paper>}
        {owner ? <></> : <FormControl component="fieldset" style={{ display: 'flex' }}>
          <RadioGroup aria-label="gender" name="toggleBidUser" row value={isAllUserBids ? "all" : "my"} onChange={(e) => {
            setPage(1)
            e.target.value === 'my' ? setIsAllUserBids(false) : setIsAllUserBids(true)
          }}>
            {/* {bids?.allBeforeBidsCount ? <FormControlLabel value="all" control={<Radio />} label={`All User Bids ${isFinalCall ? bids?.allAfterBidsCount ? `- ${bids?.allAfterBidsCount}` : ' - 0' : bids?.allBeforeBidsCount ? `- ${bids?.allBeforeBidsCount}` : '- 0'}`} /> : <></>} */}
            {
              isFinalCall ?
                bids?.allAfterBidsCount && bids?.loginUserAfterBidsCount ?
                <FormControlLabel value="all" control={<Radio />} label={`All User Bids ${isFinalCall ? bids?.allAfterBidsCount ? `- ${bids?.allAfterBidsCount}` : ' - 0' : bids?.allBeforeBidsCount ? `- ${bids?.allBeforeBidsCount}` : '- 0'}`} />
                  : <></>
                : bids?.allBeforeBidsCount && bids?.loginUserBeforeBidsCount ?
                <FormControlLabel value="all" control={<Radio />} label={`All User Bids ${isFinalCall ? bids?.allAfterBidsCount ? `- ${bids?.allAfterBidsCount}` : ' - 0' : bids?.allBeforeBidsCount ? `- ${bids?.allBeforeBidsCount}` : '- 0'}`} />
                  : <></>
            }
            {
              isFinalCall ?
                bids?.loginUserAfterBidsCount ?
                  <FormControlLabel value="my" control={<Radio />} label={`My Bids ${isFinalCall ? bids?.loginUserAfterBidsCount ? `- ${bids?.loginUserAfterBidsCount}` : ' - 0' : bids?.loginUserBeforeBidsCount ? `- ${bids?.loginUserBeforeBidsCount}` : '- 0'}`} />
                  : <></>
                : bids?.loginUserBeforeBidsCount ?
                  <FormControlLabel value="my" control={<Radio />} label={`My Bids ${isFinalCall ? bids?.loginUserAfterBidsCount ? `- ${bids?.loginUserAfterBidsCount}` : ' - 0' : bids?.loginUserBeforeBidsCount ? `- ${bids?.loginUserBeforeBidsCount}` : '- 0'}`} />
                  : <></>
            }

          </RadioGroup>
        </FormControl>}
        <Paper style={{ margin: 12 }} elevation={0}>
          <TableContainer>
            {selectedBids?.length ? selectedBids && <Table style={{ width: "100%" }} size="small">
              <TableBody>
                <TableRow>
                  {owner && <TableCell></TableCell>}
                  {owner && <TableCell>Posted By</TableCell>}
                  {!owner && <TableCell>Bid Username</TableCell>}
                  {/* {owner && <TableCell>Phone</TableCell>} */}
                  <TableCell>Date</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Bid Amount</TableCell>
                </TableRow>
                {selectedBids?.length ? selectedBids &&
                  selectedBids?.map((bid, key) => {
                    // if (key === 0 && bid.type === "Open") {
                    //   return (
                    //     <TableRow key={key}>
                    //       {owner && (
                    //         <TableCell>
                    //           {bid.profilepic ? (
                    //             <Avatar
                    //               // className={classes.avatar}
                    //               src={getURL("UPLOADS") + bid.profilepic}
                    //               onClick={(e) =>
                    //                 dispatch(
                    //                   openAvatarModal(
                    //                     getURL("UPLOADS") + bid.profilepic
                    //                   )
                    //                 )
                    //               }
                    //             />
                    //           ) : (
                    //             <Avatar
                    //               // className={classes.avatar}
                    //               onClick={(e) => dispatch(openAvatarModal())}
                    //             >
                    //               <PersonIcon />
                    //             </Avatar>
                    //           )}
                    //         </TableCell>
                    //       )}
                    //       {owner && (
                    //         <TableCell>
                    //           <Typography variant="h6" color="secondary" onClick={() => {
                    //             console.log(bid)
                    //             history.push(`/user_profile/${bid.postedBy}`)
                    //           }}

                    //           >
                    //             <Link style={{ cursor: 'pointer' }}>{bid.postedByUsername && bid.postedByUsername}</Link>
                    //           </Typography>
                    //         </TableCell>
                    //       )}
                    //       {!owner && <TableCell>
                    //         <Typography variant="h6" color="secondary">
                    //           {bid.bidAlias}
                    //         </Typography>
                    //       </TableCell>}
                    //       {/* {owner && (
                    //         <TableCell>
                    //           <Typography variant="h6" color="secondary">
                    //             {bid.phone && bid.phone}
                    //           </Typography>
                    //         </TableCell>
                    //       )} */}
                    //       <TableCell>
                    //         <Typography color="secondary">
                    //           <Moment format="YYYY-MM-DD HH:mm:ss">
                    //             {bid.createdAt}
                    //           </Moment>
                    //         </Typography>
                    //       </TableCell>
                    //       <TableCell>
                    //         <Typography variant="h6" color="secondary">
                    //           {bid.type === "Hidden" ? "H" : "O"}
                    //         </Typography>
                    //       </TableCell>
                    //       <TableCell>
                    //         <Typography variant="h6" color="secondary">
                    //           {bid.bidamt}
                    //         </Typography>
                    //       </TableCell>
                    //     </TableRow>
                    //   );
                    // } else 
                    if (bid.bidamt == overall) {
                      return (
                        <TableRow key={key}>
                          {owner && (
                            <TableCell>
                              {bid.profilepic ? (
                                <Avatar
                                  // className={classes.avatar}
                                  src={getURL("UPLOADS") + bid.profilepic}
                                  onClick={(e) =>
                                    {
                                      console.log(bid.profilepic ? getURL("UPLOADS") + bid.profilepic : "/profile-icon.png")
                                      dispatch(
                                      openAvatarModal(
                                        bid.profilepic ? getURL("UPLOADS") + bid.profilepic : "/profile-icon.png"
                                      )
                                    )}
                                  }
                                />
                              ) : (
                                <Avatar
                                  // className={classes.avatar}
                                  onClick={(e) => {
                                    console.log("here12")
                                    dispatch(openAvatarModal("/profile-icon.png"))
                                  }}
                                >
                                  <PersonIcon />
                                </Avatar>
                              )}
                            </TableCell>
                          )}
                          {owner && (
                            <TableCell>
                              <Typography variant="h6" color="primary">
                                {bid.postedByUsername && bid.postedByUsername}
                              </Typography>
                            </TableCell>
                          )}
                          <TableCell>
                            <Typography variant="h6" color="primary">
                              {bid.bidAlias}
                            </Typography>
                          </TableCell>
                          {owner && (
                            <TableCell>
                              <Typography variant="h6" color="primary">
                                {bid.phone && bid.phone}
                              </Typography>
                            </TableCell>
                          )}
                          <TableCell>
                            <Typography color="primary">
                              <Moment format="YYYY-MM-DD HH:mm:ss">
                                {bid.createdAt}
                              </Moment>
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="h6" color="primary">
                              {bid.type === "Hidden" ? "H" : "O"}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="h6" color="primary">
                              {bid.bidamt}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    } else {
                      return (
                        <TableRow key={key}>
                          {owner && (
                            <TableCell>
                              {bid.profilepic ? (
                                <Avatar
                                  // className={classes.avatar}
                                  src={getURL("UPLOADS") + bid.profilepic}
                                  onClick={(e) =>
                                    {
                                      dispatch(
                                      openAvatarModal(
                                        bid.profilepic ? getURL("UPLOADS") + bid.profilepic : "/profile-icon.png"
                                      )
                                    )}
                                  }
                                />
                              ) : (
                                <Avatar
                                  // className={classes.avatar}
                                  onClick={(e) => {
                                    dispatch(openAvatarModal("/profile-icon.png"))
                                  }}
                                >
                                  <PersonIcon />
                                </Avatar>
                              )}
                            </TableCell>
                          )}
                          {owner && (
                            <TableCell onClick={() => {
                              history.push(`/user_profile/${bid.postedBy}`)
                            }}>
                              <Link style={{ cursor: 'pointer' }}>{bid.postedByUsername && bid.postedByUsername}</Link>
                            </TableCell>
                          )}
                          {!owner && <TableCell >{bid.bidAlias}</TableCell>}
                          {/* {owner && (
                            <TableCell>{bid.phone && bid.phone}</TableCell>
                          )} */}
                          <TableCell>
                            <Moment format="YYYY-MM-DD HH:mm:ss">
                              {bid.createdAt}
                            </Moment>
                          </TableCell>
                          <TableCell>
                            {bid.type === "Hidden" ? "H" : "O"}
                          </TableCell>
                          <TableCell>
                            <Typography>{bid.bidamt}</Typography>
                          </TableCell>
                        </TableRow>
                      );
                    }
                  }) : <></>}
              </TableBody>
            </Table> : <></>}

            {
              !(selectedBids && selectedBids.length) ? <div style={{ textAlign: 'center' }}>No bids are posted</div> : <div style={{ justifyContent: 'right', display: 'flex', marginTop: 10 }}><Pagination  page={page} count={bids.pageCount} color="secondary" onChange={(event, value) => setPage(value)} /></div>
            }
          </TableContainer>
        </Paper>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  bids: state.bidReducer.bids,
  highestOpenBidAmount: state.bidReducer.highestOpenBidAmount ?? 0,
  highestHiddenBidAmount: state.bidReducer.highestHiddenBidAmount,
  fetchingBids: state.bidReducer.fetchingBids,
  userDetails: state.userDetailsReducer.userDetail,
});

export default connect(mapStateToProps)(BidFeed);
