import React from "react";
import PropTypes from "prop-types";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AccountCircle from "@material-ui/icons/AccountCircle";
import HomeIcon from "@material-ui/icons/Home";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import DraftsIcon from "@material-ui/icons/Drafts";
import SendIcon from "@material-ui/icons/Send";
import { Grid, Typography } from "@material-ui/core";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Drawer from "@material-ui/core/Drawer";
import { menuStyles } from "../helpers/materialStyles";
import classNames from "classnames";
import Button from "@material-ui/core/Button";
import { getObjectParam, getStringParam } from "../helpers/paramVerifier";
import { constants } from "../helpers/constants";
import SearchField from "./SearchField";
import { Redirect } from "react-router-dom";
import SearchResultField from "./SearchResultField/SearchResultField";

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isRedirect: false,
      redirectPath: null,
      location: this.props.history.location.pathname,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.location !== this.props.history.location.pathname) {
      this.setState({ location: this.props.history.location.pathname });
    }
  }

  redirect(path) {
    this.setState({ isRedirect: true, redirectPath: path });
  }

  getMenuItems() {
    const { classes } = this.props;
    const { location } = this.state;

    let items = new Array();
    try {
      let userDetail = getObjectParam(this.props.userDetail);
      let userType = getStringParam(userDetail.userType);
      items.push(
        <Button
          className={
            location === "/ads" || location === "/" 
              ? [classes.button, classes.selectButton]
              : [classes.button]
          }
          onClick={() => {
            this.redirect("/ads");
          }}
        >
          Ads
        </Button>
      );
      items.push(
        <Button
          className={
            location === "/myads" || location === "/myAds"
              ? [classes.button, classes.selectButton]
              : [classes.button]
          }
          onClick={() => {
            this.redirect("/myAds");
          }}
        >
          My Ads
        </Button>
      );
      items.push(
        <Button
          className={
            location === "/contactus"
              ? [classes.button, classes.selectButton]
              : [classes.button]
          }
          onClick={() => {
            this.redirect("/contactus");
          }}
        >
          Contact Us
        </Button>
      );
      items.push(
        <Button
          className={
            [classes.button]
          }
          onClick={() => {
            window.open("https://www.youtube.com/watch?v=YMoJfdTb6Q8&list=PLeHqwgRfPnR8Bij2UtS7QpszsI3nJL2BB")
          }}
        >
          Public Response
        </Button>
      );
      items.push(
        <Button
          className={
            [classes.button]
          }
          onClick={() => {
            window.open("https://www.youtube.com/watch?v=X7EicU_UERo")
          }}
        >
          How Its Different
        </Button>
      );
      items.push(<SearchResultField userId={""} />)
    } catch (error) {
      console.error("Error in Menu.js => getMenuItems(): " + error);
    }
    return items;
  }
  render() {
    const { classes } = this.props;
    if (this.state.isRedirect) {
      this.state.isRedirect = false;
      return <Redirect to={this.state.redirectPath} />;
    }
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: "100%",
          alignItems: "center",
          margin: "0px 8px",
        }}
      >
        <div style={{width: "100%", display: 'flex', alignItems: "center"}}>{this.getMenuItems()}
        
        </div>
        {/* <SearchField userId={this.props.userDetail._id} /> */}
      </div>
    );
  }
}

Menu.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(menuStyles)(Menu);
