import React from "react";
import getURL from "../helpers/apis";
import { Link } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import withRouter from "react-router-dom/es/withRouter";
import { Tabs, Tab } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import PersonIcon from "@material-ui/icons/Person";
import {
  updateProfile,
  handleUpload,
  openAvatarModal,
} from "../actions/appActions";
import { getUserDetails } from "../actions/userDetailsAction";

import {
  isValidParam,
  getStringParam,
  getBooleanParam,
  getArrayParam,
} from "../helpers/paramVerifier";
import Typography from "@material-ui/core/Typography";
import { Loader } from "./UtilityComponents";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";

import { constants } from "../helpers/constants";
import PhotoSharpIcon from "@material-ui/icons/PhotoSharp";
import PersonSharpIcon from "@material-ui/icons/PersonSharp";
import EventNoteSharpIcon from "@material-ui/icons/EventNoteSharp";
import DynamicFeedSharpIcon from "@material-ui/icons/DynamicFeedSharp";
import FollowingIcon from "@material-ui/icons/ArrowUpward";
import FollowersIcon from "@material-ui/icons/ArrowDownward";
import ProfileLikeIcon from "@material-ui/icons/ThumbUpAltSharp";
import LikeHiddenIcon from "@material-ui/icons/Favorite";
import CommentIcon from "@material-ui/icons/Comment";
import LikeOpenIcon from "@material-ui/icons/FavoriteBorderSharp";
import FavouriteIcon from "@material-ui/icons/BookmarkBorderSharp";
import EventNoteIcon from "@material-ui/icons/EventNote";
import PhotoOutlinedIcon from "@material-ui/icons/PhotoOutlined";
import PhotoCameraOutlinedIcon from "@material-ui/icons/PhotoCameraOutlined";
import VisibilityOffSharpIcon from "@material-ui/icons/VisibilityOffSharp";
import ShareToMeIcon from "@material-ui/icons/ReplySharp";
import PersonAddDisabledIcon from "@material-ui/icons/PersonAddDisabledSharp";
import EventIcon from "@material-ui/icons/Event";
import EditProfile from "./EditProfile";
import EditProfileForm from "./EditProfile/EditProfileForm";
import Modal from "@material-ui/core/Modal";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
// import UserGalleryComponent from "./UserGalleryComponent";
import ProfileInfo from "./ProfileInfo";
import ReviewContainer from "./ReviewComponent";

// import PostFeed from "./PostsComponent/PostFeed";

// import "react-image-gallery/styles/css/image-gallery.css";
// import "react-image-gallery/styles/css/image-gallery-no-icon.css";
// import ImageGallery from "react-image-gallery";
import UploadDialog from "./FineUploader/UploadDialog";
// import ImageDialog from "./UserProfile/ImageDialog";

const rootUrl = getURL("UPLOADS");

const styles = (theme) => ({
  mainContent: {
    marginTop: 10,
  },
  paper: {
    fontSize: 14,
    width: "100%",
    minWidth: 275,
    margin: 10,
    maxWidth: 1200,
  },
  avatar: {
    fontSize: 100,
    margin: "auto",
    marginLeft: "8px",
    width: 120,
    height: 120,
  },
  columnContainer: {
    padding: 5,
  },
  fieldGridLabel: {
    // width: "30%",
    fontSize: 18,
    margin: 5,
  },
  fieldGridValue: {
    // width: "68%",
    fontSize: 18,
    margin: 5,
  },
  fieldLabel: {
    fontSize: 14,
    // fontWeight: 600,
  },
  fieldValue: {
    fontSize: 14,
  },
  actionsRow: {},
  actionContainer: {},
  actionElement: {
    fontSize: 16,
    fontWeight: 600,
    textAlign: "center",
    cursor: "pointer",
  },
  actionButton: {
    marginLeft: 10,
    marginTop: 10,
    paddingLeft: 30,
    paddingRight: 30,
    textTransform: "capitalize",
    fontSize: 14,
    height: 30,
    paddingTop: 4,
  },
  profilePicChange: {
    top: 140,
    width: 120,
    height: 56,
    zIndex: 1000,
    marginLeft: "8px",
    position: "absolute",
    backgroundColor: "#00000070",
    borderBottomLeftRadius: 100,
    borderBottomRightRadius: 100,
    textAlign: "center",
    cursor: "pointer",
  },
  profileCamera: {
    position: "relative",
    marginTop: 6,
    fontSize: 24,
    color: "#ffffff",
  },
  icon: {
    cursor: "pointer",
    fontSize: 32,
    // marginLeft: 8,
    // marginRight: 8,
  },
  actionCount: {
    fontSize: 16,
    marginLeft: 25,
    marginRight: 55,
  },
  profileTitle: {
    textAlign: "center",
    fontSize: 24,
    fontWeight: 500,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  tabPaper: {
    flexGrow: 1,
    borderTop: 0,
    // overflowY: "scroll",
    // height: "100vh",
  },
  ReviewPaper: {
    width: "100%",
    marginTop: 25,
  },
  tabIndicator: {
    top: "0px",
    height: "2px",
    backgroundColor: "#3f51b5",
  },
});

class ProfileAboutComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetails: null,
      isHoverProfilePic: false,
      open: null,
      avatarModal: false,
      editProfile: false,
      value: 0,
      openUploader: false,
      refresh: false,
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleOpenEdit = this.handleOpenEdit.bind(this);
  }

  componentDidMount() {
    const id = localStorage.getItem("userId");
    const jwt = localStorage.getItem("jwt");
    this.props.dispatch(getUserDetails(jwt, id));
  }
  // componentDidUpdate() {
  //   const id = localStorage.getItem("userId");
  //   const jwt = localStorage.getItem("jwt");
  //   this.props.dispatch(getUserDetails(jwt, id));
  // }

  refresh() {
    const id = localStorage.getItem("userId");
    const jwt = localStorage.getItem("jwt");
    this.props.dispatch(getUserDetails(jwt, id));
  }
  handleEdit(params) {
    return this.props.dispatch(updateProfile(params));
  }

  handleOpenEdit(e) {
    this.props.history.push("/editprofile");
    // this.setState({ editProfile: true });
  }

  handleChange(e, newValue) {
    this.setState({ value: newValue });
  }

  onMouseEnter() {
    this.setState({ isHoverProfilePic: true });
  }

  onMouseLeave() {
    this.setState({ isHoverProfilePic: false });
  }

  onAvatarClick(profilePhotoUrl) {
    // this.setState({ avatarModal: true });
    return this.props.dispatch(openAvatarModal(profilePhotoUrl || "/profile-icon.png"));
  }

  handleClose() {
    this.setState({ openUploader: false });
  }

  render() {
    const { classes, userDetail: userDetails } = this.props;
    let isHoverProfilePic = getBooleanParam(this.state.isHoverProfilePic);
    if (!userDetails) {
      return <Loader />;
    }
    let profilePhotoUrl = null;
    if (userDetails.profilepic)
      profilePhotoUrl = getURL("UPLOADS") + userDetails.profilepic;
    let profileName =
      getStringParam(userDetails.firstname) +
      " " +
      getStringParam(userDetails.lastname);

    return (
      <Paper className={classes.paper} elevation={0}>
        {this.state.openUploader && (
          <UploadDialog
            open={this.state.openUploader}
            handleClose={() => {
              this.handleClose({ openUploader: false });
              this.refresh();
            }}
          />
        )}
        <Grid container spacing={3} className={classes.mainContent}>
          <Grid key={3} item xs={3}>
            {profilePhotoUrl ? (
              <Avatar
                alt={this.props.userDetail.name}
                className={classes.avatar}
                src={profilePhotoUrl}
                onMouseEnter={this.onMouseEnter.bind(this)}
                onMouseLeave={this.onMouseLeave.bind(this)}
                onClick={() => this.onAvatarClick(profilePhotoUrl)}
              />
            ) : (
              <Avatar
                className={classes.avatar}
                alt="owner"
                onMouseEnter={this.onMouseEnter.bind(this)}
                onMouseLeave={this.onMouseLeave.bind(this)}
                onClick={() => this.onAvatarClick()}
              >
                <PersonIcon />
              </Avatar>
            )}
            {isHoverProfilePic && (
              <Grid
                className={classes.profilePicChange}
                onMouseEnter={this.onMouseEnter.bind(this)}
                onMouseLeave={this.onMouseLeave.bind(this)}
              >
                <div onClick={() => this.setState({ openUploader: true })}>
                  <i
                    className={classNames(
                      "material-icons",
                      classes.profileCamera
                    )}
                  >
                    local_see
                  </i>

                  <Typography style={{ fontSize: 14, color: "#ffffff" }}>
                    Update
                  </Typography>
                </div>
              </Grid>
            )}
          </Grid>

          <Grid key={9} item xs={9}>
            <Grid container>
              <Grid item xs={9}>
                <Typography component="div" className={classes.profileTitle}>
                  {profileName}
                </Typography>
              </Grid>

              <Grid item item xs={3}>
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={(e) => this.handleOpenEdit(e)}
                  style={{ textTransform: "none" }}
                >
                  Edit
                </Button>
              </Grid>
            </Grid>
            {/* <Divider variant="middle" /> */}
          </Grid>
          <Paper elevation={0} className={classes.tabPaper}>
            <ProfileInfo
              userDetails={userDetails}
              classes={classes}
              auth={true}
            />
          </Paper>

          {/* Review Component */}
          {/* <Paper elevation={1} className={classes.ReviewPaper}>
            <ReviewContainer
              userDetails={userDetails}
              classes={classes}
              auth={true}
            />
          </Paper> */}
        </Grid>
      </Paper>
    );
  }
}

function mapStateToProps(state) {
  return {
    userDetail: state.userDetailsReducer.userDetail,
  };
}

ProfileAboutComponent = withStyles(styles)(ProfileAboutComponent);
export default connect(mapStateToProps)(withRouter(ProfileAboutComponent));
