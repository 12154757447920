//importing dependencies
import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import FilledInput from "@material-ui/core/FilledInput";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Link as RouterLink, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

//importing actions
import {
  onEmailChange,
  onFormSubmit,
  onPasswordChange,
  onCheckBoxToggle,
} from "../actions/loginActions";

//import material ui
import { Button, Link, Grid, Typography } from "@material-ui/core";

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
    };
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    this.handleMouseDownPassword = this.handleMouseDownPassword.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);
  }

  onSubmitForm(e) {
    e.preventDefault();
    this.props.onFormSubmit(e, this.props.formState);
  }

  handleClickShowPassword = () => {
    this.setState({ ...this.state, showPassword: !this.state.showPassword });
  };

  handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  render() {
    // if (
    //   this.props.formState.userDetail &&
    //   !this.props.formState.userDetail.emailValidated
    // ) {
    //   return (
    //     <Redirect to={`/email-verify/${this.props.formState.userDetail._id}`} />
    //   );
    // }

    if (
      this.props.formState.userDetail &&
      !this.props.formState.userDetail.mobileValidated
    ) {
      return <Redirect to="/otp-phone-verify" />;
    }

    // if (
    //   this.props.formState.userDetail &&
    //   !this.props.formState.userDetail.education
    // ) {
    //   return <Redirect to="/complete-signup" />;
    // }
    if (localStorage.getItem("jwt")) {
      return <Redirect to={"/"} />;
    }
    return (
      <>
      <Grid
        container
        className="mui-center-grid"
        spacing={3}
        alignContent="center"
        justify="space-around"
      >
        <Grid className="mui-inner-grid" item xs={8} sm={6} md={4}>
          <Typography component="h3" variant="h3" className="heading-center">
            Login
          </Typography>
          <form onSubmit={this.onSubmitForm}>
            <TextField
              required
              id="username-input"
              label="Email"
              fullWidth={true}
              margin="dense"
              className="inherit-bg-color cb-tf"
              onChange={this.props.onEmailChange}
              value={this.props.formState.username || ""}
            />
            <Input
              required
              id="password-input"
              label="Password"
              fullWidth={true}
              style={{ marginTop: 12 }}
              type={this.state.showPassword ? "text" : "password"}
              className="inherit-bg-color cb-tf"
              onChange={this.props.onPasswordChange}
              value={this.props.formState.password || ""}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={this.handleClickShowPassword}
                    onMouseDown={this.handleMouseDownPassword}
                  >
                    {this.state.showPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
            <div className="login-btn-area mtop15">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                style={{ textTransform: "none" }}
              >
                Login
              </Button>
              <Typography variant="body2">
                <Link component={RouterLink} to={"/forgot-password"}>
                  Forgot your password?
                </Link>
              </Typography>
            </div>
          </form>
          <Typography variant="subtitle1" className="text-center mtop20">
            Don't have an account?{" "}
            <Link component={RouterLink} to={"/register"}>
              Sign up
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "-webkit-center" }}>
          <Grid item xs={4}>
            <Typography variant="subtitle1">
              <Link component={RouterLink} to={"/contactus"}>
                Contact Us
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    formState: state.loginReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      onEmailChange,
      onPasswordChange,
      onCheckBoxToggle,
      onFormSubmit,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
