const initialState = {
  reviews: [],
  fetchingReviews: false,
  errors: [],
};

export default function (state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case "GET_USER_REVIEWS": {
      return { ...state, fetchingReviews: true };
    }
    case "GET_USER_REVIEWS_SUCCESS": {
      return {
        ...state,
        reviews: action.payload,
        fetchingReviews: false,
      };
    }
    case "GET_USER_REVIEWS_FAILED": {
      return { ...state, fetchingReviews: false };
    }

    case "REVIEWS_ERROR":
      return {
        ...state,
        errors: payload,
      };
    case "CLEAR_REVIEWS":
      return {
        ...state,
        reviews: [],
      };
    default:
      return { ...state };
  }
}
