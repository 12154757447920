import React, { useState, useEffect } from "react";
// import { Button } from "../components/Buttons";
import {
  AddOnEmailInput,
  AddOnPasswordInput,
  AddOnPhoneInput,
} from "../components/AddOnInputs";
import { AuGroup } from "../components/AuFormComponent";
import { otpPhoneVerify, resendOTP, loginSuccessful } from "../actions/loginActions";
import withRouter from "react-router-dom/es/withRouter";
import Link from "react-router-dom/es/Link";
// // import snackbar from "snackbar";
import { otpPhone } from "../actions/loginActions";
import { connect } from "react-redux";
import { Button, Typography, TextField, Grid, Input } from "@material-ui/core";
import { Link as RouterLink, Redirect } from "react-router-dom";
import AlertDialog from "../components/AlertDialog";
import { getUserDetailsSuccess } from "../actions/userDetailsAction";

// const buttonDesignClass = {
//   className: "btn au-btn theme-resetPassword",
//   value: "Verify Phone No.",
// };

// const formInputsTheme = {
//   className: "theme-resetPassword",
// };

const OtpVerify = (props) => {
  const [otp, setOtp] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [seconds, setSeconds] = useState(5);
  const [tries, setTries] = useState(null);
  const [alert, setAlert] = useState(false);
  const [alertText, setAlertText] = useState("");
  const { userDetail } = props;
  useEffect(() => {


    let formData = {};
    console.log(userDetail, userDetail?.username, localStorage.getItem("username"))
    if (userDetail && userDetail?.username) {
      formData = {
        username: userDetail?.username,
      };
      // formData = {
      //   username: 'userDetail',
      // };

      props.dispatch(otpPhone(formData)).then((response) => {
        console.log("response", response);
        if (response.error) {
          // snackbar.show("Sorry! Failed to send OTP");
          setAlertText("Sorry! Failed to resend OTP");
          setAlert(true);
        }
        if (
          response.errors.length > 0
        ) {
          // setTries(0);
        } else {
          setTries(response.data.triesLeft);
        }
      });
    } else {
      const username = localStorage.getItem("username");

      formData = {
        username,
      };
      props.dispatch(otpPhone(formData)).then((response) => {
        console.log("response", response);
        if (response.error) {
          // snackbar.show("Sorry! Failed to send OTP");
          setAlertText("Sorry! Failed to resend OTP");
          setAlert(true);
        }
        if (
          response.errors.length > 0
        ) {
          // setTries(0);
        } else {
          setTries(response.data.triesLeft);
        }
      });
    }
  }, [userDetail]);

  const onSubmitForm = (e) => {
    e.preventDefault();

    // const { userDetail } = props;
    const formData = {
      username: userDetail && userDetail.username || localStorage.getItem("username"),
      otp: otp,
    };
    props.dispatch(otpPhoneVerify(formData)).then((response) => {
      if (!response.error) {
        // props.dispatch(getUserDetailsSuccess({data: response.userDetail}));
        // props.dispatch(loginSuccessful(response.data, response.userDetail));
        // props.history.push("/")
        window.location.reload(true)
      }
    });
    // console.log("here", props.userDetail)
    // props.dispatch(getUserDetailsSuccess( {data: {data: {...props.userDetail, mobileValidated: true}}}));
    // props.dispatch(loginSuccessful(localStorage.getItem("jwt"), {...props.userDetail, mobileValidated: true}))
    // props.history.push("/");
  };

  const onResendOTP = () => {
    const { userDetail } = props;

    let formData = {};

    if (userDetail && userDetail.username) {
      formData = {
        username: userDetail && userDetail.username,
      };

      props.dispatch(resendOTP(formData)).then((res) => {
        if (res.error) {
          // snackbar.show("Sorry! Failed to resend OTP");
          setAlertText("Sorry! Failed to resend OTP");
          setAlert(true);
        } else if (
          res.errors.length > 0
        ) {
          setTries(0);
          // snackbar.show("Sorry! Failed to resend OTP");
          setAlertText("Sorry! Failed to resend OTP");
          setAlert(true);
        } else {
          setTries(res.data.triesLeft);
        }
      });
      setIsDisabled(true);
    } else {
      const username = localStorage.getItem("username");

      formData = {
        username,
      };
      props.dispatch(resendOTP(formData)).then((res) => {
        if (res.error) {
          // snackbar.show("Sorry! Failed to resend OTP");
          setAlertText("Sorry! Failed to resend OTP");
          setAlert(true);
        } else if (
          res.errors.length > 0 &&
          res.errors[0] === "you reached daily limit"
        ) {
          setTries(0);
          // snackbar.show("Sorry! Failed to resend OTP");
          setAlertText("Sorry! Failed to resend OTP");
          setAlert(true);
        } else {
          console.log("res, res.data", res, res.data);
          setTries(res.data.triesLeft);
        }
      });
      setIsDisabled(true);
    }
  };

  useEffect(() => {
    let interval = null;
    if (seconds <= 0) {
      setIsDisabled(false);
      setSeconds(null);
      clearInterval(interval);
    } else if (isDisabled) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isDisabled, seconds]);

  return (
    <>
      <Grid
        container
        className="mui-center-grid"
        spacing={3}
        alignContent="center"
        justify="space-around"
      >
        <Grid className="mui-inner-grid" item xs={8} sm={6} md={4}>
          <Typography component="h3" variant="h3" className="heading-center">
            Verify Mobile Number
          </Typography>
          <form onSubmit={onSubmitForm}>
            <TextField
              required
              id="otp-input"
              label="OTP"
              fullWidth={true}
              type="number"
              margin="dense"
              className="inherit-bg-color cb-tf"
              onChange={(e) => setOtp(e.target.value)}
              value={otp}
            />
            <div>
              <Typography
                display="inline"
                style={{}}
                color="secondary"
              >
                {tries !== null
                  ? tries > 0
                    ? tries + " tries left"
                    : "Daily limit reached"
                  : null}
              </Typography>
            </div>


            <div className="login-btn-area mtop15">
              <div style={{ width: '100%' }}>
                <Button
                  disabled={isDisabled || tries === 0}
                  onClick={(e) => {
                    onResendOTP(e)
                  }}
                  style={{ textTransform: "none" }}
                >
                  {isDisabled ? `Wait ${seconds} seconds` : "Resend OTP"}
                </Button>
              </div>
              <div style={{ textAlign: 'right', width: '100%' }}>
                <Button
                  // disabled={}
                  onClick={(e) => onSubmitForm(e)}
                  style={{ textTransform: "none" }}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Submit
                </Button>
              </div>

            </div>
          </form>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "-webkit-center" }}>
          <Grid item xs={4}>
            <Typography variant="subtitle1">
              <Link component={RouterLink} to={"/contactus"}>
                Contact Us
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userDetail: state.userDetailsReducer.userDetail,
  };
};

export default connect(mapStateToProps)(withRouter(OtpVerify));
