import request from "../helpers/requestEngine";
import request1 from "../helpers/request";
import getURL from "../helpers/apis";
import { getAdById, getAdGalleryDetailsById } from "./itemAdActions";
import { openDialog, closeDialog } from "./appActions";

export const finalCall = (formData) => {
  return (dispatch) => {
    request("post", "FINAL_CALL", true, formData).then(
      (response) => {
        dispatch(finalCallSuccess(response.data.data));
        // history.goBack();
        dispatch(openDialog("Done!"));
        dispatch(getAdById(response.data.data.uid));
        // dispatch(getBids({ item_id: response.data.data.itemAdId }));
      },
      (err) => {
        dispatch(
          openDialog(
            err.response.data.data || "Something went wrong. Please try again."
          )
        );

        dispatch(finalCallFailed(err));
        return err;
      }
    );
  };
};

function finalCallSuccess() {
  return {
    type: "FINAL_CALL_SUCCESS",
  };
}

function finalCallFailed(err) {
  return {
    type: "FINAL_CALL_FAILED",
  };
}

export const closeBid = (formData) => {
  return (dispatch) => {
    request("post", "CLOSE_BID", true, formData).then(
      (response) => {
        dispatch(closeBidSuccess(response.data.data));
        // history.goBack();
        dispatch(openDialog("Bid Closed!"));
        dispatch(getAdById(response.data.data.uid));
      },
      (err) => {
        dispatch(
          openDialog(
            err.response.data.data || "Something went wrong. Please try again."
          )
        );

        dispatch(closeBidFailed(err));
        return err;
      }
    );
  };
};

function closeBidSuccess() {
  return {
    type: "CLOSE_BID_SUCCESS",
  };
}

function closeBidFailed(err) {
  return {
    type: "CLOSE_BID_FAILED",
  };
}

export const postBidAfterPayment = (formData, history, isAllUserBids, isAfterFinalCall, page) => {
  return (dispatch) => {
    if (
      formData.type === "" ||
      formData.type === "Select Type" ||
      formData.bidamt === ""
    ) {
      dispatch(openDialog("Enter all bid details"));
    } else {
      request("post", "POST_BID", true, formData).then(
        (response) => {
          dispatch(postBidSuccess(response.data.data));
          // history.goBack();
          dispatch(openDialog("Bid Posted!"));
          dispatch(getAllUsersBids({ item_id: response.data.data.itemAdId, page: page, limit: 10, isAfterFinalCall: isAfterFinalCall ? 1 : 0 }, isAllUserBids))
          // dispatch(getBids({ item_id: response.data.data.itemAdId }));
        },
        (err) => {
          dispatch(
            openDialog(
              err.response.data.data ||
                "Something went wrong. Please try again."
            )
          );

          dispatch(postBidFailed(err));
          return err;
        }
      );
    }
  };
};

function postBidSuccess() {
  return {
    type: "POST_BID_SUCCESS",
  };
}

function postBidFailed(err) {
  return {
    type: "POST_BID_FAILED",
  };
}

export const getBids = (params) => {
  return (dispatch) => {
    dispatch(fetchingBids());

    return dispatch(fetchBids(params));
  };
};

export const getAllUsersBids = (params, isFetchAllUserBids, userDetails = true) => {
  return (dispatch) => {
    dispatch(fetchingBids());

    return userDetails ?  dispatch(fetchAllUserBids(params, isFetchAllUserBids)) : dispatch(getBidsWithoutToken(params, isFetchAllUserBids));
  };
};

const getBidsWithoutToken = (params, isFetchAllUserBids) => {
  return (dispatch) => {
  request("get", "GET_ALL_USER_BIDS", true, null, params).then(
    (response) => {
      if(response.data.data){
        dispatch(
          getBidsSuccess({
            data: {...response.data.data,},
            highestOpenBidAmount: response.data.data.highestOpenBidAmount,
            highestHiddenBidAmount: response.data.data.highestHiddenBidAmount,
            allData: response.data
          })
        );
      }
     
    },
    (err) => {
      dispatch(
        openDialog(
          err.response.data.data || "Something went wrong. Please try again."
        )
      );

      dispatch(getBidsFailed(err));
    }
  );
  }
}

function fetchingBids() {
  return {
    type: "GET_BIDS",
  };
}

function fetchBids(params) {
  return (dispatch) => {
    request("get", "GET_BIDS", true, null, params).then(
      (response) => {
        if(response.data.data){
          dispatch(
            getBidsSuccess({
              data: response.data.data,
              highestOpenBidAmount: response.data.data.highestOpenBidAmount,
              highestHiddenBidAmount: response.data.data.highestHiddenBidAmount,
            })
          );
        }
       
      },
      (err) => {
        dispatch(
          openDialog(
            err.response.data.data || "Something went wrong. Please try again."
          )
        );

        dispatch(getBidsFailed(err));
      }
    );
  };
}

function fetchAllUserBids(params, isFetchAllUserBids) {
  // params = {
  //   ...params,
  //   isAfterFinalCall: params.isAfterFinalCall ? 1 : 0
  // }
  return (dispatch) => {
    if(isFetchAllUserBids){
      request("get", "GET_LOGIN_USER_BIDS", true, null, params).then(
        (response) => {
          if(response.data.data){
            request("get", "GET_ALL_USER_BIDS", true, null, params).then(
              (response1) => {
                if(response1.data.data){
                  dispatch(
                    getBidsSuccess({
                      data: {...response.data.data, ...response1.data.data},
                      highestOpenBidAmount: response1.data.data.highestOpenBidAmount,
                      highestHiddenBidAmount: response1.data.data.highestHiddenBidAmount,
                      allData: response1.data
                    })
                  );
                }
               
              },
              (err) => {
                dispatch(
                  openDialog(
                    err.response.data.data || "Something went wrong. Please try again."
                  )
                );
        
                dispatch(getBidsFailed(err));
              }
            );
          }
         
        },
        (err) => {
          dispatch(
            openDialog(
              err.response.data.data || "Something went wrong. Please try again."
            )
          );
  
          dispatch(getBidsFailed(err));
        }
      );
    } else {
      request("get", isFetchAllUserBids ? "GET_ALL_USER_BIDS": "GET_LOGIN_USER_BIDS", true, null, params).then(
        (response) => {
          if(response.data.data){
            dispatch(
              getBidsSuccess({
                data: response.data.data,
                highestOpenBidAmount: response.data.data.highestOpenBidAmount,
                highestHiddenBidAmount: response.data.data.highestHiddenBidAmount,
                allData: response.data
              })
            );
          }
         
        },
        (err) => {
          dispatch(
            openDialog(
              err.response.data.data || "Something went wrong. Please try again."
            )
          );
  
          dispatch(getBidsFailed(err));
        }
      );
    }
    
  };
}

function getBidsSuccess(data) {
  return {
    type: "GET_BIDS_SUCCESS",
    payload: data,
  };
}

function getBidsFailed(err) {
  return {
    type: "GET_BIDS_FAILED",
  };
}

export const getBidEvents = () => async (dispatch) => {
  try {
    // const res = await axios.get("http://localhost:3001/api/bid/bid-events");
    const res = await request("get", "GET_BID_EVENTS", true);
    dispatch(getBidEventsData(res));
    dispatch(clearBids());
    // console.log(res.data);
  } catch (err) {
    dispatch(bidError(err));
    console.log(err.message);
  }
};

const getBidsData = (res) => {
  return {
    type: "GET_BIDS",
    payload: res.data,
  };
};

const getBidEventsData = (res) => {
  return {
    type: "GET_BIDEVENTS",
    payload: res.data,
  };
};

const bidError = (err) => {
  return {
    type: "BID_ERROR",
    payload: { msg: err.message },
  };
};

const clearEvents = () => {
  return {
    type: "CLEAR_EVENTS",
  };
};

const clearBids = () => {
  return {
    type: "CLEAR_BIDS",
  };
};
