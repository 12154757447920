import React, { useState, useEffect } from "react";
import { getBids, postBidAfterPayment } from "../../actions/bidActions";
import {
  Typography,
  Button,
  Popper,
  Grid,
  Select,
  MenuItem,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import { getUserAddress } from "../../../src/actions/applicantsDetailsActions";
import { useSelector, useDispatch } from "react-redux";
import useStyles from "./styles";
import { constants } from "../../helpers/constants";
import PurchaseDetails from "../BidPayment/PurchaseDetails";
import { openCheckout } from "../PaymentDetails/Checkout";
import { openDialog, closeDialog } from "../../actions/appActions";
import {
  postBid,
  getRazorPaySuccessCapture,
} from "../../actions/bidPaymentActions";
import {
  getAdById,
  getAdGalleryDetailsById,
} from "../../actions/itemAdActions";
const BidForm = ({ itemAd, userDetail, history, dispatch, isAllUserBids, isAfterFinalCall, page }) => {
  const classes = useStyles();
  const [purchaseOpen, setPurchaseOpen] = useState(false);

  // const [subs, setSubs] = useState([]);

  // cons = itemAd.price;

  const [formData, setformData] = useState({
    postedBy: userDetail?._id,
    postedByUsername: userDetail?.username,
    bidamt: "",
    type: "Select Type",
    item_id: itemAd._id,
  });

  const handleChange = (e) => {
    setformData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // if (formData.bidUsername.length < 3) {
    //   return dispatch(openDialog("Mininum bid username length is 3"));
    // }
    // if (new RegExp(subs.join("|")).test(formData.bidUsername)) {
    //   return dispatch(
    //     openDialog(
    //       "Bid username can’t contain any 3 consecutive characters of the login user name."
    //     )
    //   );
    // }

    // itemAd.hasPaid
    //   ? dispatch(postBidAfterPayment(formData, history))
    //   : setPurchaseOpen(true);

    dispatch(postBidAfterPayment(formData, history, isAllUserBids, isAfterFinalCall, page))
  };

  // const getAllSubstrings = (str, size) => {
  //   var i,
  //     j,
  //     result = [];
  //   size = size || 0;
  //   for (i = 0; i < str.length; i++) {
  //     for (j = str.length; j - i >= size; j--) {
  //       result.push(str.slice(i, j));
  //     }
  //   }
  //   return result;
  // };

  // useEffect(() => {
  //   var arr = getAllSubstrings(userDetail && userDetail.username, 3);
  //   setSubs(arr);
  // }, [userDetail]);

  const handleCallbackRazorPayCapture = () => {
    dispatch(openDialog("Bid Posted!"));
    // dispatch(getBids({ item_id: itemAd._id }));
    dispatch(getAdById({ item_id: itemAd._id }));
  };

  const handleCallbackRazorPaySuccess = (res, purchaseId) => {
    setPurchaseOpen(false);
    const body = {
      razorpay_order_id: res.razorpay_order_id,
      razorpay_payment_id: res.razorpay_payment_id,
      razorpay_signature: res.razorpay_signature,
      purchaseId: purchaseId,
    };
    dispatch(getRazorPaySuccessCapture(body, handleCallbackRazorPayCapture));
  };

  const handleCallRazorPayMethod = (razorPayData) => {
    setPurchaseOpen(false);
    openCheckout(
      userDetail,
      razorPayData.totalPriceAfterTax,
      razorPayData.razorpay_order_id,
      razorPayData._id,
      handleCallbackRazorPaySuccess
    );
  };

  const handleMakePayment = () => {
    setPurchaseOpen(false);
    dispatch(postBid(formData, history, handleCallRazorPayMethod));
  };

  return (
    <Grid container justify="center" spacing={0}>
      <Grid container xs={4} className={classes.paper} spacing={0}>
        <Grid
          container
          justify="center"
          alignItems="center"
          alignContent="center"
          spacing={0}
        >
          {/* <Grid xs={12} justify="center" alignItems="center">
            <Button
              variant="contained"
              color="secondary"
              size="small"
              style={{ float: "right", textTransform: "none" }}
              onClick={() => history.push("/ads")}
            >
              Back
            </Button>
          </Grid> */}
          {/* <Grid xs={10} justify="center" alignItems="center"> */}
          <Typography component="h1" variant="h4" className={classes.title}>
            Post A Bid
          </Typography>
          {/* </Grid> */}
        </Grid>
        <form className={classes.form} onSubmit={(e) => handleSubmit(e)}>
          <Grid container spacing={0}>
            {/* <Grid item xs={12}>
              <Typography
                component="h1"
                variant="h6"
                style={{ margin: 10 }}
                color="secondary"
              >
                Base Amount : ₹ {Number(baseamt).toLocaleString("en-IN")}
              </Typography>
            </Grid> */}
            {/* <Grid item xs={5}>
              <Typography
                style={{ margin: 10, color: constants.PRIMARY_COLOR }}
              >
                Bid Username*
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <TextField
                value={formData.bidUsername}
                onChange={(e) => {
                  handleChange(e);
                }}
                mi
                fullWidth
                required
                name="bidUsername"
              />
            </Grid> */}
            <Grid item xs={5}>
              <Typography
                style={{ margin: 10, color: constants.PRIMARY_COLOR }}
              >
                Bid Type*
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Select
                name="type"
                fullWidth
                value={formData.type}
                onChange={(e) => handleChange(e)}
              >
                <MenuItem disabled value="">
                  Select Type
                </MenuItem>
                <MenuItem value={"Open"}>Open</MenuItem>
                <MenuItem value={"Hidden"}>Hidden</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={5}>
              <Typography
                style={{ margin: 10, color: constants.PRIMARY_COLOR }}
              >
                Bid Amount*
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <TextField
                value={formData.bidamt}
                onChange={(e) =>
                  (Number(e.target.value) > 0 || e.target.value === "") &&
                  setformData({ ...formData, bidamt: e.target.value })
                }
                fullWidth
                required
                name="bidamt"
              />
            </Grid>

            <Grid item xs={12} align="center">
              <Button
                variant="contained"
                type="submit"
                size="large"
                className={classes.submit}
                style={{ textTransform: "none" }}
                color="primary"
              >
                Post Bid!
              </Button>
              {purchaseOpen && (
                <PurchaseDetails
                  open={purchaseOpen}
                  handleClose={() => setPurchaseOpen(false)}
                  handleMakePayment={handleMakePayment}
                  bidamt={formData.bidamt}
                />
              )}
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default connect()(withRouter(BidForm));
