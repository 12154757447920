import request from "../helpers/requestEngine";
import request1 from "../helpers/request";
import getURL from "../helpers/apis";
import { openDialog, closeDialog } from "./appActions";

export const postUserReview = (formData) => {
  return (dispatch) =>
    request("post", "USER_REVIEW", true, formData).then(
      (response) => {
        dispatch(postUserReviewSuccess(response.data.data));
        // history.goBack();
        dispatch(openDialog("Review Posted!"));
        dispatch(getReviews({ userId: response.data.data.userId }));
        return response.data;
      },
      (err) => {
        dispatch(
          openDialog(
            err.response.data.msg || "Something went wrong. Please try again."
          )
        );
        dispatch(postUserReviewFailed(err));
        return err.response.data;
      }
    );
};

function postUserReviewSuccess() {
  return {
    type: "POST_USER_REVIEW_SUCCESS",
  };
}

function postUserReviewFailed(err) {
  return {
    type: "POST_USER_REVIEW_FAILED",
  };
}

export const getReviews = (params) => {
  return (dispatch) => {
    dispatch(fetchingUserReviews());

    return dispatch(fetchUserReviews(params));
  };
};

function fetchingUserReviews() {
  return {
    type: "GET_USER_REVIEWS",
  };
}

function fetchUserReviews(params) {
  return (dispatch) => {
    request("get", "USER_REVIEW", true, null, params).then(
      (response) => {
        dispatch(getReviewsSuccess(response.data.data));
      },
      (err) => {
        dispatch(
          openDialog(
            err.response.data.data || "Something went wrong. Please try again."
          )
        );

        dispatch(getReviewsFailed(err));
      }
    );
  };
}

function getReviewsSuccess(data) {
  return {
    type: "GET_USER_REVIEWS_SUCCESS",
    payload: data,
  };
}

function getReviewsFailed(err) {
  return {
    type: "GET_USER_REVIEWS_FAILED",
  };
}
