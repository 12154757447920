import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  TextField,
  MenuItem,
  Button,
  Switch,
  Typography,
  Select,
  Divider,
  IconButton,
} from "@material-ui/core";
import { connect } from "react-redux";
import { useHistory, Redirect, Link } from "react-router-dom";
import { Loader } from "../UtilityComponents";
import {
  getUserSettings,
  changeSetting,
} from "../../actions/userDetailsAction";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import PasswordChange from "./PasswordChange";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 30,
    width: "100%",
    textAlign: "center",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
    width: "inherit",
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
    justifyContent: "space-between",
    width: "95%",
  },
}));

const BlueSwitch = withStyles({
  switchBase: {
    // color: purple[300],
    "&$checked": {
      color: "#0A3C9D",
    },
    "&$checked + $track": {
      backgroundColor: "#0A3C9D",
    },
  },
  checked: {},
  track: {},
})(Switch);

const Settings = ({ userSettings, dispatch }) => {
  const classes = useStyles();
  var history = useHistory();
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [showMobileNumber, setShowMobileNumber] = useState(null);
  const [showEmail, setShowEmail] = useState(null);
  const [showAddress, setShowAddress] = useState(null);

  useEffect(() => {
    dispatch(getUserSettings());
    if (!openChangePassword) {
      return () => {
        dispatch(getUserSettings());
      };
    }
  }, []);

  useEffect(() => {
    if (userSettings) {
      setShowMobileNumber(userSettings && userSettings.showMobileNumber);
      setShowEmail(userSettings && userSettings.showEmail);
      setShowAddress(userSettings && userSettings.showAddress);
    }
  }, [userSettings]);

  const handleToggleSetting = (setting) => {
    changeSetting(setting).then((res) => {
      // res.showBirthDate_Month !== undefined &&
      //   setShowBirthDate_Month(res.showBirthDate_Month);
      // res.showBirthYear !== undefined && setShowBirthYear(res.showBirthYear);
      // res.data &&
      //   res.data.showMobileNumber !== undefined &&
      //   setShowMobileNumber(res.data.showMobileNumber);
      // res.data &&
      //   res.data.showAddress !== undefined &&
      //   setShowAddress(res.data.showAddress);
      // res.data &&
      //   res.data.hide_HiddenFriends_Count !== undefined &&
      //   setHide_HiddenFriends_Count(res.data.hide_HiddenFriends_Count);
      // res.data &&
      //   res.data.stop_Sending_Profile_Viewed_Notification !== undefined &&
      //   setStop_Sending_Profile_Viewed_Notification(
      //     res.data.stop_Sending_Profile_Viewed_Notification
      //   );
      // res.data &&
      //   res.data.auto_Rotation_Of_Videos !== undefined &&
      //   setAuto_Rotation_Of_Videos(res.data.auto_Rotation_Of_Videos);
    });

    "showMobileNumber" === setting && setShowMobileNumber(!showMobileNumber);
    "showEmail" === setting && setShowEmail(!showEmail);
    "showAddress" === setting && setShowAddress(!showAddress);
  };

  return (
    <Paper className={classes.root}>
      {openChangePassword && (
        <PasswordChange
          open={openChangePassword}
          handleClose={() => setOpenChangePassword(false)}
          history={history}
        />
      )}
      <Button
        color="secondary"
        variant="contained"
        size="small"
        onClick={(e) => history.push("/profile-me")}
        style={{ float: "right", margin: "16px", textTransform: "none" }}
      >
        Back
      </Button>
      <Typography variant="h4">Settings</Typography>
      {userSettings ? (
        <>
          <List subheader={<ListSubheader>Personal</ListSubheader>}>
            <Divider />
            <ListItem divider>
              <ListItemText
                id="switch-list-label-3"
                primary="Show Mobile Number"
              />
              <ListItemSecondaryAction>
                <BlueSwitch
                  className={classes.checked}
                  edge="end"
                  onChange={() => handleToggleSetting("showMobileNumber")}
                  checked={showMobileNumber && showMobileNumber}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem divider>
              <ListItemText id="switch-list-label-3" primary="Show Email" />
              <ListItemSecondaryAction>
                <BlueSwitch
                  className={classes.checked}
                  edge="end"
                  onChange={() => handleToggleSetting("showEmail")}
                  checked={showEmail && showEmail}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem divider>
              <ListItemText id="switch-list-label-3" primary="Show Address" />
              <ListItemSecondaryAction>
                <BlueSwitch
                  className={classes.checked}
                  edge="end"
                  onChange={() => handleToggleSetting("showAddress")}
                  checked={showAddress && showAddress}
                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
          <List subheader={<ListSubheader>Security</ListSubheader>}>
            <Divider />
            <ListItem
              divider
              button
              onClick={() => setOpenChangePassword(true)}
            >
              <ListItemText primary="Change Password" />
              <ListItemSecondaryAction>
                <IconButton onClick={() => setOpenChangePassword(true)}>
                  <ArrowForwardIosIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </>
      ) : (
        <Loader />
      )}
    </Paper>
  );
};
const mapStateToProps = (state) => {
  return {
    userSettings: state.userDetailsReducer.userSettings,
  };
};
export default connect(mapStateToProps)(Settings);
