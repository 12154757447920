import React, { useEffect, useState, useRef } from "react";
import { Button, Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import VideoDialog from "./UserProfile/VideoDialog";
import { constants } from "../helpers/constants";
import VisibilityIcon from '@material-ui/icons/Visibility';
import { getRequestStatus, requestToGetMobileNumber, setMobileNumberDisplayStatus } from "../actions/userDetailsAction";
import moment from "moment";
import InfoPopover from "./InfoPopover";

const ProfileInfo = ({ userDetails, classes, auth, dispatch, mobileNumberStatusDisplay }) => {
  // Status
  // 1 = Show
  // 2 = Pending
  // 3 = Display
  const [mobileNumberStatus, setMobileNumberStatus] = useState(1)
  const reference = useRef('');
  
  useEffect(()=>{
    reference.current = {
      mobileNumberStatus: mobileNumberStatus,
      userId: userDetails?._id
    }
    return ()=>{
      reference.current = {}
    }
  },[mobileNumberStatus,userDetails?._id])

  const SentRequest = () => {
    console.log("API Call for Send Request")
    if(mobileNumberStatus===1){
      dispatch && dispatch(requestToGetMobileNumber({ requestedTo: userDetails?._id }))
      // dispatch(getRequestStatus({requestedTo: userDetails._id}))
      
    }
  }

  useEffect(()=>{
    
    dispatch && dispatch(getRequestStatus({requestedTo: userDetails?._id}))
    
  },[userDetails?._id])

  useEffect(()=>{
    if(mobileNumberStatusDisplay?.requestedTo===userDetails?._id){
      if(mobileNumberStatusDisplay?.status==='pending'){
        setMobileNumberStatus(2)
      } else {
        setMobileNumberStatus(3)
      }
    } else {
      setMobileNumberStatus(1)
    }
  },[mobileNumberStatusDisplay])

  const displayMobileNumber = (number) => {
    if(userDetails.showMobileNumber){
      return number;
    }
    if(mobileNumberStatus===3){
      return number;
    }
    return number?.substring(0, 3) + "*******";
  }

  useEffect(()=>{
    if(dispatch){
      const a = setInterval(()=>callCheckMobileDisplayStatusAPI(),1*30*1000)
      return ()=>{
        clearInterval(a)
        dispatch(setMobileNumberDisplayStatus({}))
      }
    }
  },[])

  const callCheckMobileDisplayStatusAPI = () =>{
    if((reference.current?.mobileNumberStatus===2 && dispatch)){
      dispatch(getRequestStatus({requestedTo: reference.current?.userId}));
    }
  }

  return (
    <Grid
      container
      xs={12}
      justify="space-around"
      alignItems="flex-start"
      style={{ marginTop: 0 }}
    >
      <Grid
        key={"col-1"}
        container
        justify="space-around"
        xs={6}
        className={classes.columnContainer}
        // justify="center"
      >
        <Grid item xs={4} className={classes.fieldGridLabel}>
          <Typography
            variant="span"
            className={classes.fieldLabel}
            style={{ color: constants.PRIMARY_COLOR }}
            noWrap={true}
          >
            Company Name
          </Typography>
        </Grid>
        <Grid item xs={4} className={classes.fieldGridValue}>
          <Typography
            variant="span"
            className={classes.fieldValue}
            noWrap={true}
          >
            {userDetails.bnr}
          </Typography>
        </Grid>

        <Grid item xs={4} className={classes.fieldGridLabel}>
          <Typography
            variant="span"
            className={classes.fieldLabel}
            style={{ color: constants.PRIMARY_COLOR }}
            noWrap={true}
          >
            First Name
          </Typography>
        </Grid>
        <Grid item xs={4} className={classes.fieldGridValue}>
          <Typography
            variant="span"
            className={classes.fieldValue}
            noWrap={true}
          >
            {userDetails.firstname}
          </Typography>
        </Grid>

        <Grid item xs={4} className={classes.fieldGridLabel}>
          <Typography
            variant="span"
            className={classes.fieldLabel}
            style={{ color: constants.PRIMARY_COLOR }}
            noWrap={true}
          >
            Last Name
          </Typography>
        </Grid>
        <Grid item xs={4} className={classes.fieldGridValue}>
          <Typography
            variant="span"
            className={classes.fieldValue}
            noWrap={true}
          >
            {userDetails.lastname}
          </Typography>
        </Grid>

        <Grid item xs={4} className={classes.fieldGridLabel}>
          <Typography
            variant="span"
            className={classes.fieldLabel}
            style={{ color: constants.PRIMARY_COLOR }}
            noWrap={true}
          >
            Gender
          </Typography>
        </Grid>
        <Grid item xs={4} className={classes.fieldGridValue}>
          <Typography
            variant="span"
            className={classes.fieldValue}
            noWrap={true}
          >
            {userDetails.gender}
          </Typography>
        </Grid>
        {/* 
        <Grid item xs={4} className={classes.fieldGridLabel}>
          <Typography
            variant="span"
            className={classes.fieldLabel}
            style={{ color: constants.PRIMARY_COLOR }}
            noWrap={true}
          >
            DOB
          </Typography>
        </Grid> */}
        {/* {auth ? (
          <>
            <Grid item xs={4} className={classes.fieldGridValue}>
              <Typography
                variant="span"
                className={classes.fieldValue}
                noWrap={true}
              >
                {new Date(userDetails.dob).toLocaleDateString()}
              </Typography>
            </Grid>
          </>
        ) : userDetails.showBirthDate_Month ? (
          <>
            <Grid item xs={4} className={classes.fieldGridValue}>
              <Typography
                variant="span"
                className={classes.fieldValue}
                noWrap={true}
              >
                {userDetails.showBirthDate_Month && userDetails.showBirthYear
                  ? new Date(userDetails.dob).toLocaleDateString()
                  : new Date(userDetails.dob).getDate() +
                    "/" +
                    new Date(userDetails.dob).getMonth()}
              </Typography>
            </Grid>
          </>
        ) : (
          <Grid item xs={4} className={classes.fieldGridValue}>
            <Typography
              variant="span"
              className={classes.fieldValue}
              noWrap={true}
            >
              {" "}
            </Typography>
          </Grid>
        )} */}
        {/* {auth && (
          <>
            <Grid item xs={4} className={classes.fieldGridLabel}>
              <Typography
                variant="span"
                className={classes.fieldLabel}
                style={{ color: constants.PRIMARY_COLOR }}
                noWrap={true}
              >
                Email
              </Typography>
            </Grid>
            <Grid item xs={4} className={classes.fieldGridValue}>
              <Typography
                variant="span"
                className={classes.fieldValue}
                noWrap={true}
              >
                {userDetails.showEmail ? userDetails.email : " "}
              </Typography>
            </Grid>
          </>
        )} */}
        <Grid item xs={4} className={classes.fieldGridLabel}>
              <Typography
                variant="span"
                className={classes.fieldLabel}
                style={{ color: constants.PRIMARY_COLOR }}
                noWrap={true}
              >
                Email
              </Typography>
            </Grid>
            <Grid item xs={4} className={classes.fieldGridValue}>
              <Typography
                variant="span"
                className={classes.fieldValue}
                noWrap={true}
              >
                {auth ? userDetails.email : userDetails.showEmail ? userDetails.email : " "}
              </Typography>
            </Grid>
        {/* <Grid item xs={4} className={classes.fieldGridLabel}>
          <Typography
            variant="span"
            className={classes.fieldLabel}
            style={{ color: constants.PRIMARY_COLOR }}
            noWrap={true}
          >
            Bid Alias
          </Typography>
        </Grid>
        <Grid item xs={4} className={classes.fieldGridValue}>
          <Typography
            variant="span"
            className={classes.fieldValue}
            noWrap={true}
          >
            {userDetails.bidAlias || " "}
          </Typography>
        </Grid> */}

        <Grid item xs={4} className={classes.fieldGridLabel}>
          <Typography
            variant="span"
            className={classes.fieldLabel}
            style={{ color: constants.PRIMARY_COLOR }}
            noWrap={true}
          >
            Mobile
          </Typography>
        </Grid>
        {auth ? (
          <>
            <Grid item xs={4} className={classes.fieldGridValue}>
              <Typography
                variant="span"
                className={classes.fieldValue}
                noWrap={true}
              >
                {userDetails.phone}
              </Typography>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={4} className={classes.fieldGridValue}>
              <Typography
                variant="span"
                className={classes.fieldValue}
                noWrap={true}
              >
                {displayMobileNumber(userDetails.phone)}
                {!userDetails.showMobileNumber && mobileNumberStatus!==3 && <Button size="small" color="primary" onClick={()=>{SentRequest()}}>{mobileNumberStatus===1? 'Show': 'Pending'}</Button>}
              </Typography>
            </Grid>
          </>
        )}
        <Grid item xs={4} className={classes.fieldGridLabel}>
              <Typography
                variant="span"
                className={classes.fieldLabel}
                style={{ color: constants.PRIMARY_COLOR }}
                noWrap={true}
              >
                DOB
              </Typography>
            </Grid>
            <Grid item xs={4} className={classes.fieldGridValue}>
              <Typography
                variant="span"
                className={classes.fieldValue}
                noWrap={true}
              >
                {userDetails.dob ? moment(userDetails.dob).format("DD-MM-YYYY"): "i"}
              </Typography>
            </Grid>
      </Grid>



      <Grid
        key={"col-2"}
        container
        justify="space-around"
        xs={6}
        className={classes.columnContainer}
      >
        {auth ? (
          <>
          {/* House No */}
            <Grid item xs={4} className={classes.fieldGridLabel}>
              <Typography
                variant="span"
                className={classes.fieldLabel}
                style={{ color: constants.PRIMARY_COLOR }}
                noWrap={true}
              >
                House No
              </Typography>
            </Grid>
            <Grid item xs={4} className={classes.fieldGridValue}>
              <Typography
                variant="span"
                className={classes.fieldValue}
                noWrap={true}
              >
                {userDetails.houseno}
              </Typography>
            </Grid>

            {/* Street */}
            <Grid item xs={4} className={classes.fieldGridLabel}>
              <Typography
                variant="span"
                className={classes.fieldLabel}
                style={{ color: constants.PRIMARY_COLOR }}
                noWrap={true}
              >
                Street
              </Typography>
            </Grid>
            <Grid item xs={4} className={classes.fieldGridValue}>
              <Typography
                variant="span"
                className={classes.fieldValue}
                noWrap={true}
              >
                {userDetails.street}
              </Typography>
            </Grid>

            {/* Town/Village */}
            <Grid item xs={4} className={classes.fieldGridLabel}>
              <Typography
                variant="span"
                className={classes.fieldLabel}
                style={{ color: constants.PRIMARY_COLOR, display: "flex" }}
                noWrap={true}
              >
                Town/Village <InfoPopover info="Enter a subdivision of neighbourhood, if nothing available, keep neighbourhood" />
              </Typography>
            </Grid>
            <Grid item xs={4} className={classes.fieldGridValue}>
              <Typography
                variant="span"
                className={classes.fieldValue}
                noWrap={true}
              >
                {userDetails.city}
              </Typography>
            </Grid>

            {/* Neighbourhood */}
            <Grid item xs={4} className={classes.fieldGridLabel}>
              <Typography
                variant="span"
                className={classes.fieldLabel}
                style={{ color: constants.PRIMARY_COLOR }}
                noWrap={true}
              >
                Neighbourhood
              </Typography>
            </Grid>
            <Grid item xs={4} className={classes.fieldGridValue}>
              <Typography
                variant="span"
                className={classes.fieldValue}
                noWrap={true}
              >
                {userDetails.neighbourHood || '-'}
              </Typography>
            </Grid>

            

            {/* District */}
            <Grid item xs={4} className={classes.fieldGridLabel}>
              <Typography
                variant="span"
                className={classes.fieldLabel}
                style={{ color: constants.PRIMARY_COLOR }}
                noWrap={true}
              >
                District
              </Typography>
            </Grid>
            <Grid item xs={4} className={classes.fieldGridValue}>
              <Typography
                variant="span"
                className={classes.fieldValue}
                noWrap={true}
              >
                {userDetails.district}
              </Typography>
            </Grid>

            <Grid item xs={4} className={classes.fieldGridLabel}>
              <Typography
                variant="span"
                className={classes.fieldLabel}
                style={{ color: constants.PRIMARY_COLOR }}
                noWrap={true}
              >
                Pincode
              </Typography>
            </Grid>
            <Grid item xs={4} className={classes.fieldGridValue}>
              <Typography
                variant="span"
                className={classes.fieldValue}
                noWrap={true}
              >
                {userDetails.pincode}
              </Typography>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={4} className={classes.fieldGridLabel}>
              <Typography
                variant="span"
                className={classes.fieldLabel}
                style={{ color: constants.PRIMARY_COLOR }}
                noWrap={true}
              >
                House No
              </Typography>
            </Grid>
            <Grid item xs={4} className={classes.fieldGridValue}>
              <Typography
                variant="span"
                className={classes.fieldValue}
                noWrap={true}
              >
                {userDetails.showAddress ? userDetails.houseno : " "}
              </Typography>
            </Grid>

            <Grid item xs={4} className={classes.fieldGridLabel}>
              <Typography
                variant="span"
                className={classes.fieldLabel}
                style={{ color: constants.PRIMARY_COLOR }}
                noWrap={true}
              >
                Street
              </Typography>
            </Grid>
            <Grid item xs={4} className={classes.fieldGridValue}>
              <Typography
                variant="span"
                className={classes.fieldValue}
                noWrap={true}
              >
                {userDetails.showAddress ? userDetails.street : " "}
              </Typography>
            </Grid>

{/* Town/Village */}
            <Grid item xs={4} className={classes.fieldGridLabel}>
              <Typography
                variant="span"
                className={classes.fieldLabel}
                style={{ color: constants.PRIMARY_COLOR, display: "flex" }}
                noWrap={true}
              >
                Town/Village <InfoPopover info="Enter a subdivision of neighbourhood, if nothing available, keep neighbourhood" />
              </Typography>
            </Grid>
            <Grid item xs={4} className={classes.fieldGridValue}>
              <Typography
                variant="span"
                className={classes.fieldValue}
                noWrap={true}
              >
                {userDetails.showAddress ? userDetails.city : " "}
              </Typography>
            </Grid>
            {/* Neighbourhood */}
            <Grid item xs={4} className={classes.fieldGridLabel}>
              <Typography
                variant="span"
                className={classes.fieldLabel}
                style={{ color: constants.PRIMARY_COLOR }}
                noWrap={true}
              >
                Neighbourhood
              </Typography>
            </Grid>
            <Grid item xs={4} className={classes.fieldGridValue}>
              <Typography
                variant="span"
                className={classes.fieldValue}
                noWrap={true}
              >
                {userDetails.neighbourHood || '-'}
              </Typography>
            </Grid> 

{/* District */}
            <Grid item xs={4} className={classes.fieldGridLabel}>
              <Typography
                variant="span"
                className={classes.fieldLabel}
                style={{ color: constants.PRIMARY_COLOR }}
                noWrap={true}
              >
                District
              </Typography>
            </Grid>
            <Grid item xs={4} className={classes.fieldGridValue}>
              <Typography
                variant="span"
                className={classes.fieldValue}
                noWrap={true}
              >
                {userDetails.showAddress ? userDetails.district : " "}
              </Typography>
            </Grid>

            <Grid item xs={4} className={classes.fieldGridLabel}>
              <Typography
                variant="span"
                className={classes.fieldLabel}
                style={{ color: constants.PRIMARY_COLOR }}
                noWrap={true}
              >
                Pincode
              </Typography>
            </Grid>
            <Grid item xs={4} className={classes.fieldGridValue}>
              <Typography
                variant="span"
                className={classes.fieldValue}
                noWrap={true}
              >
                {userDetails.showAddress ? userDetails.pincode : " "}
              </Typography>
            </Grid>
          </>
        )}

        <Grid item xs={4} className={classes.fieldGridLabel}>
          <Typography
            variant="span"
            className={classes.fieldLabel}
            style={{ color: constants.PRIMARY_COLOR }}
            noWrap={true}
          >
            State
          </Typography>
        </Grid>
        <Grid item xs={4} className={classes.fieldGridValue}>
          <Typography
            variant="span"
            className={classes.fieldValue}
            noWrap={true}
          >
            {userDetails.state}
          </Typography>
        </Grid>

        <Grid item xs={4} className={classes.fieldGridLabel}>
          <Typography
            variant="span"
            className={classes.fieldLabel}
            style={{ color: constants.PRIMARY_COLOR }}
            noWrap={true}
          >
            Country
          </Typography>
        </Grid>
        <Grid item xs={4} className={classes.fieldGridValue}>
          <Typography
            variant="span"
            className={classes.fieldValue}
            noWrap={true}
          >
            {userDetails.country}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProfileInfo;
