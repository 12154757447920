import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import BidForm from "./BidForm";
import BidFeed from "./BidFeed";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

// import Moment from "react-moment";

const Bid = ({ userDetail }) => {
  return (
    <Grid container justify="center">
      <Grid item xs={12}>
        <Grid container justify="center">
          {userDetail && <BidForm userDetail={userDetail} />}
          <BidFeed />
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    userDetail: state.userDetailsReducer.userDetail,
  };
};

export default connect(mapStateToProps)(withRouter(Bid));
