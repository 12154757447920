import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { TextField, withStyles, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Redirect } from "react-router-dom";
import withRouter from "react-router-dom/es/withRouter";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import ListItemText from "@material-ui/core/ListItemText";

import { getUsersByKeyword } from "../../actions/userDetailsAction";
import { constants } from "../../helpers/constants";
import { openDialog, closeDialog } from "../../actions/appActions";

const SearchField = ({ classes, dispatch, users, history, userId }) => {
  const [keyword, setKeyword] = useState("");
  useEffect(() => {
    if (keyword.length >= 3) {
      const params = { key: keyword, page: 0, limit: 10 };
      dispatch(getUsersByKeyword(params));
    }
  }, [keyword.length]);

  const handleOnType = (e) => {
    setKeyword(e.target.value);
  };

  const handleOnChange = (e, option) => {
    return history.push(`/user_profile/${option._id}`);
  };

  // const [filteredUsers, setFilteredUsers] = useState([]);
  // useEffect(() => {
  //   setFilteredUsers(
  //     users.filter((user) => !(user.isBlocked || user._id === userId))
  //   );
  // }, [users]);

  useEffect(() => {
    console.log("users", users);
  }, [users]);

  return (
    <React.Fragment>
      <Autocomplete
        fullWidth
        freeSolo
        disableClearable
        options={users.filter(
          (user) => !(user.isBlocked || user._id === userId)
        )}
        // renderOptions={(option) => {
        //   console.log("option", option);
        //   return (
        //     <React.Fragment>
        //       <ListItemText
        //         style={{ color: constants.PRIMARY_COLOR }}
        //         primary={option.username}
        //         secondary={
        //           option.username.startsWith("@")
        //             ? option.username
        //             : option.username.replace("@", "")
        //         }
        //       />
        //     </React.Fragment>
        //   );
        // }}
        getOptionLabel={(option) => (option && option.username) || ""}
        className={classes.autocomplete}
        onChange={(e, option) =>
          option._id
            ? handleOnChange(e, option)
            : dispatch(openDialog("No Users Found"))
        }
        renderInput={(params) => (
          <React.Fragment>
            <TextField
              {...params}
              style={{ color: "white" }}
              margin="normal"
              variant="outlined"
              placeholder="Search Users"
              fullWidth
              inputValue={keyword}
              onChange={(e) => handleOnType(e)}
              InputProps={{
                ...params.InputProps,
                type: "search",
                className: classes.autocompleteField,
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
            />
          </React.Fragment>
        )}
      />
    </React.Fragment>
  );
};

const styles = {
  autocomplete: {
    width: "40%",
  },
  autocompleteField: {
    background: "white",
  },
};

const mapStateToProps = (state) => {
  return {
    users: state.usersReducer.users || [],
  };
};

export default connect(mapStateToProps)(
  withRouter(withStyles(styles)(SearchField))
);
