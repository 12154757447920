import io from "socket.io-client";
import { getToken } from "./globalFunctions";
import { base_url } from "./apis";

/**
 * Socket Route
/bid

Have to call to get the data from socket

Socket Event name
/getBids

Response Event Name
/getBidResponse
 */

let socket;
const chatEventTarget = new EventTarget();

socket = io(base_url, {
  path: "/bid",
  transports: ["websocket"],
  query: {
    token: getToken(),
  },
});

socket.on("connect", () => {
  console.log("connected");
  let incomingChatEvent = new CustomEvent("connected");
  chatEventTarget.dispatchEvent(incomingChatEvent);
  socket.emit("load");
});
//

socket.on("connect_error", (err) => {
  console.log(`connect_error due to`, err);
});

socket.on("getBidResponse", function (data) {
  console.log("getBidResponse", data);
  let incomingChatEvent = new CustomEvent("getBidResponse", { detail: data });
  chatEventTarget.dispatchEvent(incomingChatEvent);
});

socket.on("online", function (data) {
  let incomingChatEvent = new CustomEvent("isOnline", { detail: data });
  chatEventTarget.dispatchEvent(incomingChatEvent);
});

socket.on("reconnect", (attemptNumber) => {
  console.log("reconnect", attemptNumber);
});

socket.on("reconnecting", (attemptNumber) => {
  console.log("reconnecting", attemptNumber);
});

socket.on("connect_error", (err) => {
  console.log(`connect_error due to`, err);
});

socket.on("getBids", function (data) {
  console.log("getBids", data);
  let incomingChatEvent = new CustomEvent("getBids", { detail: data });
  chatEventTarget.dispatchEvent(incomingChatEvent);
});

export const establishSocketConnection = (orderId) => {
  socket.on(`newbid:${orderId}`, function (data) {
    console.log(data);
    let incomingChatEvent = new CustomEvent("bids", { detail: data });
    chatEventTarget.dispatchEvent(incomingChatEvent);
  });

  return chatEventTarget;
};
