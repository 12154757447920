const initialState = {
  firstname: "",
  lastname: "",
  email: "",
  password: "",
  confirmPassword: "",
  username: "",
  usertype: "",
  termsOfServicesCheckBox: false,
  countries: [],
  states: [],
  districts: [],
  industries: [],
  userDetail: null,
  jwt: null,
  neighbourhoods: []
};

const registerReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_ALL_COUNTRIES_SUCCESS":
      console.log(action.payload.data.data.filter((item)=>item.country))
      return {
        ...state,
        countries: action.payload.data.data.filter((item) => item.country),
    
      };
    case "FETCH_ALL_COUNTRIES_FAILURE":
      return {
        ...state,
      };
    case "FETCH_STATES_SUCCESS":
      return {
        ...state,
        states: !!action.payload.data.data[0]
          ? action.payload.data.data[0].states
          : [],
      };
    case "FETCH_STATES_FAILURE":
      return {
        ...state,
      };
    case "FETCH_DISTRICTS_SUCCESS":
      return {
        ...state,
        districts: action.payload.data.data,
      };
    case "FETCH_DISTRICTS_FAILURE":
      return {
        ...state,
      };
    case "FETCH_INDUSTRIES_SUCCESS":
      return {
        ...state,
        industries: action.payload.data.data,
      };
    case "FETCH_INDUSTRIES_FAILURE":
      return {
        ...state,
      };
    case "REGISTER_FIRSTNAME_CHANGED":
      return {
        ...state,
        firstname: action.firstname,
      };
    case "REGISTER_LASTNAME_CHANGED":
      return {
        ...state,
        lastname: action.lastname,
      };
    case "REGISTER_EMAIL_CHANGED":
      return {
        ...state,
        email: action.email,
      };
    case "REGISTER_PASSWORD_CHANGED":
      return {
        ...state,
        password: action.password,
      };
    case "REGISTER_USERNAME_CHANGED":
      return {
        ...state,
        username: action.username,
      };
    case "REGISTER_CHECKBOX_TOGGLE":
      return {
        ...state,
        termsOfServicesCheckBox: !state.termsOfServicesCheckBox,
      };
    case "REGISTER_USERTYPE_CHANGED":
      return {
        ...state,
        usertype: action.usertype,
      };

    case "REGISTER_GENDER_CHANGED":
      return {
        ...state,
        gender: action.gender,
      };
    case "SET_INITIAL_REGISTER_STATE":
      return initialState;
    case "REGISTRATION_SUCCESS":
      return {
        ...state,
        userDetail: action.userDetail,
        jwt: action.jwt,
      };
    case 'FETCH_NEIGHBOURHOOD_SUCCESS': {
      return {
        ...state,
        neighbourhoods: !!action.payload.data.data[0]
        ? action.payload.data.data
        : [],
      }
    }
    case "FETCH_NEIGHBOURHOOD_FAILURE":
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default registerReducer;
