import { combineReducers, applyMiddleware, createStore } from "redux";
import promise from "redux-promise-middleware";
import thunk from "redux-thunk";
import logger from "redux-logger";
import { composeWithDevTools } from "redux-devtools-extension";

import loginReducer from "./reducers/loginReducer";
import appReducer from "./reducers/appReducer";
import userDetailsReducer from "./reducers/userDetailsReducer";
import usersReducer from "./reducers/usersReducer";
import registerReducer from "./reducers/registerReducer";
import itemAdsReducer from "./reducers/itemAdsReducer";
import bidReducer from "./reducers/bidReducer";
import AdbidReducer from "./reducers/AdbidReducer";
import adSpaceReducer from "./reducers/adSpaceReducer";
import bidPaymentReducer from "./reducers/bidPaymentReducer";
import reviewReducer from "./reducers/reviewReducer";
import shareBidReducer from "./reducers/shareBidReducer";

const middleware = applyMiddleware(promise(), thunk, logger);

//creating auth store
const authReducers = combineReducers({
  appReducer,
  loginReducer,
  userDetailsReducer,
  usersReducer,
  registerReducer,
  itemAdsReducer,
  bidReducer,
  AdbidReducer,
  adSpaceReducer,
  bidPaymentReducer,
  reviewReducer,
  shareBidReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGGED_OUT") {
    state = undefined;
  }

  return authReducers(state, action);
};

//export const authStore = createStore(authReducers, middleware);
// export default createStore(authReducers, middleware);
export default createStore(rootReducer, composeWithDevTools(middleware));

// creating unAuth store
// const unAuthReducer = combineReducers({
//     appReducer,
//     loginReducer,
//     registerReducer
// });

// export const unAuthStore = createStore(unAuthReducer, middleware);
