import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Typography,
  FormLabel,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { Loader } from "../../components/UtilityComponents";
import { connect } from "react-redux";
import withRouter from "react-router-dom/es/withRouter";
import { getPurchaseDetails } from "../../actions/userDetailsAction";
import { constants } from "../../helpers/constants";

const PurchaseDetails = ({
  open,
  purchaseType,
  handleClose,
  handleMakePayment,
  subscriptionId,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    // if (purchaseType === "upgrade") {
    //   dispatch(getUpgradeDetails(subscriptionId));
    // } else {
    dispatch(getPurchaseDetails(subscriptionId));
    // }
  }, [subscriptionId]);
  // const upgradeDetails = useSelector(
  //   ({ userDetailsReducer }) => userDetailsReducer.upgradeDetails
  // );
  // const upgradeDetailsLoading = useSelector(
  //   ({ userDetailsReducer }) => userDetailsReducer.upgradeDetailsLoading
  // );
  const purchaseDetails = useSelector(
    ({ userDetailsReducer }) => userDetailsReducer.purchaseDetails
  );
  const purchaseDetailsLoading = useSelector(
    ({ userDetailsReducer }) => userDetailsReducer.purchaseDetailsLoading
  );

  if (!purchaseDetailsLoading) {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        className="inherit-bg-color"
      >
        <DialogTitle id="scroll-dialog-title">
          Purchase Subscription
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={0}>
            <Grid item xs={8}>
              <Typography style={{ color: constants.PRIMARY_COLOR }}>
                Plan Price
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>
                ₹{" "}
                {purchaseDetails &&
                  Number(purchaseDetails.totalPrice).toLocaleString("en-IN")}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography style={{ color: constants.PRIMARY_COLOR }}>
                SGST Percent
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>
                {purchaseDetails && purchaseDetails.taxSGSTPercent}%
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography style={{ color: constants.PRIMARY_COLOR }}>
                CGST Percent
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>
                {purchaseDetails && purchaseDetails.taxCGSTPercent}%
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography style={{ color: constants.PRIMARY_COLOR }}>
                SGST Amount
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>
                ₹{" "}
                {purchaseDetails &&
                  Number(purchaseDetails.taxCGSTAmount).toLocaleString("en-IN")}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography style={{ color: constants.PRIMARY_COLOR }}>
                CGST Amount
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>
                ₹{" "}
                {purchaseDetails &&
                  Number(purchaseDetails.taxSGSTAmount).toLocaleString("en-IN")}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography style={{ color: constants.PRIMARY_COLOR }}>
                Total Price After Tax
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>
                ₹{" "}
                {purchaseDetails &&
                  Number(purchaseDetails.totalPriceAfterTax).toLocaleString(
                    "en-IN"
                  )}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography style={{ color: constants.PRIMARY_COLOR }}>
                Adjusted Final Payment
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>
                ₹{" "}
                {purchaseDetails &&
                  Number(purchaseDetails.adjustedFinalPayment).toLocaleString(
                    "en-IN"
                  )}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleClose}
            color="primary"
            style={{ textTransform: "none" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleMakePayment}
            color="primary"
            style={{ textTransform: "none" }}
          >
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  return (
    <div>
      <Loader />
    </div>
  );
};

export default PurchaseDetails;
