import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { constants } from "../../helpers/constants";

const AdDetails = ({ itemAd, classes, userDetail }) => {
  return (
    <Grid
      container
      xs={12}
      justify="space-around"
      alignItems="flex-start"
      style={{ marginTop: "32px" }}
    >
      {/* <Grid item xs={4} className={classes.fieldGridLabel}>
        <Typography
          variant="span"
          className={classes.fieldLabel}
          style={{ color: constants.PRIMARY_COLOR }}
          noWrap={true}
        >
          Item Name
        </Typography>
      </Grid>
      <Grid item xs={4} className={classes.fieldGridValue}>
        <Typography variant="span" className={classes.fieldValue} noWrap={true}>
          {itemAd.itemName}
        </Typography>
      </Grid>
      <Grid item xs={4} className={classes.fieldGridLabel}>
        <Typography
          variant="span"
          className={classes.fieldLabel}
          style={{ color: constants.PRIMARY_COLOR }}
          noWrap={true}
        >
          Price
        </Typography>
      </Grid>
      <Grid item xs={4} className={classes.fieldGridValue}>
        <Typography variant="span" className={classes.fieldValue} noWrap={true}>
          ₹ {Number(itemAd.price).toLocaleString("en-IN")}
        </Typography>
      </Grid> */}
      <Grid item xs={4} className={classes.fieldGridLabel}>
        <Typography
          variant="span"
          className={classes.fieldLabel}
          style={{ color: constants.PRIMARY_COLOR }}
          noWrap={true}
        >
          Category
        </Typography>
      </Grid>
      <Grid item xs={4} className={classes.fieldGridValue}>
        <Typography variant="span" className={classes.fieldValue} noWrap={true}>
          {itemAd.categoryname}
        </Typography>
      </Grid>
      <Grid item xs={4} className={classes.fieldGridLabel}>
        <Typography
          variant="span"
          className={classes.fieldLabel}
          style={{ color: constants.PRIMARY_COLOR }}
          noWrap={true}
        >
          Sub Category
        </Typography>
      </Grid>
      <Grid item xs={4} className={classes.fieldGridValue}>
        <Typography variant="span" className={classes.fieldValue} noWrap={true}>
          {itemAd.subcategory || '-'}
        </Typography>
      </Grid>
      {
        itemAd.categoryname==="Automobiles" || itemAd.categoryname==="Ships Boats" || (itemAd.categoryname==="Bank Auctions" && (itemAd.subcategory==="Cars" || itemAd.subcategory==="Bikes")) ? <>
        <Grid item xs={4} className={classes.fieldGridLabel}>
        <Typography
          variant="span"
          className={classes.fieldLabel}
          style={{ color: constants.PRIMARY_COLOR }}
          noWrap={true}
        >
          Manufacturer
        </Typography>
      </Grid>
      <Grid item xs={4} className={classes.fieldGridValue}>
        <Typography variant="span" className={classes.fieldValue} noWrap={true}>
          {itemAd.make || '-'}
        </Typography>
      </Grid>
      <Grid item xs={4} className={classes.fieldGridLabel}>
        <Typography
          variant="span"
          className={classes.fieldLabel}
          style={{ color: constants.PRIMARY_COLOR }}
          noWrap={true}
        >
          Model
        </Typography>
      </Grid>
      <Grid item xs={4} className={classes.fieldGridValue}>
        <Typography variant="span" className={classes.fieldValue} noWrap={true}>
          {itemAd.model || '-'}
        </Typography>
      </Grid>
      <Grid item xs={4} className={classes.fieldGridLabel}>
        <Typography
          variant="span"
          className={classes.fieldLabel}
          style={{ color: constants.PRIMARY_COLOR }}
          noWrap={true}
        >
          Mileage
        </Typography>
      </Grid>
      <Grid item xs={4} className={classes.fieldGridValue}>
        <Typography variant="span" className={classes.fieldValue} noWrap={true}>
          {itemAd.mileage || '-'}
        </Typography>
      </Grid>
      <Grid item xs={4} className={classes.fieldGridLabel}>
        <Typography
          variant="span"
          className={classes.fieldLabel}
          style={{ color: constants.PRIMARY_COLOR }}
          noWrap={true}
        >
          Year
        </Typography>
      </Grid>
      <Grid item xs={4} className={classes.fieldGridValue}>
        <Typography variant="span" className={classes.fieldValue} noWrap={true}>
          {itemAd.yearOfManufacture || '-'}
        </Typography>
      </Grid>
      <Grid item xs={4} className={classes.fieldGridLabel}>
        <Typography
          variant="span"
          className={classes.fieldLabel}
          style={{ color: constants.PRIMARY_COLOR }}
          noWrap={true}
        >
          Owner/Dealer
        </Typography>
      </Grid>
      <Grid item xs={4} className={classes.fieldGridValue}>
        <Typography variant="span" className={classes.fieldValue} noWrap={true}>
          {itemAd?.ownerOrDealer || '-'}
        </Typography>
      </Grid>
      <Grid item xs={4} className={classes.fieldGridLabel}>
        <Typography
          variant="span"
          className={classes.fieldLabel}
          style={{ color: constants.PRIMARY_COLOR }}
          noWrap={true}
        >
          New/Used
        </Typography>
      </Grid>
      <Grid item xs={4} className={classes.fieldGridValue}>
        <Typography variant="span" className={classes.fieldValue} noWrap={true}>
          {itemAd?.newOrused || '-'}
        </Typography>
      </Grid>
        </> : null
      }
      <Grid item xs={4} className={classes.fieldGridLabel}>
        <Typography
          variant="span"
          className={classes.fieldLabel}
          style={{ color: constants.PRIMARY_COLOR }}
          noWrap={true}
        >
          Posted By
        </Typography>
      </Grid>
      <Grid item xs={4} className={classes.fieldGridValue}>
        <Typography variant="span" className={classes.fieldValue} noWrap={true}>
          {itemAd.postedByUsername}
        </Typography>
      </Grid>
      {itemAd.displayAd && (
        <>
          <Grid item xs={4} className={classes.fieldGridLabel}>
            <Typography
              variant="span"
              className={classes.fieldLabel}
              style={{ color: constants.PRIMARY_COLOR }}
              noWrap={true}
            >
              Phone
            </Typography>
          </Grid>
          <Grid item xs={4} className={classes.fieldGridValue}>
            <Typography
              variant="span"
              className={classes.fieldValue}
              noWrap={true}
            >
              {itemAd.phone}
            </Typography>
          </Grid>
        </>
      )}

      <Grid item xs={4} className={classes.fieldGridLabel}>
        <Typography
          variant="span"
          className={classes.fieldLabel}
          style={{ color: constants.PRIMARY_COLOR }}
          noWrap={true}
        >
          House No
        </Typography>
      </Grid>
      <Grid item xs={4} className={classes.fieldGridValue}>
        <Typography variant="span" className={classes.fieldValue} noWrap={true}>
          {itemAd.houseno}
        </Typography>
      </Grid>
      <Grid item xs={4} className={classes.fieldGridLabel}>
        <Typography
          variant="span"
          className={classes.fieldLabel}
          style={{ color: constants.PRIMARY_COLOR }}
          noWrap={true}
        >
          Street
        </Typography>
      </Grid>
      <Grid item xs={4} className={classes.fieldGridValue}>
        <Typography variant="span" className={classes.fieldValue} noWrap={true}>
          {itemAd.street}
        </Typography>
      </Grid>
      <Grid item xs={4} className={classes.fieldGridLabel}>
        <Typography
          variant="span"
          className={classes.fieldLabel}
          style={{ color: constants.PRIMARY_COLOR }}
          noWrap={true}
        >
          Town/Village
        </Typography>
      </Grid>
      <Grid item xs={4} className={classes.fieldGridValue}>
        <Typography variant="span" className={classes.fieldValue} noWrap={true}>
          {itemAd.city}
        </Typography>
      </Grid>
      <>
      <Grid item xs={4} className={classes.fieldGridLabel}>
        <Typography
          variant="span"
          className={classes.fieldLabel}
          style={{ color: constants.PRIMARY_COLOR }}
          noWrap={true}
        >
          Neighbourhood
        </Typography>
      </Grid>
      <Grid item xs={4} className={classes.fieldGridValue}>
        <Typography variant="span" className={classes.fieldValue} noWrap={true}>
          {itemAd?.neighbourHood || '-'}
        </Typography>
      </Grid>
      <Grid item xs={4} className={classes.fieldGridLabel}>
        <Typography
          variant="span"
          className={classes.fieldLabel}
          style={{ color: constants.PRIMARY_COLOR }}
          noWrap={true}
        >
          District
        </Typography>
      </Grid>
      <Grid item xs={4} className={classes.fieldGridValue}>
        <Typography variant="span" className={classes.fieldValue} noWrap={true}>
          {itemAd.district}
        </Typography>
      </Grid>
      </>
      <Grid item xs={4} className={classes.fieldGridLabel}>
        <Typography
          variant="span"
          className={classes.fieldLabel}
          style={{ color: constants.PRIMARY_COLOR }}
          noWrap={true}
        >
          Pincode
        </Typography>
      </Grid>
      <Grid item xs={4} className={classes.fieldGridValue}>
        <Typography variant="span" className={classes.fieldValue} noWrap={true}>
          {itemAd.pincode}
        </Typography>
      </Grid>
      <Grid item xs={4} className={classes.fieldGridLabel}>
        <Typography
          variant="span"
          className={classes.fieldLabel}
          style={{ color: constants.PRIMARY_COLOR }}
          noWrap={true}
        >
          State
        </Typography>
      </Grid>
      <Grid item xs={4} className={classes.fieldGridValue}>
        <Typography variant="span" className={classes.fieldValue} noWrap={true}>
          {itemAd.state}
        </Typography>
      </Grid>
      <Grid item xs={4} className={classes.fieldGridLabel}>
        <Typography
          variant="span"
          className={classes.fieldLabel}
          style={{ color: constants.PRIMARY_COLOR }}
          noWrap={true}
        >
          Country
        </Typography>
      </Grid>
      <Grid item xs={4} className={classes.fieldGridValue}>
        <Typography variant="span" className={classes.fieldValue} noWrap={true}>
          {itemAd.country}
        </Typography>
      </Grid>
      {itemAd.postedBy !== userDetail?._id && <><Grid item xs={4} className={classes.fieldGridLabel}>
        <Typography
          variant="span"
          className={classes.fieldLabel}
          style={{ color: constants.PRIMARY_COLOR }}
          noWrap={true}
        >
          Phone
        </Typography>
      </Grid>
      <Grid item xs={4} className={classes.fieldGridValue}>
        <Typography variant="span" className={classes.fieldValue} noWrap={true}>
          {userDetail?.phone || '-'}
        </Typography>
      </Grid></>}
    </Grid>
  );
};

export default AdDetails;
