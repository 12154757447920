import { IconButton, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import React from 'react';

const ShowPasswordComponent = (props) => {
    const {showPassword, changeFunction} = props
    const changeVisibility = () => {

    }
    return (
        <InputAdornment position="end">
            <IconButton
                aria-label="toggle password visibility"
                onClick={() => changeFunction(!showPassword)}
                edge="end"
            >
                {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
        </InputAdornment>
    )
}
export default ShowPasswordComponent