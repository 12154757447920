import { Typography } from '@material-ui/core';
import moment from 'moment';
import React, { useState, useRef, useEffect } from 'react'

const TimerComponent = ({ bid }) => {
    const Ref = useRef(null);
    // The state for our timer
    const [timer, setTimer] = useState('00:00:00');
    // console.log(bid.finalCallDateTime, 'props', moment.utc(new Date()).toISOString())

    const getTimeRemaining = () => {
        const startTime = moment(new Date());
        const endTime = moment(bid.finalCallDateTime);
        const duration = moment.duration(endTime.diff(startTime));
        const days = parseInt(duration.asDays());
        const hours = parseInt(duration.asHours());
        const minutes = parseInt(duration.asMinutes()) % 60;
        const seconds = parseInt(duration.asSeconds()) - hours * 3600 - minutes * 60;
        const total = parseInt(duration.asSeconds())
        return {
            total, days, hours, minutes, seconds
        };
    }
    const startTimer = () => {
        let { total, hours, minutes, seconds, days }
            = getTimeRemaining();
        if (total >= 0) {

            // update the timer
            // check if less than 10 then we need to 
            // add '0' at the beginning of the variable
            setTimer(
                (days > 9 ? hours : '0' + days) + ' days ' +
                (hours > 9 ? hours : '0' + hours) + ' hours ' +
                (minutes > 9 ? minutes : '0' + minutes) + ' min '
                + (seconds > 9 ? seconds : '0' + seconds) + ' seconds'
            )
        }
    }


    const clearTimer = (e) => {

        // If you adjust it you should also need to
        // adjust the Endtime formula we are about
        // to code next    
        setTimer('00:00:10');

        // If you try to remove this line the 
        // updating of timer Variable will be
        // after 1000ms or 1sec
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000)
        Ref.current = id;
    }

    const getDeadTime = () => {
        let deadline = new Date();

        // This is where you need to adjust if 
        // you entend to add more time
        deadline.setSeconds(deadline.getSeconds() + 10);
        return deadline;
    }

    // We can use useEffect so that when the component
    // mount the timer will start as soon as possible

    // We put empty array to act as componentDid
    // mount only
    useEffect(() => {
        if (bid && bid.finalCallDateTime) {
            const startTime = moment(new Date());
            const endTime = moment(bid.finalCallDateTime).utc();
            const duration = moment.duration(endTime.diff(startTime));
            const days = parseInt(duration.asDays());
            const hours = parseInt(duration.asHours());
            const minutes = parseInt(duration.asMinutes()) % 60;
            const seconds = parseInt(duration.asSeconds()) - hours * 3600 - minutes * 60;

            const total = parseInt(duration.asSeconds())
            console.log(total, hours, minutes, seconds, 'time')
            // If you try to remove this line the 
            // updating of timer Variable will be
            // after 1000ms or 1sec
            if (total > 0) {
                setTimer(
                    (days > 9 ? hours : '0' + days) + ' days ' +
                    (hours > 9 ? hours : '0' + hours) + ' hours ' +
                    (minutes > 9 ? minutes : '0' + minutes) + ' min '
                    + (seconds > 9 ? seconds : '0' + seconds) + ' seconds'
                )
                if (Ref.current) clearInterval(Ref.current);
                const id = setInterval(() => {
                    startTimer(total, hours, minutes, seconds);
                }, 1000)
                Ref.current = id;
            } else {
                setTimer('00:00:00')
            }
        }
    }, []);
    const onClickReset = () => {
        clearTimer(getDeadTime());
    }
    return (
        <div>
            <Typography variant="h6">Bid Closes in</Typography>
            <Typography variant="h6">{timer}</Typography>
        </div>
    )
}
export default TimerComponent