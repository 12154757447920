export const openCheckout = (user, amount, order_id, cart_id, callback) => {
  console.log(
    "openCheckout",
    user,
    "cart_id",
    cart_id,
    "order_id",
    order_id,
    "amount",
    amount
  );
  let options = {
    key: "rzp_test_DezQO1BVMXhkZY",
    amount: amount * 100,
    name: "CB WebAPP",
    description: `CB WebAPP Subscription Payment`,
    order_id,
    handler: function (response) {
      callback(response, cart_id);
    },
    prefill: {
      name: user.name,
      email: user.email,
      contact: user.phone,
    },
    notes: {
      address: `${user.houseno},${user.street},${user.city},${user.state},${user.country},${user.pincode}.`,
    },
  };

  let rzp = new window.Razorpay(options);
  rzp.open();
};
