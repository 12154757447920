const initialState = {
  itemAdsByCategory: [],
  fetchingAds: false,
  currentAd: null,
  fetchingCurrentAd: false,
  adGalleries: null,
  fetchingAdGalleries: false,
  isFromSearch: false
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ITEM_ADS_BY_CATEGORY": {
      return { ...state, fetchingAds: true };
    }
    case "GET_ITEM_ADS_BY_CATEGORY_SUCCESS": {
      return {
        ...state,
        itemAdsByCategory: action.payload.page===1 ? action.payload.data : [...state.itemAdsByCategory, ...action.payload.data],
        fetchingAds: false,
        isFromSearch: action.payload.isFromSearch
      };
    }
    case "GET_ITEM_ADS_BY_CATEGORY_FAILED": {
      return { ...state, fetchingAds: false };
    }
    case "GET_AD_BY_ID": {
      return { ...state, fetchingCurrentAd: true };
    }
    case "GET_AD_BY_ID_SUCCESS": {
      return {
        ...state,
        currentAd: action.payload,
        fetchingCurrentAd: false,
      };
    }
    case "GET_AD_BY_ID_FAILED": {
      return { ...state, fetchingCurrentAd: false };
    }
    case "GET_AD_GALLERIES_BY_ID": {
      return { ...state, fetchingAdGalleries: true };
    }
    case "GET_AD_GALLERIES_BY_ID_SUCCESS": {
      return {
        ...state,
        adGalleries: action.payload,
        fetchingAdGalleries: false,
      };
    }
    case "GET_AD_GALLERIES_BY_ID_FAILED": {
      return { ...state, fetchingAdGalleries: false };
    }
    case "CLEAR_ITEM_ADS": {
      return { ...state, itemAdsByCategory: [] };
    }
    default:
      return state;
  }
};

export default usersReducer;
