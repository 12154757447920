const initialState = {
  logged_in: false,
  jwt: null,
  userDetail: null,
  edit: false,
  snackBar: null,
  dialog: false,
  dialogText: "",
  avatarModal: false,
  imageSrc: "",
  fetchingCategories: false,
  categories: [],
  subCategories: [],
  manufacturerMake: [],
  manufacturerModel: [],
  manuractureringYear: [],
  fetchingSubCategories: false
};

const appReducer = (
  state = {
    logged_in: false,
    jwt: null,
    userDetail: null,
    edit: false,
    snackBar: null,
    dialog: false,
    dialogText: "",
    avatarModal: false,
    imageSrc: "",
    fetchingCategories: false,
    categories: [],
    subCategories: [],
    fetchingSubCategories: false
  },
  action
) => {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return {
        ...state,
        logged_in: true,
        jwt: action.jwt,
        userDetail: action.userDetail,
      };
    case "GET_CATEGORIES": {
      return { ...state, fetchingCategories: true };
    }
    case "GET_CATEGORIES_SUCCESS": {
      return {
        ...state,
        categories: action.payload,
        fetchingCategories: false,
      };
    }
    case "GET_CATEGORIES_FAILED": {
      return { ...state, fetchingCategories: false };
    }
    case "EDIT_PROFILE":
      return {
        ...state,
        edit: !state.edit,
      };
    case "OPEN_DIALOG":
      return {
        ...state,
        dialog: true,
        dialogText: action.payload.text,
        dialogDescription: action.payload.description,
      };
    case "CLOSE_DIALOG":
      return {
        ...state,
        dialog: false,
        dialogText: "",
        dialogDescription: "",
      };
    case "OPEN_AVATAR_MODAL":
      return {
        ...state,
        avatarModal: true,
        imageSrc: action.payload.imageSrc,
      };
    case "CLOSE_AVATAR_MODAL":
      return {
        ...state,
        avatarModal: false,
        imageSrc: "",
      };
    case "GET_SUB_CATEGORIES_SUCCESS": {
      return {
        ...state,
        subCategories: action.payload,
        fetchingSubCategories: false,
      };
    }
    case "GET_SUB_CATEGORIES_FAILED": {
      return { ...state, fetchingSubCategories: false };
    }
    case "GET_MANUFACTURER_MAKE_SUCCESS": {
      return {
        ...state,
        manufacturerMake: action.payload
      };
    }
    case "GET_MANUFACTURER_MAKE_FAILED": {
      return { ...state };
    }
    case "GET_MANUFACTURER_MODEL_SUCCESS": {
      return {
        ...state,
        manufacturerModel: action.payload
      };
    }
    case "GET_MANUFACTURER_MODEL_FAILED": {
      return { ...state };
    }
    case "GET_MANUFACTURER_YEAR_SUCCESS": {
      return {
        ...state,
        manuractureringYear: action.payload
      };
    }
    case "GET_MANUFACTURER_YEAR_FAILED": {
      return { ...state };
    }
    default:
      return state;
  }
};

export default appReducer;
