import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";

import { Button, Grid, Typography } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import PaymentDetails from "../components/PaymentDetails/index";
import PurchaseDetails from "../components/PaymentDetails/PurchaseDetails";
import { openCheckout } from "../components/PaymentDetails/Checkout";
import { Loader } from "../components/UtilityComponents";
import {
  getUserSubscription,
  userChooseSubscriptionPlan,
  addSubscriptionPlan,
  getRazorPaySuccessCapture,
} from "../actions/userDetailsAction";
import { isNull } from "util";
import { constants } from "../helpers/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: "wrap",
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  paymentButton: {
    margin: "24px 0px",
  },
  paymentDetailsRow: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginBottom: "8px",
  },
  divider: {
    marginBottom: "8px",
  },
  paymentDetials: {
    width: "100%",
    maxWidth: "80%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  warningMessage: {
    display: "flex",
    alignItems: "center",
  },
  warningTyphography: {
    color: "white",
  },
  warningIcon: {
    marginLeft: "6px",

    fontSize: "20px",
  },
  feature: {
    listStyleType: "squre",
  },
}));

// const rezorPayData = {
//   error: false,
//   errors: [],
//   data: {
//     status: "unprocessed",
//     taxAmount: 0.18,
//     roundOffAmount: 0.18,
//     totalPrice: 1,
//     refundDetails: null,
//     _id: "5e5ca04c9c40be0bc8391d78",
//     taxCGSTAmount: 0.54,
//     taxSGSTAmount: 0.54,
//     taxCGSTPercent: 18,
//     taxSGSTPercent: 18,
//     totalPriceAfterTax: 4,
//     items: [
//       {
//         _id: "5e8340a0c2f5ed21882ab1d8",
//         name: "SocialMedia",
//         price: 1,
//         subscriptionId: "5de66782f38383417f12cada"
//       }
//     ],
//     userId: "5e2ac8132eafcd049e2a291c",
//     createdAt: "2020-03-02T05:57:32.605Z",
//     updatedAt: "2020-03-31T13:07:44.922Z",
//     __v: 18,
//     razorpay_order_id: "order_EYpvuPu80Na94k"
//   }
// };

function SubscriptionComponent(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [purchaseOpen, setPurchaseOpen] = useState(false);
  const userDetail = useSelector(
    ({ userDetailsReducer }) => userDetailsReducer.userDetail
  );
  const currentDate = moment();

  const addSubscriptionPlanLoading = useSelector(
    ({ userDetailsReducer }) => userDetailsReducer.addSubscriptionPlanLoading
  );

  const userSubscriptionList = useSelector(
    ({ userDetailsReducer }) => userDetailsReducer.userSubscriptionList
  );

  const chooseSubscriptionPlanList = useSelector(
    ({ userDetailsReducer }) => userDetailsReducer.chooseSubscriptionPlanList
  );

  const userSubscriptionListLoading = useSelector(
    ({ userDetailsReducer }) => userDetailsReducer.userSubscriptionListLoading
  );

  useEffect(() => {
    dispatch(getUserSubscription("active"));
  }, [userSubscriptionList && userSubscriptionList.length]);

  useEffect(() => {
    userSubscriptionList &&
      userSubscriptionList.length <= 0 &&
      userDetail &&
      dispatch(userChooseSubscriptionPlan());
  }, [chooseSubscriptionPlanList && chooseSubscriptionPlanList.length]);

  useEffect(() => {
    dispatch(userChooseSubscriptionPlan());
  }, []);

  function handleCallbackRazorPayCapture() {
    dispatch(getUserSubscription("active"));
  }
  function handleCallbackRazorPaySuccess(res, cart) {
    setPurchaseOpen(false);
    const body = {
      razorpay_order_id: res.razorpay_order_id,
      razorpay_payment_id: res.razorpay_payment_id,
      razorpay_signature: res.razorpay_signature,
      cart_id: cart,
    };
    dispatch(getRazorPaySuccessCapture(body, handleCallbackRazorPayCapture));
  }

  function handleCallRazorPayMethod(rezorPayData) {
    setPurchaseOpen(false);
    openCheckout(
      userDetail,
      rezorPayData.totalPriceAfterTax,
      rezorPayData.razorpay_order_id,
      rezorPayData._id,
      handleCallbackRazorPaySuccess
    );
  }

  function handleMakePayment() {
    setPurchaseOpen(false);
    const choosablePlanId =
      chooseSubscriptionPlanList && chooseSubscriptionPlanList[0]._id;
    dispatch(addSubscriptionPlan(choosablePlanId, handleCallRazorPayMethod));
  }

  return (
    <div className={classes.root}>
      <Container maxWidth="sm" component="main" className={classes.heroContent}>
        <div>
          {userSubscriptionList.length <= 0 && !userSubscriptionListLoading && (
            <div
              style={{
                backgroundColor: "#3eb0ff",
                borderRadius: "4px",
                width: "100%",
                padding: "16px",
              }}
            >
              <Typography style={{ color: "white" }}>
                Please subscribe to bid and sell.
              </Typography>
            </div>
          )}
        </div>
      </Container>
      {userSubscriptionList.length > 0 ? (
        <div className={classes.paymentDetials}>
          <Typography
            component="h1"
            variant="h6"
            color="textPrimary"
            gutterBottom
          >
            Current Subscription Details
          </Typography>
          <Divider className={classes.divider} />
          <PaymentDetails
            payment={userSubscriptionList[userSubscriptionList.length - 1]}
          />
        </div>
      ) : null}
      {!userSubscriptionListLoading && userSubscriptionList.length <= 0 ? (
        <div className={classes.paymentButton}>
          {addSubscriptionPlanLoading ? (
            <Button
              variant="contained"
              color="primary"
              style={{ color: "white", opacity: "0.6", textTransform: "none" }}
            >
              Making payment...
            </Button>
          ) : (
            <Grid container>
              <Grid item xs={12} align="center">
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    color: "white",
                    margin: "10px",
                    textTransform: "none",
                  }}
                  onClick={() => setPurchaseOpen(true)}
                >
                  Make payment
                </Button>
                {purchaseOpen && (
                  <PurchaseDetails
                    open={purchaseOpen}
                    purchaseType="purchase"
                    handleClose={() => setPurchaseOpen(false)}
                    handleMakePayment={handleMakePayment}
                    subscriptionId={
                      chooseSubscriptionPlanList &&
                      chooseSubscriptionPlanList[0]._id
                    }
                  />
                )}
              </Grid>
            </Grid>
          )}
        </div>
      ) : null}
      {!userSubscriptionListLoading &&
      userSubscriptionList.length > 0 &&
      userDetail ? (
        <div className={classes.paymentButton}>
          {addSubscriptionPlanLoading ? (
            <Button
              variant="contained"
              color="primary"
              style={{
                color: "white",
                opacity: "0.6",
                textTransform: "none",
              }}
            >
              Making payment...
            </Button>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}

export default SubscriptionComponent;
