import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import withRouter from "react-router-dom/es/withRouter";
import bindActionCreators from "redux/es/bindActionCreators";
import connect from "react-redux/es/connect/connect";

import routes from "../Routes/page-routes.jsx";

//material-ui imports
import { Grid } from "@material-ui/core";
import UserProfile from "./UserProfile";
import ProfileAboutComponent from "./ProfileAboutComponent";
import Bid from "./Bid/Bid";
import ItemAdsContainer from "./ItemAds/ItemAdsContainer";
import BuyShareComponent from "./BuyShareComponent/Bid";
import ItemAd from "./ItemAds/ItemAd";
import EditProfileForm from "./EditProfile/EditProfileForm";
import PostAdComponent from "./CreateItemAd";
import SettingsComponent from "./SettingsComponents/SettingsComponent";
import UpdateAdComponent from "./EditItemAd";
import PostAdMediaComponent from "./CreateItemAd/PostAdMediaComponent";
import TransactionsContainer from "./TransactionsComponent/TransactionsContainer";
import ProfileSubscriptions from "./ProfileSubscriptions";
import { getUserSubscription } from "../actions/userDetailsAction";
import AdSpaceContainer from "./AdSpaceComponent/AdSpaceContainer";
import AdBid from "../components/AdBid/Bid";
import AdBidCalendar from "../components/AdBid/CalendarComponent/Calendar";
import { getStringParam } from "../helpers/paramVerifier";
import { constants } from "../helpers/constants";
import ContactUs from "../containers/ContactUs.js";
import SignupForm from "../containers/SignupForm.jsx";
import LoginForm from "../containers/LoginForm.js";
import ForgetPasswordComponent from "../containers/ForgetPasswordComponent.js";
import ResetPassword from "../containers/ResetPassword.js";
import VerifyEmail from "../containers/verifyEmail.js";
import EmailVerifyMessage from "../containers/EmailVerifyMessage.js";
import OtpPhoneVerify from "../containers/OtpPhoneVerify.js";
import OtpPhone from "../containers/otp";
import TermsAndConditions from "../containers/TermsAndConditions.js";
import PrivacyPolicy from "../containers/PrivacyPolicy.js";

const MainContent = ({
  userDetail,
  dispatch,
  userSubscriptionList,
  userSubscriptionListLoading,
  history
}) => {
  const pageLoadRedirect = () => {
    return <Redirect to={"/ads"} />;
  };

  useEffect(() => {
    if (localStorage.getItem('jwt')) {
      dispatch(getUserSubscription("active"));
    }

  }, [dispatch]);

  if (!userDetail && userSubscriptionListLoading) {
    return null;
  }
  console.log(userDetail ? userDetail?.mobileValidated ? "ItemAdsContainer" :  "OtpPhoneVerify" :"ItemAdsContainer", userDetail?.mobileValidated)
  return userDetail && !userDetail?.mobileValidated ? (<OtpPhoneVerify />) : (
    <Grid container>
      <Switch>
        {
          userDetail ? userDetail?.mobileValidated ? <Route path={"/"} exact component={ItemAdsContainer} /> :  <Route path={"/"} exact component={OtpPhoneVerify}  /> : <Route path={"/"} exact component={ItemAdsContainer} />
        }
        {/* { userDetail && userDetail?.mobileValidated ? <Route path={"/"} exact component={ItemAdsContainer} /> : <Route path={"/"} exact component={OtpPhoneVerify}  />} */}
        {/* <Route path={"/"} exact render={() =>
          localStorage.getItem("jwt") && !userDetail?.mobileValidated ? (
            <ItemAdsContainer  />
          ) : (
            <Redirect to={"/otp-phone-verify"} />
          )
        } /> */}
        <Route exact path={`/ads`} render={() =>
          localStorage.getItem("jwt") && userDetail?.mobileValidated ? (
            <ItemAdsContainer  />
          ) : (
            <Redirect to={"/"} />
          )
        } />
        <Route path="/contactus" exact render={() => <ContactUs />} />
        <Route
          path="/register"
          exact
          render={() =>
            localStorage.getItem("jwt") ? (
              <Redirect to={"/"} />
            ) : (
              <SignupForm />
            )
          }
        />
        <Route
          path="/login"
          exact
          render={() => {
            let path = '/'
            if (history?.location?.search?.includes("redirect")) {
              path = history.location.search.split("=")[1] || '/'
            }
            return localStorage.getItem("jwt") ? <Redirect to={path} /> : <LoginForm />
          }
          }
        />
        <Route
          path="/forgot-password"
          exact
          render={() =>
            localStorage.getItem("jwt") ? (
              <Redirect to={"/"} />
            ) : (
              <ForgetPasswordComponent />
            )
          }
        />

        <Route
          path="/api/auth/resetPassword/:q"
          exact
          render={() =>
            localStorage.getItem("jwt") ? (
              <Redirect to={"/"} />
            ) : (
              <ResetPassword />
            )
          }
        />

        <Route
          path="/email-verify/:q"
          exact
          render={() =>
            localStorage.getItem("jwt") ? (
              <Redirect to={"/"} />
            ) : (
              <VerifyEmail />
            )
          }
        />
        <Route
          path="/email-verify-message"
          exact
          render={() =>
            localStorage.getItem("jwt") ? (
              <Redirect to={"/"} />
            ) : (
              <EmailVerifyMessage />
            )
          }
        />
        <Route
          path="/otp-phone-verify"
          exact
          render={() =>
            localStorage.getItem("jwt") ? <Redirect to={"/"} /> : <OtpPhoneVerify />
          }
        />

        <Route
          path="/otp-phone"
          exact
          render={() =>
            localStorage.getItem("jwt") ? <Redirect to={"/"} /> : <OtpPhone />
          }
        />

        <Route
          exact
          path={`/ads/:ad_id`}
          render={() =><ItemAd  />
            // localStorage.getItem("jwt") ? (
            //   userDetail?.mobileValidated ? <ItemAd  /> : <Redirect to={"/"} />
            // ) : (
            //   <ItemAd  />
            // )
          }
        />
        <Route
          exact
          path={"/tc"}
          render={(props) => <TermsAndConditions {...props} />}
        />
        <Route
          exact
          path={"/privacypolicy"}
          render={(props) => <PrivacyPolicy {...props} />}
        />

        {
          localStorage.getItem("jwt") ? <>
            <Route
              exact
              path={`/myAds`}
              render={() =>
                localStorage.getItem("jwt") && userDetail?.mobileValidated ? (
                  <ItemAdsContainer  />
                ) : (
                  <Redirect to={"/"} />
                )
              }
            />

            <Route
              exact
              path={`/ads/:ad_id`}
              render={() =>
                localStorage.getItem("jwt") && userDetail?.mobileValidated ? (
                  <ItemAd  />
                ) : (
                  <Redirect to={"/"} />
                )
              }
            />

            <Route
              exact
              path={`/postAd`}
              render={() =>
                localStorage.getItem("jwt") ? (
                  <PostAdComponent  />
                ) : (
                  <Redirect to={"/"} />
                )
              }
            />
            <Route
              exact
              path={`/editAd/:ad_id`}
              render={() =>
                localStorage.getItem("jwt") ? (
                  <UpdateAdComponent  />
                ) : (
                  <Redirect to={"/"} />
                )
              }
            />
            <Route
              exact
              path={`/postAd/media/:ad_id`}
              component={
                // userSubscriptionList.length
                //   ? PostAdMediaComponent
                //   : ProfileSubscriptions
                PostAdMediaComponent
              }
              
            />
            <Route
              exact
              path={`/bid/:id`}
              component={userSubscriptionList.length ? Bid : ProfileSubscriptions}
            />
            <Route exact path={"/settings"} render={() =>
            localStorage.getItem("jwt") ? (
              <SettingsComponent  />
            ) : (
              <Redirect to={"/"} />
            )
          }  />
            <Route exact path={"/buyShare"} render={() =>
            localStorage.getItem("jwt")  ? (
              <BuyShareComponent  />
            ) : (
              <Redirect to={"/"} />
            )
          }  />
            <Route exact path={"/subscription"} render={() =>
            localStorage.getItem("jwt")  ? (
              <ProfileSubscriptions  />
            ) : (
              <Redirect to={"/"} />
            )
          }  />
            <Route exact path={"/transactions"} render={() =>
            localStorage.getItem("jwt")  ? (
              <TransactionsContainer  />
            ) : (
              <Redirect to={"/"} />
            )
          }  />
            <Route exact path={"/adbid/:id"} render={() =>
            localStorage.getItem("jwt") && userDetail?.mobileValidated ? (
              <AdBid  />
            ) : (
              <Redirect to={"/"} />
            )
          }  />
            <Route exact path={"/bid-calendar"} render={() =>
            localStorage.getItem("jwt") && userDetail?.mobileValidated ? (
              <AdBidCalendar  />
            ) : (
              <Redirect to={"/"} />
            )
          }  />
            <Route exact path={"/buyAdSpace"} render={() =>
            localStorage.getItem("jwt") && userDetail?.mobileValidated ? (
              <AdSpaceContainer  />
            ) : (
              <Redirect to={"/"} />
            )
          }  />
            <Route
              exact
              path={"/editprofile"}
              // render={(props) => <EditProfileForm {...props} />}
              render={() =>
                localStorage.getItem("jwt") ? (
                  <EditProfileForm  />
                ) : (
                  <Redirect to={"/"} />
                )
              }
            />
            <Route
              path={"/profile-me"}
              // render={(props) => <ProfileAboutComponent {...props} />}
              render={(props) =>
                localStorage.getItem("jwt") ? (
                  <ProfileAboutComponent {...props} />
                ) : (
                  <Redirect to={"/"} />
                )
              }
            />
            <Route
              path={"/user_profile/:user_id"}
              // render={(props) => <UserProfile {...props} />}
              render={(props) =>
                localStorage.getItem("jwt") && userDetail?.mobileValidated ? (
                  <UserProfile {...props} />
                ) : (
                  <Redirect to={"/"} />
                )
              }
            />
            <Route
              exact
              path={"/:user_name"}
              // render={(props) => <UserProfile {...props} />}
              render={() =>
                localStorage.getItem("jwt") && userDetail?.mobileValidated ? (
                  <UserProfile  />
                ) : (
                  <Redirect to={"/"} />
                )
              }
            />
          </> : <><Redirect to={"/"} /></>
        }
        {/* <Route exact path={`/`} render={()=>(<>dkjfghfgh<br/><br/><br/><br/><br/><br/><br/>skghkjsghskjg</>)} /> */}
        {/* <Route exact path={`/`} component={ItemAdsContainer} /> */}

        {/* <Route
          exact
          path={`/myAds`}
          component={
            // userSubscriptionList.length  ?
            ItemAdsContainer
            //  : ProfileSubscriptions
          }
        />
        <Route
          exact
          path={`/ads/:ad_id`}
          component={
            // userSubscriptionList.length ?
            ItemAd
            //  :            ProfileSubscriptions
          }
        />
        <Route
          exact
          path={`/postAd`}
          component={
            // userSubscriptionList.length ? PostAdComponent : ProfileSubscriptions
            PostAdComponent
          }
        />
        <Route
          exact
          path={`/editAd/:ad_id`}
          component={
            // userSubscriptionList.length
            //   ? UpdateAdComponent
            //   : ProfileSubscriptions


            UpdateAdComponent
          }
        />
        <Route
          exact
          path={`/postAd/media/:ad_id`}
          component={
            // userSubscriptionList.length
            //   ? PostAdMediaComponent
            //   : ProfileSubscriptions
            PostAdMediaComponent
          }
        />
        <Route
          exact
          path={`/bid/:id`}
          component={userSubscriptionList.length ? Bid : ProfileSubscriptions}
        />
        <Route exact path={"/settings"} component={SettingsComponent} />
        <Route exact path={"/buyShare"} component={BuyShareComponent} />
        <Route exact path={"/subscription"} component={ProfileSubscriptions} />
        <Route exact path={"/transactions"} component={TransactionsContainer} />
        <Route exact path={"/adbid/:id"} component={AdBid} />
        <Route exact path={"/bid-calendar"} component={AdBidCalendar} />
        <Route exact path={"/buyAdSpace"} component={AdSpaceContainer} />
        <Route
          exact
          path={"/editprofile"}
          render={(props) => <EditProfileForm {...props} />}
        />
        <Route
          path={"/profile-me"}
          render={(props) => <ProfileAboutComponent {...props} />}
        />
        <Route
          path={"/user_profile/:user_id"}
          render={(props) => <UserProfile {...props} />}
        />
        <Route
          exact
          path={"/:user_name"}
          render={(props) => <UserProfile {...props} />}
        /> */}
        {/* {accessPages.length > 0 &&
          routes
            .filter((route) => {
              var thisPage = accessPages.filter((page) => {
                if (route.path === page.pageName) {
                  return page;
                }
                return false;
              });
              if (thisPage && thisPage[0]) {
                return true;
              }
              return false;
            })
            .map((route, index) => {
              return (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  render={route.render}
                />
              );
            })} */}

        <Route render={() => <h1>Page not found</h1>} />
      </Switch>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    userDetail: state.userDetailsReducer.userDetail,
    accessPages: state.userDetailsReducer.accessPages,
    userSubscriptionList: state.userDetailsReducer.userSubscriptionList,
    userSubscriptionListLoading:
      state.userDetailsReducer.userSubscriptionListLoading,
  };
};

export default withRouter(connect(mapStateToProps)(MainContent));

/*********** */

//  <Route path={'/'} component = {PostAdTableComponent}>
//  <IndexRoute component = {ProfileAboutComponent} />
//  <Route path={'profile'} component={ProfileAboutComponent}/>
//  <Route path = {"positions"} component = {PostAdTableComponent}>
//      <Route path={'/:ad_id?'} component={PostPosTable}/>
//  </Route>
// </Route>this.props.userDetail.userType
