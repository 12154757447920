import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import {
  Button,
  Grid,
  Select,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import AdSpacePaymentDetails from "../PaymentDetails/AdSpacePaymentDetails";
import PurchaseDetails from "./PurchaseDetails";
import WarningIcon from "@material-ui/icons/Warning";
import AcUnitIcon from "@material-ui/icons/AcUnit";
import { openCheckout } from "../PaymentDetails/Checkout";
import { Loader } from "../UtilityComponents";
import {
  addAdSpace,
  getUserAdSpace,
  getAdSpaceDetails,
  getRazorPaySuccessCapture,
} from "../../actions/adSpaceActions";
import { requestCountries } from "../../actions/registerActions";
import { constants } from "../../helpers/constants";
import UploadDialog from "./UploadDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: "wrap",
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  paymentButton: {
    margin: "24px",
  },
  paymentDetailsRow: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginBottom: "8px",
  },
  divider: {
    marginBottom: "8px",
  },
  paymentDetials: {
    width: "100%",
    textAlign: "center",
  },
  warningMessage: {
    display: "flex",
    alignItems: "center",
  },
  warningTyphography: {
    color: "white",
  },
  warningIcon: {
    marginLeft: "6px",

    fontSize: "20px",
  },
  feature: {
    listStyleType: "squre",
  },
}));

const AdSpaceContainer = ({ history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openUpload, setOpenUpload] = useState(false);
  const [adspace, setAdspace] = useState("Select");
  const [cycle, setCycle] = useState("yearly");
  // const [country, setCountry] = useState("Worldwide");
  const [purchaseOpen, setPurchaseOpen] = useState(false);

  const userDetail = useSelector(
    ({ userDetailsReducer }) => userDetailsReducer.userDetail
  );
  const addAdSpacetoCartLoading = useSelector(
    ({ adSpaceReducer }) => adSpaceReducer.addAdSpacetoCartLoading
  );
  const adSpaceDetails = useSelector(
    ({ adSpaceReducer }) => adSpaceReducer.adSpaceDetails
  );
  const userAdSpaceList = useSelector(
    ({ adSpaceReducer }) => adSpaceReducer.userAdSpaceList
  );
  const userAdSpaceListLoading = useSelector(
    ({ adSpaceReducer }) => adSpaceReducer.userAdSpaceListLoading
  );

  useEffect(() => {
    // dispatch(requestCountries());
    dispatch(getAdSpaceDetails({ cycle, name: adspace }));
    dispatch(getUserAdSpace("active"));
  }, []);

  useEffect(() => {
    dispatch(getAdSpaceDetails({ cycle, name: adspace }));
  }, [cycle, adspace]);

  const handleCallbackRazorPayCapture = () => {
    dispatch(getUserAdSpace("active"));
  };

  const handleCallbackRazorPaySuccess = (res, advtId) => {
    setPurchaseOpen(false);
    const body = {
      razorpay_order_id: res.razorpay_order_id,
      razorpay_payment_id: res.razorpay_payment_id,
      razorpay_signature: res.razorpay_signature,
      advtId: advtId,
    };
    dispatch(getRazorPaySuccessCapture(body, handleCallbackRazorPayCapture));
  };

  const handleCallRazorPayMethod = (razorPayData) => {
    setPurchaseOpen(false);
    openCheckout(
      userDetail,
      razorPayData.totalPriceAfterTax,
      razorPayData.razorpay_order_id,
      razorPayData._id,
      handleCallbackRazorPaySuccess
    );
  };

  const handleMakePayment = () => {
    setPurchaseOpen(false);
    dispatch(
      addAdSpace(
        adSpaceDetails && adSpaceDetails[0] && adSpaceDetails[0]._id,
        handleCallRazorPayMethod
      )
    );
  };

  // useEffect(() => {
  //   if (adspace === "authPages" || adspace === "sidePanels") {
  //     history.push("/bid-calendar");
  //   }
  // }, [adspace]);

  return (
    <div className={classes.root}>
      <div className={classes.paymentButton}>
        <Typography
          component="h1"
          variant="h4"
          align="center"
          color="textPrimary"
        >
          Buy AdSpace!
        </Typography>
        {!userAdSpaceListLoading &&
        userAdSpaceList &&
        userAdSpaceList.length > 0 ? (
          <div className={classes.paymentDetials}>
            <Typography
              component="h1"
              variant="h6"
              color="textPrimary"
              gutterBottom
            >
              Current AdSpace Details
            </Typography>
            <Divider className={classes.divider} />
            <AdSpacePaymentDetails
              payment={
                userAdSpaceList &&
                userAdSpaceList.length > 0 &&
                userAdSpaceList[userAdSpaceList.length - 1]
              }
            />
            {/* <Button
                variant="contained"
                color="primary"
                style={{
                  color: "white",
                  textTransform: "none",
                  margin: 12,
                }}
                onClick={() => setOpenUpload(true)}
              >
                Ad Media
            </Button> */}
            {/* {openUpload && ( */}
            <UploadDialog
              // open={openUpload}
              companyId={userDetail._id}
              // handleClose={() => setOpenUpload(false)}
              // handleClose={() => handleCloseUpload()}
            />
            {/* )} */}
          </div>
        ) : addAdSpacetoCartLoading ? (
          <Button
            variant="contained"
            color="primary"
            style={{ color: "white", opacity: "0.6", textTransform: "none" }}
          >
            Making payment...
          </Button>
        ) : (
          <Grid container style={{ margin: 32 }}>
            <Grid item xs={6}>
              <Typography
                align="center"
                variant="h6"
                style={{ margin: "10px", color: constants.PRIMARY_COLOR }}
              >
                Choose AdSpace
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Select
                value={adspace}
                onChange={(e) => setAdspace(e.target.value)}
              >
                <MenuItem value={"Select"}>Select</MenuItem>
                <MenuItem value={"authPages"}>Login/Register Page</MenuItem>
                <MenuItem value={"sidePanels"}>Single Side Panel</MenuItem>
                <MenuItem value={"inStream"}>In-Stream</MenuItem>
                <MenuItem value={"betweenPosts"}>Between Posts</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={6}>
              <Typography
                align="center"
                variant="h6"
                style={{ margin: "10px", color: constants.PRIMARY_COLOR }}
              >
                Cycle
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Select value={cycle} onChange={(e) => setCycle(e.target.value)}>
                <MenuItem value={"yearly"}>Yearly</MenuItem>
                <MenuItem value={"halfyearly"}>Halfyearly</MenuItem>
                <MenuItem value={"quarterly"}>Quarterly</MenuItem>
                <MenuItem value={"monthly"}>Monthly</MenuItem>
                <MenuItem value={"quarterly"}>Daily</MenuItem>
              </Select>
            </Grid>
            {/* <Grid item xs={6}>
              <Typography
                align="center"
                variant="h6"
                style={{ margin: "10px", color: constants.PRIMARY_COLOR }}
              >
                Country
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                options={["Worldwide", ...countriesList]}
                getOptionLabel={(option) => option}
                onChange={(e, country) => setCountry(country)}
                value={country}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // label="Select Country"
                    // variant="outlined"
                    fullWidth
                    required
                  />
                )}
              />
            </Grid> */}
            {adspace !== "authPages" &&
              adspace !== "sidePanels" &&
              adspace !== "Select" && (
                <>
                  <Grid item xs={6}>
                    <Typography
                      align="center"
                      variant="h6"
                      style={{ margin: "10px", color: constants.PRIMARY_COLOR }}
                    >
                      Price
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      align="center"
                      variant="h6"
                      style={{ margin: "10px", color: constants.PRIMARY_COLOR }}
                    >
                      {adSpaceDetails && adSpaceDetails[0]
                        ? "Rs. " + adSpaceDetails[0].price
                        : "Unavailable. Please try different cycles and/or countries."}
                    </Typography>
                  </Grid>
                </>
              )}
            {(adspace === "authPages" || adspace === "sidePanels") && (
              <Grid item xs={12} align="center">
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{
                    color: "white",
                    margin: "32px",
                    textTransform: "none",
                  }}
                  onClick={() => history.push("/bid-calendar")}
                >
                  Select Dates
                </Button>
              </Grid>
            )}
            {adSpaceDetails &&
              adSpaceDetails[0] &&
              !(adspace === "authPages" || adspace === "sidePanels") && (
                <Grid item xs={12} align="center">
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    style={{
                      color: "white",
                      margin: "32px",
                      textTransform: "none",
                    }}
                    onClick={() => setPurchaseOpen(true)}
                  >
                    Make payment
                  </Button>
                  {purchaseOpen && (
                    <PurchaseDetails
                      open={purchaseOpen}
                      handleClose={() => setPurchaseOpen(false)}
                      handleMakePayment={handleMakePayment}
                      adSpaceId={
                        adSpaceDetails &&
                        adSpaceDetails[0] &&
                        adSpaceDetails[0]._id
                      }
                    />
                  )}
                </Grid>
              )}
          </Grid>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  // countriesList: state.registerReducer.countries
  //   ? state.registerReducer.countries.map((country) => country.country)
  //   : [],
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AdSpaceContainer));
