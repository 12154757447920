import React, { useState, useEffect, useHistory } from "react";
import { Link } from "react-router-dom";
import { Button, withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { Tabs, Tab } from "@material-ui/core";
import { isEmpty } from "lodash";
import { withRouter } from "react-router-dom";
import {
  Paper,
  Grid,
  Avatar,
  Typography,
  Divider,
  IconButton,
  Icon,
  Badge,
  Chip,
  Popover,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import PhotoSharpIcon from "@material-ui/icons/PhotoSharp";
import PersonSharpIcon from "@material-ui/icons/PersonSharp";
import DynamicFeedSharpIcon from "@material-ui/icons/DynamicFeedSharp";
import EventNoteSharpIcon from "@material-ui/icons/EventNoteSharp";
import FollowingIcon from "@material-ui/icons/ArrowUpward";
import FollowersIcon from "@material-ui/icons/ArrowDownward";
import ProfileLikeIcon from "@material-ui/icons/ThumbUpAltSharp";
import RadioButtonUncheckedSharpIcon from "@material-ui/icons/RadioButtonUncheckedSharp";
import LikeHiddenIcon from "@material-ui/icons/Favorite";
import LikeOpenIcon from "@material-ui/icons/FavoriteBorderSharp";
import FavouriteIcon from "@material-ui/icons/BookmarkBorderSharp";
import MenuIcon from "@material-ui/icons/Menu";
import EventNoteIcon from "@material-ui/icons/EventNote";
import PhotoOutlinedIcon from "@material-ui/icons/PhotoOutlined";
import PhotoCameraOutlinedIcon from "@material-ui/icons/PhotoCameraOutlined";
import VisibilityOffSharpIcon from "@material-ui/icons/VisibilityOffSharp";
import ShareToMeIcon from "@material-ui/icons/ReplySharp";
import PersonAddDisabledIcon from "@material-ui/icons/PersonAddDisabledSharp";
import AddSharpIcon from "@material-ui/icons/AddSharp";
import ArrowBackSharpIcon from "@material-ui/icons/ArrowBackSharp";
import ChatSharpIcon from "@material-ui/icons/ChatSharp";
import DoneSharpIcon from "@material-ui/icons/DoneSharp";
// import ConfirmationDialog from "../ConfirmationDialog";
// import CustomNameDialog from "../CustomNameDialog";
// import PostFeed from "../PostsComponent/PostFeed";
import { openAvatarModal } from "../../actions/appActions";

import {
  getUserProfileDetails,
  getUserProfileDetailsByUsername,
} from "../../actions/userDetailsAction";
import getURL from "../../helpers/apis";
// import UserGalleryComponent from "../UserGalleryComponent";
import ProfileInfo from "../ProfileInfo";
import ReviewContainer from "../ReviewComponent";
// import CalendarComponent from "../CalendarComponent";
// import ReportDialog from "./ReportDialog";
// import PublicShare from "./PublicShare";

const styles = {
  mainContent: {
    marginTop: 10,
  },
  paper: {
    fontSize: 14,
    width: "100%",
    minWidth: 275,
    margin: 10,
    maxWidth: 1200,
  },
  avatar: {
    fontSize: 100,
    margin: "auto",
    width: 120,
    height: 120,
  },
  name: {
    margin: "20px auto 10px",
    fontSize: 20,
    fontWeight: 600,
    textAlign: "center",
  },
  userName: {
    fontSize: 15,
    textAlign: "center",
    margin: 6,
  },
  gender: {
    margin: "10px auto",
    fontSize: 15,
    textAlign: "center",
  },
  detailFields: {
    marginTop: 20,
    // height: "500px",
  },
  profileTitle: {
    textAlign: "center",
    fontSize: 20,
    fontWeight: 600,
    padding: 10,
  },
  firstName: {
    fontSize: 14,
    marginBottom: 10,
  },
  icon: {
    cursor: "pointer",
    fontSize: 30,
    // marginLeft: 15,
    // marginRight: 15,
  },
  columnContainer: {
    padding: 5,
  },
  fieldGridLabel: {
    // width: "30%",
    fontSize: 18,
    margin: 5,
  },
  fieldGridValue: {
    // width: "68%",
    fontSize: 18,
    margin: 5,
  },
  fieldLabel: {
    fontSize: 14,
    // fontWeight: 600,
  },
  fieldValue: {
    fontSize: 14,
  },
  tabPaper: {
    flexGrow: 1,
    borderTop: 0,
    width: "100%",
    // overflowY: "scroll",
    // height: "100vh",
  },
  ReviewPaper: {
    width: "100%",
    marginTop: 25,
  },
  tabIndicator: {
    top: "0px",
    height: "2px",
    backgroundColor: "#3f51b5",
    // backgroundColor: "black",
  },
};

const avatarLetters = (firstString, secondString) =>
  `${firstString[0]}${secondString[0]}`?.toUpperCase();

const rootUrl = getURL("UPLOADS");

const UserProfile = (props) => {
  const {
    dispatch,
    classes,
    match,
    currentUserProfile,
    userDetail,
    mobileNumberStatusDisplay
  } = props
  const [value, setValue] = useState(0);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openCustom, setOpenCustom] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [followType, setFollowType] = useState(null);
  const [isEdit, setIsEdit] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openReport, setOpenReport] = useState(false);
  const [openPublicShare, setOpenPublicShare] = useState(false);
  const [currentAction, setCurrentAction] = useState(null);

  useEffect(() => {
    if(localStorage.getItem('jwt')){
      match.params.user_id
      ? dispatch(getUserProfileDetails(match.params.user_id))
      : dispatch(getUserProfileDetailsByUsername(match.params.user_name));
    }
    
  }, []);

  // useEffect(() => {
  //   if(localStorage.getItem('jwt')){
  //     match.params.user_id
  //     ? dispatch(getUserProfileDetails(match.params.user_id))
  //     : dispatch(getUserProfileDetailsByUsername(match.params.user_name));
  //   }
    
  // }, [match, refresh]);

  if (isEmpty(currentUserProfile)) {
    return null;
  }

  const profilePhotoUrl = getURL("UPLOADS") + currentUserProfile.profilepic;
  const profileName = `${currentUserProfile.firstname} ${currentUserProfile.lastname}`;  

  return (
    <Paper className={classes.paper} elevation={0}>
      <Grid container spacing={3} className={classes.mainContent}>
        <Grid item xs={3}>
          {!currentUserProfile.profilepic && (
            <Avatar className={classes.avatar}>
              {avatarLetters(
                currentUserProfile?.firstname || '',
                currentUserProfile?.lastname || ''
              )}
            </Avatar>
          )}
          {currentUserProfile.profilepic && (
            <Avatar
              className={classes.avatar}
              alt={currentUserProfile.username}
              src={profilePhotoUrl}
              onClick={(e) => {
                dispatch(openAvatarModal(profilePhotoUrl || "/profile-icon.png"))
              }}
            />
          )}
        </Grid>

        <Grid item xs={7}>
          <Grid container>
            <Grid xs={11}>
              <Typography className={classes.profileTitle}>
                {profileName}
              </Typography>
            </Grid>
          </Grid>

          {/* <Divider variant="middle" /> */}
        </Grid>
        <Grid item xs={2}>
        <Button
        size="small"
        variant="contained"
        style={{
          float: "right",
          textTransform: "none",
        }}
        onClick={(e) => props.history.goBack()}
        color="secondary"
      >
        Back
      </Button>
        </Grid>
        
        <Paper elevation={0} className={classes.tabPaper}>
          <ProfileInfo userDetails={currentUserProfile} classes={classes} dispatch={dispatch} mobileNumberStatusDisplay={mobileNumberStatusDisplay} />
        </Paper>

        {/* Review Component */}
        {/* <Paper elevation={1} className={classes.ReviewPaper}>
          <ReviewContainer userDetails={currentUserProfile} classes={classes} />
        </Paper> */}
      </Grid>
    </Paper>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUserProfile: state.usersReducer.currentUserProfile,
    userDetail: state.userDetailsReducer.userDetail,
    mobileNumberStatusDisplay: state.userDetailsReducer.mobileNumberStatusDisplay
  };
};

export default connect(mapStateToProps)(
  withStyles(styles)(withRouter(UserProfile))
);
