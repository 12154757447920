import React from "react";
import { useForm, Controller } from "react-hook-form";
import { Link as RouterLink, withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import bindActionCreators from "redux/es/bindActionCreators";
import { connect } from "react-redux";
import { openDialog, closeDialog } from "../actions/appActions";
import { constants } from "../helpers/constants";
import ConfirmationDialog from "../components/ConfirmationDialog";
import ReCAPTCHA from "react-google-recaptcha";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ControlledInput from "../components/ControlledInput";

import {
  onPasswordChanged,
  onEmailChanged,
  onFirstNameChanged,
  onLastNameChanged,
  onUsernameChanged,
  onRegisterFormSubmitted,
  onCheckBoxToggle,
  requestCountries,
  requestStates,
  requestDistricts,
  // requestIndustries,
} from "../actions/registerActions";

import {
  Button,
  Popper,
  Grid,
  Link,
  MenuItem,
  TextField,
  Checkbox,
  Typography,
  Radio,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useEffect } from "react";
import { useState } from "react";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
});

const gendersArray = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
];

const schema = yup
  .object({
    firstname: yup.string().required().max(20, "first name too long"),
    lastname: yup.string().required().max(20, "last name too long"),
    email: yup.string().email().required(),
    username: yup.string().required().min(4, "username too short").max(15, "username too long").matches(/^[^\s]+$/, 'Username cannot contain spaces'),
    // bidAlias: yup.string().required(),
    password: yup
      .string()
      .required()
      .min(6, "password is too short")
      .max(20, "password too long"),
    confirmPassword: yup.string().oneOf([yup.ref("password"), null]),
    // gender: yup.string().required(),
    // dob: yup.string().min(8, "invalid date"),
    phone: yup
      .string()
      .required()
      .matches(/\d{10}/, "phone number must be 10 digits"),
    phoneCode: yup.string(),
    // houseno: yup.string().required(),
    // street: yup.string().required(),
    // city: yup.string().required(),
    country: yup.object().shape({
      country: yup.string().required(),
    }),
    // state: yup.string().required(),
    // district: yup.string().required(),
    // pincode: yup.string().required(),
    termsOfServicesCheckBox: yup
      .boolean()
      .oneOf([true], "please agree to terms of services"),
  })
  .required();

function SignupForm(props) {
  console.log(props);
  const recaptchaRef = React.createRef();
  const [isRecaptchaValue, setRecaptchaValue] = useState(false);
  const onSubmitRecaptcha = () => {
    setRecaptchaValue((re) => !re);
  };

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      firstname: "",
      lastname: "",
      email: "",
      username: "",
      bidAlias: "",
      password: "",
      confirmPassword: "",
      // gender: "",
      // dob: null,
      phone: "",
      phoneCode: "",
      // houseno: "",
      // street: "",
      // city: "",
      country: "",
      // state: "",
      // district: {},
      // industry: {},
      // pincode: "",
      termsOfServicesCheckBox: false,
      errors: {},
      subs: [],
      signUpDisabled: true,
      dialog: false,
      title: "",
      des: "",
      n: "",
      p: "",
      subs: [],
    },
  });
  const onSubmitForm = (formData) =>{
    console.log(formData)
    props.onRegisterFormSubmitted(formData, () => {
      props.history.push("/otp-phone-verify");
    });
  }
  const formState = watch();
  console.log(errors);
  useEffect(() => {
    props.requestCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const changeVisibility = (value, fun) =>{
    fun(!value)
  }

  return (
    <Grid
      container
      style={{ marginTop: 60 }}
      justify="center"
      alignItems="center"
    >
      {formState.dialog && (
        <ConfirmationDialog
          open={formState.dialog}
          handleCancel={() =>
            reset({
              ...formState,
              dialog: false,
              title: "",
              des: "",
              n: "",
              p: "",
            })
          }
          handleConfirm={() => props.onConfirm()}
          title={formState.title}
          description={formState.des}
          negative={formState.n}
          positive={formState.p}
        />
      )}
      <Grid xs={8} justify="center" alignItems="center">
        <Grid
          container
          justify="center"
          alignItems="center"
          alignContent="center"
        >
          <Grid xs={10} justify="center" alignItems="center">
            <Typography display="block" align="center">
              Already a member?{" "}
              <Link
                size="small"
                variant="outlined"
                component={RouterLink}
                to={"/login"}
              >
                Login here
              </Link>{" "}
            </Typography>
          </Grid>
          <Grid xs={10} justify="center" alignItems="center">
            <Typography
              component="h1"
              variant="h4"
              display="block"
              style={{
                margin: 10,
                textAlign: "center",
              }}
            >
              Register
            </Typography>
          </Grid>
        </Grid>

        <Divider />
        <form>
          <Grid
            container
            spacing={2}
            justify="space-evenly"
            alignItems="center"
            style={{ marginTop: 25 }}
          >
            <Grid
              item
              xs={8}
              sm={6}
              md={4}
              justify="center"
              alignItems="center"
            >
              <Grid container spacing={0} justify="center" alignItems="center">
                

                

                <Grid item xs={5}>
                  <Typography
                    style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                  >
                    Email*
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <ControlledInput
                    control={control}
                    name="email"
                    helperText={errors.email?.message}
                    error={Boolean(errors.email)}
                  />
                </Grid>

                <Grid item xs={5}>
                  <Typography
                    style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                  >
                    User Name*
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <ControlledInput
                    control={control}
                    name="username"
                    helperText={errors.username?.message}
                    error={Boolean(errors.username)}
                  />
                </Grid>

                {/* <Grid item xs={5}>
                  <Typography
                    style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                  >
                    Bid Alias Name*
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <ControlledInput
                    control={control}
                    name="bidAlias"
                    helperText={errors.bidAlias?.message}
                    error={Boolean(errors.bidAlias)}
                  />
                </Grid> */}

                <Grid item xs={5}>
                  <Typography
                    style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                  >
                    Password*
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <ControlledInput
                    control={control}
                    name="password"
                    type="password"
                    helperText={errors.password?.message}
                    error={Boolean(errors.password)}
                    showPassword={showPassword}
                    ChangeVisibilityFunction={()=>changeVisibility(showPassword, setShowPassword)}
                  />
                </Grid>

                

                

                <Grid item xs={5}>
                  <Typography
                    style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                  >
                    Confirm Password*
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <ControlledInput
                    control={control}
                    name="confirmPassword"
                    type="password"
                    helperText={errors.confirmPassword?.message}
                    error={Boolean(errors.confirmPassword)}
                    showPassword={showConfirmPassword}
                    ChangeVisibilityFunction={()=>changeVisibility(showConfirmPassword, setShowConfirmPassword)}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={8}
              sm={6}
              md={4}
              justify="center"
              alignItems="center"
            >
              <Grid container spacing={0} justify="center" alignItems="center">
              <Grid item xs={5}>
                  <Typography
                    style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                  >
                    First Name*
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <ControlledInput
                    control={control}
                    name="firstname"
                    helperText={errors.firstname?.message}
                    error={Boolean(errors.firstname)}
                  />
                </Grid>
              <Grid item xs={5}>
                  <Typography
                    style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                  >
                    Last Name*
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <ControlledInput
                    control={control}
                    name="lastname"
                    helperText={errors.lastname?.message}
                    error={Boolean(errors.lastname)}
                  />
                </Grid>

                <Grid item xs={5}>
                  <Typography
                    style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                  >
                    Mobile No.*
                  </Typography>
                </Grid>

                <Grid item xs={7}>
                  <ControlledInput
                    control={control}
                    name="phone"
                    type="tel"
                    // className={classes.fields}

                    helperText={errors.phone?.message}
                    error={Boolean(errors.phone)}
                  />
                </Grid>

                {/* <Grid item xs={5}>
                  <Typography
                    style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                  >
                    DOB*
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <ControlledInput
                    control={control}
                    name="dob"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    helperText={errors.dob?.message}
                    error={Boolean(errors.dob)}
                  />
                </Grid>
                <Grid item xs={5}>
                  <Typography
                    style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                  >
                    Gender*
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Controller
                    control={control}
                    name="gender"
                    render={({ field }) => (
                      <TextField
                        select
                        {...field}
                        fullWidth
                        name="gender"
                        helperText={errors.gender?.message}
                        error={Boolean(errors.gender)}
                      >
                        <MenuItem
                          style={{ fontSize: "1em" }}
                          key=""
                          value=""
                          disabled
                        >
                          Select Gender
                        </MenuItem>
                        {gendersArray.map((option) => {
                          return (
                            <MenuItem
                              style={{ fontSize: "1em" }}
                              key={option.value}
                              value={option.value}
                            >
                              {option.label}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    )}
                  />
                </Grid> */}
                <Grid item xs={5}>
                </Grid>
                <Grid item xs={7}>
                  
                </Grid>
                
                
                {/* House No
                <Grid item xs={5}>
                  <Typography
                    style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                  >
                    House No.*
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <ControlledInput
                    control={control}
                    name="houseno"
                    fullWidth={true}
                    helperText={errors.houseno?.message}
                    error={Boolean(errors.houseno)}
                  />
                </Grid> */}

                {/* street */}
                {/* <Grid item xs={5}>
                  <Typography
                    style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                  >
                    Street*
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <ControlledInput
                    control={control}
                    name="street"
                    helperText={errors.street?.message}
                    error={Boolean(errors.street)}
                  />
                </Grid> */}

                {/* City */}
                {/* <Grid item xs={5}>
                  <Typography
                    style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                  >
                    City*
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <ControlledInput
                    control={control}
                    name="city"
                    fullWidth={true}
                    helperText={errors.city?.message}
                    error={Boolean(errors.city)}
                  />
                </Grid> */}

                {/* Country */}
                <Grid item xs={5}>
                  <Typography
                    style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                  >
                    Country*
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Controller
                    control={control}
                    fullwidth={true}
                    size="small"
                    name="country"
                    render={({ form }) => (
                      <Autocomplete
                        id="country-input"
                        options={props.countriesList}
                        name="country"
                        {...form}
                        // className={classes.fields}
                        getOptionLabel={(option) => option.country}
                        onChange={(e, country) => {
                          props.requestStates(country.country);
                          setValue("country", country);
                        }}
                        renderInput={(params) => (
                          <TextField
                            inputProps={{
                              autocomplete: "new-password",
                              form: {
                                autocomplete: "off",
                              },
                            }}
                            // variant="outlined"
                            {...params}
                            name="country"
                            helperText={errors.country?.message}
                            error={errors.country}
                            // label="Select Country"
                            fullWidth={true}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>

                {/* State */}
                {/* <Grid item xs={5}>
                  <Typography
                    style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                  >
                    State*
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Controller
                    control={control}
                    name="state"
                    render={() => (
                      <Autocomplete
                        id="state-input"
                        options={props.statesList}
                        fullwidth
                        size="small"
                        getOptionLabel={(option) => option}
                        // className={classes.fields}
                        onChange={(e, state) => {
                          props.requestDistricts(state);
                          setValue("state", state);
                        }}
                        disabled={!Boolean(formState.country.country)}
                        renderInput={(params) => (
                          <TextField
                            inputProps={{
                              autocomplete: "new-password",
                              form: {
                                autocomplete: "off",
                              },
                            }}
                            // variant="outlined"
                            {...params}
                            // label="Select State"
                            name="country"
                            helperText={errors.country?.message}
                            fullWidth={true}
                            required
                          />
                        )}
                      />
                    )}
                  />
                </Grid> */}

                {/* District */}
                {/* {formState.country.country === "India" && (
                  <>
                    <Grid item xs={5}>
                      <Typography
                        style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                      >
                        District*
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Controller
                        control={control}
                        name="district"
                        render={() => (
                          <Autocomplete
                            id="district-input"
                            options={props.districtsList}
                            getOptionLabel={(option) => option.district}
                            fullwidth
                            size="small"
                            onChange={(e, district) =>
                              setValue("district", district)
                            }
                            disabled={!Boolean(formState.state)}
                            renderInput={(params) => (
                              <TextField
                                inputProps={{
                                  autocomplete: "new-password",
                                  form: {
                                    autocomplete: "off",
                                  },
                                }}
                                // variant="outlined"
                                {...params}
                                // label="Select District"
                                fullWidth={true}
                                required
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                  </>
                )} */}

                {/* {formState.country.country === "India" && (
              <>
                <Grid item xs={5}>
                  <Typography
                    style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                  >
                    Industry*
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Autocomplete
                    id="industry-input"
                    options={props.industriesList}
                    getOptionLabel={(option) => option.industry}
                    // className={classes.fields}
                    fullwidth={true}
                    size="small"
                    onChange={(e, object) =>
                      props.onIndustrySelect(e, object)
                    }
                    disabled={!Boolean(formState.state)}
                    renderInput={(params) => (
                      <TextField
                        inputProps={{
                          autocomplete: "new-password",
                          form: {
                            autocomplete: "off",
                          },
                        }}
                        // variant="outlined"
                        {...params}
                        // label="Select Industry"
                        fullWidth={true}
                        required
                      />
                    )}
                  />
                </Grid>
              </>
            )} */}

            {/* Pincode */}
                {/* <Grid item xs={5}>
                  <Typography
                    style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                  >
                    Pincode*
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <ControlledInput
                    control={control}
                    name="pincode"
                    fullWidth={true}
                    // margin="dense"

                    helperText={errors.pincode?.message}
                    error={Boolean(errors.pincode)}
                  />
                </Grid> */}
              </Grid>
            </Grid>
            <Grid item xs={12} align="center">
              <ReCAPTCHA
                ref={props.recaptchaRef}
                sitekey={"6LeXTy0aAAAAAKz9jhndz-pMSF-clhSB0ZWrFmr9"}
                onChange={onSubmitRecaptcha}
              />
            </Grid>
            <Grid item xs={12} align="center">
              <Typography
                // margin="dense"
                component="label"
                style={{ fontSize: "1.2em" }}
              >
                <Controller
                  control={control}
                  name="termsOfServicesCheckBox"
                  render={(fields) => (
                    <Checkbox
                      checked={fields.value}
                      onChange={(e) =>
                        setValue("termsOfServicesCheckBox", e.target.checked)
                      }
                      required
                    />
                  )}
                />
                I agree the Terms of services
              </Typography>

              {errors.termsOfServicesCheckBox && (
                <Typography
                  // margin="dense"
                  component="h6"
                  variant="h6"
                  fontSize="1em"
                  color="error"
                >
                  {errors.termsOfServicesCheckBox?.message}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12} align="center">
              <Button
                size="large"
                variant="contained"
                color="primary"
                type="submit"
                onClick={handleSubmit(onSubmitForm)}
                disabled={!Boolean(isRecaptchaValue)}
                style={{ textTransform: "none" }}
              >
                Sign up
              </Button>
            </Grid>
            <Grid item xs={12} align="center">
              <Typography>
                Already a member?{" "}
                <Link component={RouterLink} to={"/login"}>
                  Login here
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
}

function mapStateToProps(state) {
  return {
    countriesList: state.registerReducer.countries,
    statesList: state.registerReducer.states,
    districtsList: state.registerReducer.districts,
    // industriesList: state.registerReducer.industries,
    responseUserDetails: state.registerReducer.userDetail,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      onFirstNameChanged,
      onLastNameChanged,
      onEmailChanged,
      onUsernameChanged,
      onCheckBoxToggle,
      onRegisterFormSubmitted,
      onPasswordChanged,
      requestCountries,
      requestStates,
      requestDistricts,
      // requestIndustries,
      openDialog,
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SignupForm))
);
