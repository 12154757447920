import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import BidForm from "./BidForm";
import BidFeed from "./BidFeed";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { establishSocketConnection } from "../../helpers/bid-socket";
import moment from "moment";
import TimerComponent from "../TimerComponent";

// import Moment from "react-moment";

const Bid = ({ itemAd, userDetail }) => {
  const [isAllUserBids, setIsAllUserBids] = useState(true);
  const [isAfterFinalCall, setIsAfterFinalCall] = useState(false);
  const [page, setPage] = useState(1);
  useEffect(() => {
    const chatEventTarget = establishSocketConnection(itemAd._id);
  }, [itemAd]);

  return (
    <Grid container justify="center">
      <Grid item xs={12}>
        <Grid container justify="center">
          {itemAd.postedBy !== userDetail?._id && itemAd.bidOpen && (
            itemAd?.finalCallDateTime ? moment.duration(moment(new Date()).diff(itemAd?.finalCallDateTime)).asSeconds() > 0 ? <></> : <BidForm itemAd={itemAd} userDetail={userDetail} isAllUserBids={isAllUserBids} isAfterFinalCall={isAfterFinalCall} page={page} />: userDetail ? <BidForm itemAd={itemAd} userDetail={userDetail} isAllUserBids={isAllUserBids} isAfterFinalCall={isAfterFinalCall} page={page}/> : null
          )}
          <div style={{textAlign:'center'}}>
          {/* <TimerComponent bid={{...itemAd, finalCallDateTime: "2022-08-15T17:30"}}/> */}
          {itemAd && moment(itemAd?.finalCallDateTime).isAfter(new Date()) && itemAd.bidOpen && <TimerComponent bid={itemAd}/>}
          {/* {
            itemAd?.finalCallDateTime && moment(new Date()).isAfter(itemAd?.finalCallDateTime) && <>
              <TimerComponent />
            </>
          } */}
          {
            !itemAd.bidOpen || (itemAd?.finalCallDateTime && itemAd.finalCallMade) ? !(moment.duration(moment(new Date()).diff(itemAd?.finalCallDateTime)).asSeconds() > 0) ? <></> : <Typography variant="h6">Bidding Closed</Typography> : <></>
          }
          {/* {!itemAd.bidOpen || !(itemAd && moment(itemAd?.finalCallDateTime).isAfter(new Date()) && itemAd.finalCallMade) && (
             <Typography variant="h6">Bidding Closed</Typography>
          )} */}
          </div>
          <BidFeed itemAd={itemAd} owner={itemAd.postedBy === userDetail?._id} isAfterFinalCall={isAfterFinalCall} setIsAfterFinalCall={setIsAfterFinalCall} setIsAllUserBids={setIsAllUserBids} isAllUserBids={isAllUserBids} setPage={setPage} page={page}/>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    userDetail: state.userDetailsReducer.userDetail,
  };
};

export default connect(mapStateToProps)(withRouter(Bid));
