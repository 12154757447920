import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import PersonIcon from "@material-ui/icons/Person";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { getReviews, postUserReview } from "../../actions/reviewActions";
import getURL from "../../helpers/apis";
import { openAvatarModal } from "../../actions/appActions";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
}));

const ReviewContainer = ({ dispatch, userDetails, auth, reviews }) => {
  const classes = useStyles();
  const [reviewText, setReviewText] = useState("");
  useEffect(() => {
    dispatch(getReviews({ userId: userDetails._id }));
  }, [userDetails._id]);

  const handlePostReview = () => {
    dispatch(
      postUserReview({ userId: userDetails._id, text: reviewText })
    ).then((res) => !res.error && setReviewText(""));
  };

  return (
    <List className={classes.root}>
      <Divider variant="fullWidth" />
      {!auth && (
        <>
          <Typography variant="h6" align="center">
            Post a Review
          </Typography>
          <Grid
            container
            justify="space-evenly"
            alignContent="center"
            alignItems="center"
            style={{ margin: 12 }}
          >
            <Grid xs={10}>
              <TextField
                label={"Describe your experience with " + userDetails.username}
                placeholder={
                  "Describe your experience with " + userDetails.username
                }
                value={reviewText}
                multiline
                rows={2}
                fullWidth
                required
                onChange={(e) => setReviewText(e.target.value)}
                focused
                variant="outlined"
              />
            </Grid>
            <Grid>
              <Button
                variant="contained"
                color="primary"
                onClick={() => reviewText !== "" && handlePostReview()}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
          <Divider variant="fullWidth" />
        </>
      )}

      <Typography variant="h6" align="center">
        User Reviews
      </Typography>
      {reviews &&
        reviews.length > 0 &&
        reviews.map((review) => (
          <>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                {review.postedByProfilepic ? (
                  <Avatar
                    alt={review.postedByUsername}
                    className={classes.avatar}
                    src={review.postedByProfilePic}
                    onClick={() =>
                      dispatch(
                        openAvatarModal(
                          getURL("UPLOADS") + review.postedByProfilepic
                        )
                      )
                    }
                  />
                ) : (
                  <Avatar
                    className={classes.avatar}
                    alt="owner"
                    onClick={() => dispatch(openAvatarModal())}
                  >
                    <PersonIcon />
                  </Avatar>
                )}
              </ListItemAvatar>
              <ListItemText
                primary={review.postedByUsername}
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body1"
                      className={classes.inline}
                      color="textPrimary"
                    >
                      {review.text}
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
            <Divider variant="inset" component="li" />
          </>
        ))}
    </List>
  );
};

const mapStateToProps = (state) => {
  return {
    reviews: state.reviewReducer.reviews,
  };
};

export default connect(mapStateToProps)(ReviewContainer);
