import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import ReactPlayer from "react-player";
import withRouter from "react-router-dom/es/withRouter";
import { Paper, Typography, Divider, Tabs, Tab } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import "react-fine-uploader/gallery/gallery.css";
import { connect } from "react-redux";
import AlertDialog from "../AlertDialog";
import { constants } from "../../helpers/constants";

import FineUploaderTraditional from "fine-uploader-wrappers";
import Gallery from "react-fine-uploader";

import "react-fine-uploader/gallery/gallery.css";
import getURL from "../../helpers/apis";

const UploadComponent = ({ match, history }) => {
  const rootUrl = getURL("UPLOADS");
  const [authToken, setAuthToken] = useState(localStorage.getItem("jwt"));

  const adId = match.params.ad_id;

  useEffect(() => {
    setAuthToken(localStorage.getItem("jwt"));
  }, [localStorage]);

  const [uploader, setUploader] = useState(
    new FineUploaderTraditional({
      options: {
        maxConnections: 6,
        validation: {
          allowedExtensions: [
            "jpeg",
            "jpg",
            "png",
            "mp4",
            "mpeg",
            "webm",
            "mkv",
            "flv",
            "ogg",
            "ogv",
            "m4v",
            "m4p",
            "3gp",
          ],
          sizeLimit: 10485760,
          itemLimit: 6,
          stopOnFirstInvalidFile: false,
        },
        debug: true,
        request: {
          customHeaders: {
            Authorization: authToken,
          },
          inputName: "media",
        },
        session: {
          customHeaders: {
            Authorization: authToken,
          },
          endpoint: getURL("GET_ITEM_AD_GALLERIES_FOR_UPDATE"),
          params: { item_id: adId },
        },
        deleteFile: {
          enabled: true,
          forceConfirm: true,
          customHeaders: {
            Authorization: authToken,
          },
          endpoint: getURL("GALLERY_REMOVE"),
        },
        retry: {
          enableAuto: true,
        },
      },
    })
  );

  useEffect(() => {
    setAuthToken(localStorage.getItem("jwt"));
    uploader.on("submit", (id, name) => {
      let file = uploader.methods.getFile(id);
      if (file.type.substr(0, 5) === "image") {
        uploader.methods.setEndpoint(
          getURL("GALLERY_UPLOAD") + "/" + "photos" + "/" + adId,
          id
        );
      } else if (file.type.substr(0, 5) === "video") {
        uploader.methods.setEndpoint(
          getURL("GALLERY_UPLOAD") + "/" + "videos" + "/" + adId,
          id
        );
      }
    });
    uploader.on("cancel", (id) => {
      uploader.methods.deleteFile(id);
    });
  }, []);

  return (
    <div style={{ width: "100%", margin: 32 }}>
      <Gallery style={{ width: "100%" }} uploader={uploader} />
      <Button
        variant="contained"
        color="secondary"
        size="large"
        style={{ float: "right", textTransform: "none", margin: 12 }}
        onClick={() => history.push("/myAds")}
      >
        Done
      </Button>
    </div>
  );
};

export default withRouter(UploadComponent);
