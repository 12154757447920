import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const PrivacyPolicy = () => {
  return (
    <Grid container justify="center" style={{ marginTop: 100 }}>
      <Grid container justify="center" style={{ margin: 12 }}>
        <Typography variant="h4">Privacy Policy</Typography>
      </Grid>

      <Grid container justify="center">
        <Grid
          item
          xs={6}
          style={{
            padding: 12,
            border: "solid",
            borderColor: "rgba(0,0,0,0.1)",
            borderWidth: 0.5,
            height: "fit-content",
          }}
        >
          <p>
            Celebebook strictly maintaining the users data with high security.
            Login passwords are storing in encryption format. Celebebook
            employees can’t login to subscribers account, because passwords are
            encrypted.
          </p>
          <p>
          Celebebook provides the services of posting the opportunities by 
          Production houses/Employer, and Opportunity seekers/Jobseekers can 
          apply to them. This page is used to inform website visitors regarding 
          our policies with the collection, use, and disclosure of personal information 
          if anyone decided to use our service.
          </p>
          <p>
            If you choose to use our service, then you agree to the collection
            and use of information in relation with this policy. The personal
            information that we collect are used for providing and improving the
            service.
          </p>
          <p>
            We will not use or share your information with anyone except as
            described in this privacy policy. The terms used in this privacy
            policy have the same meanings as in our terms and conditions, which
            is accessible at , unless otherwise defined in this privacy policy.
          </p>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PrivacyPolicy;
