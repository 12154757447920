import React, { useState, useEffect, useRef } from "react";
import withRouter from "react-router-dom/es/withRouter";

import FineUploaderTraditional from "fine-uploader-wrappers";
import Gallery from "react-fine-uploader";

import "react-fine-uploader/gallery/gallery.css";
import getURL from "../../helpers/apis";

const UploadComponent = ({ itemAd }) => {
  const rootUrl = getURL("UPLOADS");
  const [authToken, setAuthToken] = useState(localStorage.getItem("jwt"));
  useEffect(() => {
    setAuthToken(localStorage.getItem("jwt"));
  }, [localStorage]);

  const [uploader, setUploader] = useState(
    new FineUploaderTraditional({
      options: {
        maxConnections: 6,
        validation: {
          allowedExtensions: [
            "jpeg",
            "jpg",
            "png",
            "mp4",
            "mpeg",
            "webm",
            "mkv",
            "flv",
            "ogg",
            "ogv",
            "m4v",
            "m4p",
            "3gp",
          ],
          itemLimit: 6,
          sizeLimit: 10485760,
          stopOnFirstInvalidFile: false,
        },
        debug: true,
        request: {
          customHeaders: {
            Authorization: authToken,
          },
          inputName: "media",
        },
        session: {
          customHeaders: {
            Authorization: authToken,
          },
          endpoint: getURL("GET_ITEM_AD_GALLERIES_FOR_UPDATE"),
          params: { item_id: itemAd._id },
        },
        deleteFile: {
          enabled: true,
          forceConfirm: true,
          customHeaders: {
            Authorization: authToken,
          },
          endpoint: getURL("GALLERY_REMOVE"),
        },
        retry: {
          enableAuto: true,
        },
      },
    })
  );

  useEffect(() => {
    setAuthToken(localStorage.getItem("jwt"));

    uploader.on("submit", (id, name) => {
      let file = uploader.methods.getFile(id);
      if (file.type.substr(0, 5) === "image") {
        uploader.methods.setEndpoint(
          getURL("GALLERY_UPLOAD") + "/" + "photos" + "/" + itemAd._id,
          id
        );
      } else if (file.type.substr(0, 5) === "video") {
        uploader.methods.setEndpoint(
          getURL("GALLERY_UPLOAD") + "/" + "videos" + "/" + itemAd._id,
          id
        );
      }
    });
    uploader.on("cancel", (id) => {
      uploader.methods.deleteFile(id);
    });
  }, [uploader]);

  return <Gallery uploader={uploader} />;
};

export default withRouter(UploadComponent);
