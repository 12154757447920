import React, { useEffect, useState } from "react";
import getURL from "../../helpers/apis";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter, Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Paper,
  CircularProgress,
  Avatar,
  InputLabel,
  Grid,
  Select,
  MenuItem,
  FormControl,
} from "@material-ui/core";
// import TransactionsTabelToolbar from "./TransactionsTabelToolbar";
import TransactionsTableHead from "./TransactionsTableHead";
import { getBidTransactions } from "../../actions/bidPaymentActions";
import { getMembershipTransactions } from "../../actions/userDetailsAction";
import Pagination from "@material-ui/lab/Pagination";
import { constants } from "../../helpers/constants";
import { openAvatarModal } from "../../actions/appActions";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: 25,
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  pagination: {
    display: "flex",
    justifyContent: "space-between",
    margin: "8px",
  },
}));

const UserProfilesListViewContainer = ({ history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [filteredData, setFilteredData] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [pageNumber, setPageNumber] = useState(1);
  const [type, setType] = useState("bid");
  const [rowData, setRowData] = useState(null);
  const [totalPages, setTotalPages] = useState(null);

  const bidTransactions = useSelector(
    ({ bidPaymentReducer }) => bidPaymentReducer.bidTransactions
  );
  const membershipTransactions = useSelector(
    ({ userDetailsReducer }) => userDetailsReducer.membershipTransactions
  );

  const bidTransactionsLoading = useSelector(
    ({ bidPaymentReducer }) => bidPaymentReducer.bidTransactionsLoading
  );
  const membershipTransactionsLoading = useSelector(
    ({ userDetailsReducer }) => userDetailsReducer.membershipTransactionsLoading
  );
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {
    type === "bid"
      ? dispatch(getBidTransactions({ page: 0, limit: 20 }))
      : dispatch(getMembershipTransactions({ page: 0, limit: 20 }));
  }, [type]);

  useEffect(() => {
    pageNumber > 1 &&
      (type === "bid"
        ? dispatch(getBidTransactions({ page: pageNumber - 1, limit: 20 }))
        : dispatch(
            getMembershipTransactions({ page: pageNumber - 1, limit: 20 })
          ));
  }, [pageNumber]);

  useEffect(() => {
    type === "bid"
      ? !bidTransactionsLoading &&
        setRowData(bidTransactions && bidTransactions.purchases)
      : !membershipTransactionsLoading &&
        setRowData(membershipTransactions && membershipTransactions.purchases);
    type === "bid"
      ? !bidTransactionsLoading &&
        setTotalPages(bidTransactions && bidTransactions.totalPages)
      : !membershipTransactionsLoading &&
        setTotalPages(
          membershipTransactions && membershipTransactions.totalPages
        );
  }, [bidTransactions, membershipTransactions]);

  useEffect(() => {
    let data = rowData && rowData;
    setFilteredData(data);
  }, [rowData && rowData]);

  return (
    <Paper className={classes.root}>
      <div className={classes.pagination}>
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">Type</InputLabel>
          <Select
            value={type}
            onChange={(e) => {
              setPageNumber(1);
              setType(e.target.value);
            }}
          >
            <MenuItem value={"bid"}>Bid Transactions</MenuItem>
            <MenuItem value={"membership"}>Membership Transactions</MenuItem>
          </Select>
        </FormControl>

        <Pagination
          count={totalPages}
          page={pageNumber}
          variant="outlined"
          shape="rounded"
          onChange={(e, value) => setPageNumber(value)}
        />
      </div>
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={"medium"}
          aria-label="enhanced table"
        >
          <TransactionsTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={filteredData && filteredData.length}
            type={type}
          />

          <TableBody>
            {bidTransactionsLoading ? (
              <TableRow style={{ height: "100%" }}>
                <TableCell colSpan={6} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : filteredData && filteredData.length > 0 ? (
              filteredData.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row._id}
                    // onClick={() => history.push(`/user_profile/${row._id}`)}
                  >
                    {/* <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="default"
                    >
                      <Typography
                        style={{ color: constants.PRIMARY_COLOR }}
                        component={Link}
                        to={`/user_profile/${row._id}`}
                      >
                        {row.username}
                      </Typography>
                    </TableCell> */}
                    <TableCell align={type === "bid" ? "left" : "right"}>
                      {(row.itemAdId && row.itemAdId.itemName) ||
                        (row.items && row.items[0].price)}
                    </TableCell>
                    <TableCell align="right">
                      {row.totalPriceAfterTax}
                    </TableCell>
                    <TableCell align="right">{row.taxCGSTAmount}</TableCell>
                    <TableCell align="right">{row.taxSGSTAmount}</TableCell>
                    <TableCell align="right">
                      {moment(row.createdAt).format("DD-MM-YYYY")}
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow style={{ height: "100%" }}>
                <TableCell colSpan={6} align="center">
                  No records found.
                </TableCell>
              </TableRow>
            )}
            {/* {!bidTransactionsLoading && rowData && rowData.length && 10 - rowData.length > 0 ? (
                <TableRow style={{ height: 53 * (10 - rowData.length) }}>
                  <TableCell colSpan={6} />
                </TableRow>
              ) : ""} */}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
export default withRouter(UserProfilesListViewContainer);
