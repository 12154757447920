import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import ReactPlayer from "react-player";
import withRouter from "react-router-dom/es/withRouter";
import { Paper, Typography, Divider, Tabs, Tab } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import "react-fine-uploader/gallery/gallery.css";
import { connect } from "react-redux";
import AlertDialog from "../AlertDialog";
import { constants } from "../../helpers/constants";

import FineUploaderTraditional from "fine-uploader-wrappers";
import Gallery from "react-fine-uploader";

import "react-fine-uploader/gallery/gallery.css";
import getURL from "../../helpers/apis";

const UploadComponent = ({ match, history, uplaodImages, setUplaodImages }) => {
    const [authToken, setAuthToken] = useState(localStorage.getItem("jwt"));

    useEffect(() => {
        setAuthToken(localStorage.getItem("jwt"));
    }, [localStorage]);

    const [uploader, setUploader] = useState(
        new FineUploaderTraditional({
            options: {
                maxConnections: 6,
                validation: {
                    allowedExtensions: [
                        "jpeg",
                        "jpg",
                        "png",
                        "mp4",
                        "mpeg",
                        "webm",
                        "mkv",
                        "flv",
                        "ogg",
                        "ogv",
                        "m4v",
                        "m4p",
                        "3gp",
                    ],
                    sizeLimit: 10485760,
                    itemLimit: 6,
                    stopOnFirstInvalidFile: false,
                },
                chunking: {
                    enabled: true
                },
                // debug: true,
                // request: {
                //     customHeaders: {
                //         Authorization: authToken,
                //     },
                //     inputName: "media",
                // },
                // session: {
                //     customHeaders: {
                //         Authorization: authToken,
                //     },
                //     endpoint: getURL("GET_ITEM_AD_GALLERIES_FOR_UPDATE"),
                //     params: { item_id: adId },
                // },
                // deleteFile: {
                //     enabled: true,
                //     forceConfirm: true,
                //     customHeaders: {
                //         Authorization: authToken,
                //     },
                //     endpoint: getURL("GALLERY_REMOVE"),
                // },
                retry: {
                    enableAuto: false,
                },
                failedUploadTextDisplay: {
                    mode: 'default',
                    responseProperty: 'error123',
                },
                multiple: true
            },
        })
    );

    const prevUplaodImages = useRef([])

    useEffect(() => {
        setAuthToken(localStorage.getItem("jwt"));
        uploader.on("submitted", (id) => {
            let file = uploader.methods.getFile(id);
            prevUplaodImages.current = [...prevUplaodImages.current, file]
            setUplaodImages(prevUplaodImages.current)
        });
        uploader.on("cancel", (id, fileName) => {
            console.log(fileName)
            prevUplaodImages.current = prevUplaodImages.current.filter(file => file.name !== fileName)
            setUplaodImages(prevUplaodImages.current)
            // uploader.methods.deleteFile(id);
        });
    }, []);
    return (
        <div style={{ width: "100%", margin: 32 }}>
            <Gallery style={{ width: "100%" }} uploader={uploader} />
            {/* <Button
                variant="contained"
                color="secondary"
                size="large"
                style={{ float: "right", textTransform: "none", margin: 12 }}
                onClick={() => history.push("/myAds")}
            >
                Done
            </Button> */}
        </div>
    );
};

export default withRouter(UploadComponent);
