import React from "react";

import { Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { formatDate } from "../../helpers/globalFunctions";
import { constants } from "../../helpers/constants";

const useStyles = makeStyles((theme) => ({
  divider: {
    marginBottom: "8px",
  },
  paymentDetials: {
    width: "100%",
    maxWidth: "80%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  paymentDetailsRow: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginBottom: "8px",
  },
}));
function PaymentDetails(props) {
  const classes = useStyles();
  const { payment } = props;
  console.log("payment", payment);
  return (
    <div>
      {" "}
      <div className={classes.paymentDetailsRow}>
        <div>
          <Typography style={{ color: constants.PRIMARY_COLOR }}>
            Start Date
          </Typography>
        </div>
        <div>{formatDate(payment.createdAt)}</div>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.paymentDetailsRow}>
        <div>
          <Typography style={{ color: constants.PRIMARY_COLOR }}>
            Expiry Date
          </Typography>
        </div>
        <div>Lifetime Subscription</div>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.paymentDetailsRow}>
        <div>
          <Typography style={{ color: constants.PRIMARY_COLOR }}>
            Actual Price
          </Typography>
        </div>
        <div>{payment.items.price}</div>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.paymentDetailsRow}>
        <div>
          <Typography style={{ color: constants.PRIMARY_COLOR }}>
            Tax CGST Percent
          </Typography>
        </div>
        <div>{payment.taxCGSTPercent}</div>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.paymentDetailsRow}>
        <div>
          <Typography style={{ color: constants.PRIMARY_COLOR }}>
            Tax CGST Amount
          </Typography>
        </div>
        <div>{payment.taxCGSTAmount}</div>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.paymentDetailsRow}>
        <div>
          <Typography style={{ color: constants.PRIMARY_COLOR }}>
            Tax SGST Percent
          </Typography>
        </div>
        <div>{payment.taxSGSTPercent}</div>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.paymentDetailsRow}>
        <div>
          <Typography style={{ color: constants.PRIMARY_COLOR }}>
            Tax SGST Amount
          </Typography>
        </div>
        <div>{payment.taxSGSTAmount}</div>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.paymentDetailsRow}>
        <div>
          <Typography style={{ color: constants.PRIMARY_COLOR }}>
            Total Price After Tax
          </Typography>
        </div>
        <div>{payment.totalPriceAfterTax}</div>
      </div>
      <Divider className={classes.divider} />
    </div>
  );
}

export default PaymentDetails;
