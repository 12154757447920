import React from "react";
import getURL from "../helpers/apis";
import bindActionCreators from "redux/es/bindActionCreators";
import connect from "react-redux/es/connect/connect";
import { withStyles } from "@material-ui/core/styles";
import Menu from "../components/Menu";
import { homeStyles as pureStyle } from "../helpers/styles";
import {
  homeStyles as styles,
  profileMenuStyles,
} from "../helpers/materialStyles";
import AppBar from "@material-ui/core/AppBar";
import Badge from "@material-ui/core/Badge";
import Toolbar from "@material-ui/core/Toolbar";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Divider from "@material-ui/core/Divider";
import NotificationsRoundedIcon from "@material-ui/icons/NotificationsRounded";
import ChatRoundedIcon from "@material-ui/icons/ChatRounded";
import ArrowDropDownCircleRoundedIcon from "@material-ui/icons/ArrowDropDownCircleRounded";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import classNames from "classnames";
import Paper from "@material-ui/core/Paper";
import { Grid, Typography, Card, CardContent, Button, Box } from "@material-ui/core";
import { getCategories, logout } from "../actions/appActions";
import { requestCountries } from "../actions/registerActions";
import withRouter from "react-router-dom/es/withRouter";
import MainContent from "../components/MainContent";
import {
  getUserDetails,
  getUserAccessPages,
  getUserSettings,
  getNotification
} from "../actions/userDetailsAction";
import { openAvatarModal } from "../actions/appActions";

import Popover from "@material-ui/core/Popover";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Redirect, useHistory } from "react-router-dom";
import NotificationComponent from "../components/NotificationComponent";
import SearchField from "../components/SearchField";
import SearchResultField from "../components/SearchResultField/SearchResultField";
import { useLocation } from "react-router";
import ResetPassword from "./ResetPassword";
import { Route, Switch } from "react-router-dom";



class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isNotifOpen: false,
      notifAnchorEl: null,
      unreadCount: "",
      isChatUnread: false,
      isRedirect: false,
      redirectPath: null,
      location: this.props.history.location.pathname,
    };
  }

  componentDidMount() {
    if (localStorage.getItem("jwt")) {
      const jwt = localStorage.getItem("jwt");
      const userId = localStorage.getItem("userId");

      this.props.getUserDetails(jwt, userId);
      // this.props.getUserAccessPages();
      this.props.getUserSettings();
    }
    console.log(this.props.history.location.pathname, 'props')

  }
  redirect(path) {
    this.setState({ isRedirect: true, redirectPath: path });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.location !== this.props.history.location.pathname) {
      this.setState({ location: this.props.history.location.pathname });
      console.log(this.props.history.location.pathname, 'props')
      if (this.isAuthorized() && localStorage.getItem('jwt') && !this.props.userDetails) {
        const jwt = localStorage.getItem("jwt");
        const userId = localStorage.getItem("userId");
        this.props.getUserDetails(jwt, userId);
        this.props.getUserSettings();
      }
    }
  }
  // handleMenuOpen() {
  //   this.setState({ isOpen: this.state.isOpen ? false : true });
  // }

  isAuthorized = () => {
    const paths = ["login", "forgot-password", "register", "reset-password", "email-verify", "otp-phone"]
    if (paths.some(ele => this.props.history.location.pathname.toLowerCase().includes(ele))) {
      return false
    } else {
      // if (localStorage.getItem('jwt') && !this.props.userDetails) {
      //   const jwt = localStorage.getItem("jwt");
      //   const userId = localStorage.getItem("userId");
      //   this.props.getUserDetails(jwt, userId);
      //   this.props.getUserSettings();
      // }
      return true
    }
  }
  
  render() {
    let { classes, userDetail, dispatch } = this.props;
    console.log(this.props.history.location)
    if (this.state.isRedirect) {
      this.state.isRedirect = false;

      return <Redirect to={this.state.redirectPath} />;
    }
    return this.props.history.location.pathname.includes('/auth/resetPassword') ? 
    (
      <Switch>
        <Route
          path="/api/auth/resetPassword/:q"
          exact
          render={() =>
            localStorage.getItem("jwt") ? (
              <Redirect to={"/"} />
            ) : (
              <ResetPassword />
            )
          }
        />
      </Switch>
    )

    : (
      <Grid container>
        <AppBar position="fixed" className={classes.appBar}>
          <Grid container justify="space-between">
            <Grid item className={classes.appTitleContainer}>
              <Typography variant="h3" className={classes.appTitle}>
                iBids
              </Typography>
            </Grid>
            <Grid item className={classes.appMenuContainer}>
              {this.props.userDetail || localStorage.getItem('jwt') ? <Menu {...this.props} /> :
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    height: "100%",
                    alignItems: "center",
                    margin: "0px 8px",

                  }}
                >
                  <div style={{ width: "100%", display: 'flex', alignItems: "center" }}><Button
                    className={
                      this.props.history.location.pathname === "/ads" || this.props.history.location.pathname === "/"
                        ? [classes.button, classes.selectButton]
                        : [classes.button]
                    }
                    onClick={() => {
                      this.redirect("/ads");
                    }}
                  >
                    Ads
                  </Button>
                    <Button
                      className={
                        this.props.history.location.pathname === "/contactus"
                          ? [classes.button, classes.selectButton]
                          : [classes.button]
                      }
                      onClick={() => {
                        this.redirect("/contactus");
                      }}
                    >
                      Contact Us
                    </Button>
                    <Button
                      className={
                        [classes.button]
                      }
                      onClick={() => {
                        window.open("https://www.youtube.com/watch?v=YMoJfdTb6Q8&list=PLeHqwgRfPnR8Bij2UtS7QpszsI3nJL2BB")
                      }}
                    >
                      Public Response
                    </Button>
                    <Button
                      className={
                        [classes.button]
                      }
                      onClick={() => {
                        window.open("https://www.youtube.com/watch?v=X7EicU_UERo")
                      }}
                    >
                      How its different
                    </Button>
                    <SearchResultField userId={""} />
                  </div>
                </div>
              }
            </Grid>
            {/* <Grid item justify="flex-end" alignContent="space-around"> */}
            {localStorage.getItem('jwt') ?
              <Grid item className={classes.profileContainer}>
                <Grid
                  item
                  xs={12}
                  className={classes.container}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Grid
                    container
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Avatar
                      src={
                        this.props.userDetail &&
                        getURL("UPLOADS") + this.props.userDetail.profilepic
                      }
                      onClick={(e) =>
                        {
                          this.props.openAvatarModal(
                          this.props.userDetail && this.props.userDetail.profilepic ?
                          getURL("UPLOADS") + this.props.userDetail.profilepic : "/profile-icon.png"
                        )}
                      }
                    // className={classes.profileDefaultIcon}
                    />
                    <Typography
                      // className={classes.title}
                      style={{ margin: "6px" }}
                      variant="h6"
                      onClick={(e) => this.redirect("/profile-me")}
                    >
                      {this.props.userDetail && this.props.userDetail.username}
                    </Typography>
                    <NotificationComponent getNotification={getNotification} />
                  </Grid>
                </Grid>
                <Grid item className={classes.notification}>
                  <Options {...this.props} />
                </Grid>
              </Grid> : <Grid item className={classes.profileContainer}>
                <Grid
                  item
                  xs={12}
                  className={classes.container}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Grid
                    container
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Button variant="contained" style={{ margin: "auto" }} color='secondary' onClick={() => {
                      this.redirect(`/login?redirect=${this.props.history.location.pathname}`);
                    }}> Login</Button>
                  </Grid>
                </Grid>
                <Grid item className={classes.notification}>
                  <Options {...this.props} />
                </Grid>
              </Grid>}

            {/* </Grid> */}
          </Grid>
        </AppBar>
        {
          this.isAuthorized() ? <Grid
            container
            now
            wrap="nowrap"
            justifyContent="center"
            className={classes.body}
            style={{ position: 'relative' }}
          >
            <Grid sm={2} item key="cust-add-left" style={{ marginLeft: 5 }}>
              <CustomAddContainerLeft hidden={true} />
            </Grid>
            <Grid item sm={10} style={{ marginLeft: 10, marginRight: 10 }}>
              <AppContainer {...this.props} />
            </Grid>
            <Grid sm={2} item key="cust-add-right" style={{}}>
              <CustomAddContainerRight hidden={true} />
            </Grid>
          </Grid> : <MainContent {...this.props} />
        }

{/* paths.includes(this.props.history.location.pathname) */}
        {/* <Grid
          container
          now
          wrap="nowrap"
          justifyContent="center"
          className={classes.body}
          style={{position: 'relative'}}
        >
          <Grid sm={2} item key="cust-add-left" style={{ marginLeft: 5 }}>
            <CustomAddContainerLeft hidden={true} />
          </Grid>
          <Grid item sm={10} style={{ marginLeft: 10, marginRight: 10 }}>
            <AppContainer {...this.props} />
          </Grid>
          <Grid sm={2} item key="cust-add-right" style={{  }}>
            <CustomAddContainerRight hidden={true} />
          </Grid>
        </Grid> */}
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userDetail: state.userDetailsReducer.userDetail,
    accessPages: state.userDetailsReducer.accessPages,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      logout,
      getUserDetails: (jwt, userId) => getUserDetails(jwt, userId),
      getUserAccessPages,
      openAvatarModal,
      getUserSettings,
      getCategories,
      requestCountries
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Home))
);

class CustomAddContainerLeft extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
    };
  }

  render() {
    let { classes, hidden } = this.props;
    if (hidden) {
      return null;
    }
    return (
      <>
        <Card variant="outlined">
          <CardContent style={{ width: "100%", height: "300px" }}>
            <a
              // href="https://homepages.cae.wisc.edu/~ece533/images/airplane.png"
              target="_blank"
            >
              {/* <img
                width="100%"
                // src="https://homepages.cae.wisc.edu/~ece533/images/airplane.png"
                alt="ad-1"
              /> */}
            </a>
          </CardContent>
        </Card>

        <Card variant="outlined">
          <CardContent style={{ width: "100%", height: "300px" }}>
            <a
              // href="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
              target="_blank"
            >
              <video width="100%" autoPlay loop muted>
                <source
                  // src={
                  // "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
                  // }
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </a>
          </CardContent>
        </Card>

        <Card variant="outlined">
          <CardContent style={{ width: "100%", height: "300px" }}>
            <a
              // href="https://homepages.cae.wisc.edu/~ece533/images/airplane.png"
              target="_blank"
            >
              {/* <img
                width="100%"
                // src="https://homepages.cae.wisc.edu/~ece533/images/airplane.png"
                alt="ad-1"
              /> */}
            </a>
          </CardContent>
        </Card>
        <Card variant="outlined">
          <CardContent style={{ width: "100%", height: "300px" }}>
            <a
              // href="https://homepages.cae.wisc.edu/~ece533/images/airplane.png"
              target="_blank"
            >
              {/* <img
                width="100%"
                // src="https://homepages.cae.wisc.edu/~ece533/images/airplane.png"
                alt="ad-1"
              /> */}
            </a>
          </CardContent>
        </Card>
        <Card variant="outlined">
          <CardContent style={{ width: "100%", height: "300px" }}>
            <a
              // href="https://homepages.cae.wisc.edu/~ece533/images/airplane.png"
              target="_blank"
            >
              {/* <img
                width="100%"
                // src="https://homepages.cae.wisc.edu/~ece533/images/airplane.png"
                alt="ad-1"
              /> */}
            </a>
          </CardContent>
        </Card>
      </>
    );
  }
}

class CustomAddContainerRight extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
    };
  }

  render() {
    let { classes, hidden } = this.props;
    if (hidden) {
      return null;
    }
    return (
      <>
        <Card variant="outlined">
          <CardContent style={{ width: "100%", height: "300px" }}>
            <a
              // href="https://homepages.cae.wisc.edu/~ece533/images/airplane.png"
              target="_blank"
            >
              {/* <img
                width="100%"
                // src="https://homepages.cae.wisc.edu/~ece533/images/airplane.png"
                alt="ad-1"
              /> */}
            </a>
          </CardContent>
        </Card>

        <Card variant="outlined">
          <CardContent style={{ width: "100%", height: "300px" }}>
            <a
              // href="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
              target="_blank"
            >
              <video width="100%" autoPlay loop muted>
                <source
                  // src={
                  // "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
                  // }
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </a>
          </CardContent>
        </Card>

        <Card variant="outlined">
          <CardContent style={{ width: "100%", height: "300px" }}>
            <a
              // href="https://homepages.cae.wisc.edu/~ece533/images/airplane.png"
              target="_blank"
            >
              {/* <img
                width="100%"
                // src="https://homepages.cae.wisc.edu/~ece533/images/airplane.png"
                alt="ad-1"
              /> */}
            </a>
          </CardContent>
        </Card>
        <Card variant="outlined">
          <CardContent style={{ width: "100%", height: "300px" }}>
            <a
              // href="https://homepages.cae.wisc.edu/~ece533/images/airplane.png"
              target="_blank"
            >
              {/* <img
                width="100%"
                // src="https://homepages.cae.wisc.edu/~ece533/images/airplane.png"
                alt="ad-1"
              /> */}
            </a>
          </CardContent>
        </Card>
        <Card variant="outlined">
          <CardContent style={{ width: "100%", height: "300px" }}>
            <a
              // href="https://homepages.cae.wisc.edu/~ece533/images/airplane.png"
              target="_blank"
            >
              {/* <img
                width="100%"
                // src="https://homepages.cae.wisc.edu/~ece533/images/airplane.png"
                alt="ad-1"
              /> */}
            </a>
          </CardContent>
        </Card>
      </>
    );
  }
}

class AppContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
    };
  }



  render() {
    let { classes, userDetail } = this.props;
    let paths = ['/contactus', '/tc', '/privacypolicy']
    return paths.includes(this.props.history.location.pathname) || (userDetail && !userDetail?.mobileValidated) ? <MainContent {...this.props} /> :  (
      <Paper style={{ height: "100%" }}>
        <MainContent {...this.props} />
      </Paper>
    );
  }
}

class Options extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      anchorEl: null,
      isRedirect: false,
      redirectPath: null,
      location: this.props.history.location.pathname,
    };
  }

  handlePopover(e) {
    this.setState({
      isOpen: this.state.isOpen ? false : true,
      anchorEl: e.currentTarget,
    });
  }

  redirect(path) {
    this.setState({ isRedirect: true, redirectPath: path });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.location !== this.props.history.location.pathname) {
      this.setState({ location: this.props.history.location.pathname });
    }
  }
  render() {
    let { classes, userDetail } = this.props;
    console.log(this.props)

    if (this.state.isRedirect) {
      this.state.isRedirect = false;
      return <Redirect to={this.state.redirectPath} />;
    }

    return (
      <Grid container>
        <IconButton
          onClick={(e) => {
            this.handlePopover(e);
          }}
        >
          <ArrowDropDownCircleRoundedIcon
            fontSize="large"
            style={{ color: "white" }}
          />
        </IconButton>
        <Popover
          id="simple-popper"
          open={this.state.isOpen}
          anchorEl={this.state.anchorEl}
          onClose={(e) => {
            this.handlePopover(e);
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <List component="nav" className={classes.root}>
          
            {userDetail && <>
              <ListItem
                button
                className={classes.listItem}
                onClick={(e) => {
                  this.handlePopover(e);
                  this.redirect("/settings");
                }}
              >
                <ListItemText
                  primary="Settings"
                  classes={{ primary: classes.listItemText }}
                />
              </ListItem>
              <ListItem
                button
                className={classes.listItem}
                onClick={(e) => {
                  this.handlePopover(e);
                  this.redirect(`/subscription`);
                }}
              >
                <ListItemText
                  primary="Subscription"
                  classes={{ primary: classes.listItemText }}
                />
              </ListItem>
              <ListItem
                button
                className={classes.listItem}
                onClick={(e) => {
                  this.handlePopover(e);
                  this.redirect(`/transactions`);
                }}
              >
                <ListItemText
                  primary="Transactions"
                  classes={{ primary: classes.listItemText }}
                />
              </ListItem>
              <ListItem
                button
                className={classes.listItem}
                onClick={(e) => {
                  this.handlePopover(e);
                  this.redirect("/buyAdSpace");
                }}
              >
                <ListItemText
                  primary="Buy AdSpace"
                  classes={{ primary: classes.listItemText }}
                />
              </ListItem>
              <ListItem
                button
                className={classes.listItem}
                onClick={(e) => {
                  this.handlePopover(e);
                  this.redirect("/buyShare");
                }}
              >
                <ListItemText
                  primary="Become a Partner?"
                  classes={{ primary: classes.listItemText }}
                />
              </ListItem>
              
            </>}
            <ListItem
                button
                className={classes.listItem}
                onClick={(e) => {
                  this.handlePopover(e);
                  this.redirect("/tc");
                }}
              >
                <ListItemText
                  primary="Terms and Condition"
                  classes={{ primary: classes.listItemText }}
                />
              </ListItem>
              <ListItem
                button
                className={classes.listItem}
                onClick={(e) => {
                  this.handlePopover(e);
                  this.redirect("/privacypolicy");
                }}
              >
                <ListItemText
                  primary="Privacy policy"
                  classes={{ primary: classes.listItemText }}
                />
              </ListItem>
              {userDetail && <><ListItem
                button
                className={classes.listItem}
                onClick={(e) => {
                  this.handlePopover(e);
                  this.props.logout();
                  this.props.getCategories();
                  this.props.requestCountries();
                }}
              >
                <ListItemText
                  primary="Logout"
                  classes={{ primary: classes.listItemText }}
                />
              </ListItem></>}
          </List>
        </Popover>
      </Grid>
    );
  }
}

Options = withStyles(profileMenuStyles)(Options);
