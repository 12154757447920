import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Popper,
  Grid,
  Menu,
  MenuItem,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import { getUserAddress } from "../../../src/actions/applicantsDetailsActions";
import { useSelector, useDispatch } from "react-redux";
import {
  requestCountries,
  requestStates,
  requestDistricts,
  requestNeighbourhood
} from "../../actions/registerActions";
import { getCategories, getUserAddress, getSubCategories, getManufacturerMake, getManufacturerYear, getManufacturerModel } from "../../actions/appActions";
import { postItemAd } from "../../actions/itemAdActions";
import useStyles from "./styles";
import { constants } from "../../helpers/constants";
import moment from "moment";
import UploadMedia from "./UploadMedia";

const CreateItemAd = (props) => {
  const {
    dispatch,
    countriesList,
    history,
    statesList,
    districtsList,
    categories,
    fetchingCategories,
    userDetails,
    countryCodeList,
    subCategories,
    fetchingSubCategories,
    neighbourhoodList,
    manufacturerMakes,
    manufacturerModels,
    manufacturerYears
  } = props;
  const classes = useStyles();
  const [itemName, setItemName] = useState("");
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [type, setType] = useState("");
  const [price, setPrice] = useState("");
  const [phone, setPhone] = useState("");
  const [description, setDescription] = useState("");
  const [houseno, setHouseno] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [district, setDistrict] = useState("");
  const [neighbourhood, setNeighbourhood] = useState("");
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [makeYear, setMakeYear] = useState("");
  const [mileage, setMileage] = useState("");
  const [isOwner, setIsOwner] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [currencycode, setCurrencyCode] = useState("")
  const [displayPhone, setDisplayPhone] = useState(false);
  const [pincode, setPincode] = useState("");
  const [sameAddress, setSameAddress] = useState(false);
  const [uplaodImages, setUplaodImages] = useState([])
  const [newOrUsed, setNewOrUsed] = useState("")

  useEffect(() => {
    dispatch(getCategories());
    dispatch(requestCountries());


  }, [dispatch]);

  useEffect(() => {
    if (sameAddress) {
      getUserAddress({ user_id: localStorage.getItem("userId") }).then(
        (res) => {
          dispatch(requestStates(res.data[0].country));
          dispatch(requestDistricts(res.data[0].country, res.data[0].state));
          dispatch(requestNeighbourhood(res.data[0].country, res.data[0].state, res.data[0].district || ""));
          setCountry(res.data[0].country);
          setDistrict(res.data[0].district || "");
          setState(res.data[0].state);
          setCity(res.data[0].city);
          setPincode(res.data[0].pincode);
          setHouseno(res.data[0].houseno);
          setStreet(res.data[0].street);
          setNeighbourhood(res.data[0].neighbourHood)
        }
      );
    } else {
      setCountry("");
      setState("");
      setDistrict("");
      setCity("");
      setPincode("");
      setHouseno("");
      setStreet("");
    }
  }, [dispatch, sameAddress]);

  const onSubmit = (e) => {
    e.preventDefault();
    let param = new FormData();
    const params = {
      itemName,
      phone,
      category,
      type: category == "Sports" ? type : "",
      price,
      description,
      // currencycode,
      state,
      pincode,
      country,
      houseno,
      city,
      district,
      displayPhone,
      street,
      subcategory: subCategory,
      postedByUsername: userDetails.username,
      neighbourHood: neighbourhood,
      ownerOrDealer: isOwner,
      mileage: mileage,
      yearOfManufacture: makeYear,
      model: model,
      make: make,
      newOrused: newOrUsed
    };
    let formData1 = new FormData();
    for(let i=0; i<uplaodImages.length; i++){
      formData1.append("media", uplaodImages[i]);
    }
    // formData1.append("media", uplaodImages);
    Object.keys(params).map(function (key) {
      formData1.append(key, params[key]);
    });

    dispatch(postItemAd(formData1, history));
  };

  const onCountrySelect = (country) => {
    dispatch(requestStates(country));
    setCountry(country);
    setState("");
    setDistrict("");
  };

  const onCategorySelect = (category) => {
    setSubCategory('')
    dispatch(getSubCategories(category));
    setCategory(category)
  };
  const onSubCategorySelect = (subcategory) => {
    setMake('')
    if (category === 'Automobiles' || category === 'Ships Boats') {
      dispatch(getManufacturerMake(subcategory));
    }
    setSubCategory(subcategory)
  };
  const onMakeSelect = (make) => {
    // setMake('')
    if (category === 'Automobiles' || category === 'Ships Boats') {
      // dispatch(getManufacturerMake(subcategory));
    }
    setMake(make)
  };

  const callOtherAPI = (fun1, fun2, fun3, val, isMake) => {
    fun1('');
    let sub = subCategory || val
    if (category === 'Automobiles' || category === 'Ships Boats' || (category==="Bank Auctions" && (sub==="Cars" || sub==="Bikes"))) {
      dispatch(fun2(val, isMake ? subCategory ?? val : undefined));
    }
    fun3(val)
  }

  const onCurrencyCodeSelect = (code) => {
    setCurrencyCode(code?.split("-")[1]?.trim())

  }

  const onStateSelect = (state) => {
    setState(state);
    dispatch(requestDistricts(country, state));
  };

  const onDistrictSelect = (district) => {
    setDistrict(district);
    dispatch(requestNeighbourhood(country, state, district));
  };
  console.log(category)

  const PopperMy = function (props) {
    return (
      <Popper {...props} style={{ width: 212 }} placement="bottom-start" />
    );
  };

  return (
    <Grid container justify="center" spacing={0}>
      <Grid container xs={10} className={classes.paper} spacing={3}>
        <Grid
          container
          justify="center"
          alignItems="center"
          alignContent="center"
        >
          <Grid xs={12} justify="center" alignItems="center">
            <Button
              variant="contained"
              color="secondary"
              size="small"
              style={{ float: "right", textTransform: "none" }}
              onClick={() => history.push("/ads")}
            >
              Back
            </Button>
          </Grid>
          {/* <Grid xs={10} justify="center" alignItems="center"> */}
          <Typography component="h1" variant="h5" className={classes.title} >
            Post New Ad
          </Typography>
          {/* </Grid> */}
        </Grid>

        <form
          className={classes.form}
          onSubmit={onSubmit}
          className="inherit-bg-color"
        >
          <Grid container spacing={6} justify="space-between">
            <Grid item xs={6} spacing={1}>
              <Grid container spacing={1}>
                <Grid item xs={5}>
                  <Typography
                    style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                  >
                    Category*
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Autocomplete
                    options={!fetchingCategories ? categories : []}
                    getOptionLabel={(option) => option}
                    onChange={(e, object) => onCategorySelect(object)}
                    value={category}
                    PopperComponent={PopperMy}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth required />
                    )}
                  />
                </Grid>
                {/* {category === "Sports" && (
                  <>
                    <Grid item xs={5}>
                      <Typography
                        style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                      >
                        Type*
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Autocomplete
                        options={["Team", "Player"]}
                        getOptionLabel={(option) => option}
                        onChange={(e, object) => setType(object)}
                        value={type}
                        PopperComponent={PopperMy}
                        renderInput={(params) => (
                          <TextField {...params} fullWidth required />
                        )}
                      />
                    </Grid>
                  </>
                )} */}

                <Grid item xs={5}>
                  <Typography
                    style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                  >
                    Sub Category*
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Autocomplete
                    options={!fetchingSubCategories ? subCategories : []}
                    getOptionLabel={(option) => option}
                    onChange={(e, object) => { callOtherAPI(setMake, getManufacturerMake, setSubCategory, object) }}
                    value={subCategory}
                    PopperComponent={PopperMy}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth required={subCategories.length} />
                    )}
                  />
                </Grid>

                {
                  category === 'Automobiles' || category === 'Ships Boats' || (category==="Bank Auctions" && (subCategory==="Cars" || subCategory==="Bikes")) ? <>
                    <Grid item xs={5}>
                      <Typography
                        style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                      >
                        Manufacturer*
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Autocomplete
                        options={manufacturerMakes}
                        getOptionLabel={(option) => option}
                        onChange={(e, object) => { callOtherAPI(setMake, getManufacturerModel, setMake, object, true) }}
                        value={make}
                        PopperComponent={PopperMy}
                        renderInput={(params) => (
                          <TextField {...params} fullWidth required={manufacturerMakes.length} />
                        )}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <Typography
                        style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                      >
                        Model*
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Autocomplete
                        options={manufacturerModels}
                        getOptionLabel={(option) => option}
                        onChange={(e, object) => { callOtherAPI(setMakeYear, getManufacturerYear, setModel, object) }}
                        value={model}
                        PopperComponent={PopperMy}
                        renderInput={(params) => (
                          <TextField {...params} fullWidth required={manufacturerModels.length} />
                        )}
                      />
                    </Grid>

                    <Grid item xs={5}>
                      <Typography
                        style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                      >
                        Year*
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Autocomplete
                        options={manufacturerYears}
                        getOptionLabel={(option) => option}
                        onChange={(e, object) => { setMakeYear(object) }}
                        value={makeYear}
                        PopperComponent={PopperMy}
                        renderInput={(params) => (
                          <TextField {...params} fullWidth required={manufacturerYears.length} />
                        )}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <Typography
                        style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                      >
                        Mileage*
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <TextField
                        // variant="outlined"
                        required
                        fullWidth
                        type="number"
                        // label="price"
                        value={mileage}
                        onChange={(e) => setMileage(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <Typography
                        style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                      >
                        Owner/Dealer*
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Autocomplete
                        options={["Owner", "Dealer"]}
                        getOptionLabel={(option) => option}
                        onChange={(e, object) => { setIsOwner(object) }}
                        value={isOwner}
                        PopperComponent={PopperMy}
                        renderInput={(params) => (
                          <TextField {...params} fullWidth required={true} />
                        )}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <Typography
                        style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                      >
                        New/Used*
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Autocomplete
                        options={["New", "Used"]}
                        getOptionLabel={(option) => option}
                        onChange={(e, object) => { setNewOrUsed(object) }}
                        value={newOrUsed}
                        PopperComponent={PopperMy}
                        renderInput={(params) => (
                          <TextField {...params} fullWidth required={true} />
                        )}
                      />
                    </Grid>

                  </> : null
                }

                <Grid item xs={5}>
                  <Typography
                    style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                  >
                    Item Name*
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <TextField
                    value={itemName}
                    onChange={(e) => {
                      setItemName(e.target.value);
                    }}
                    fullWidth
                    required
                  />
                </Grid>

                <Grid item xs={5}>
                  <Typography
                    style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                  >
                    Price*
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <TextField
                    // variant="outlined"
                    required
                    fullWidth
                    type="number"
                    // label="price"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                  />
                </Grid>

                {/* <Grid item xs={5}>
                  <Typography
                    style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                  >
                    Phone*
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <TextField
                    // variant="outlined"
                    required
                    fullWidth
                    // label="price"
                    value={phone}
                    onChange={(e) =>
                      e.target.value.length <= 10 &&
                      (Number(e.target.value) || e.target.value === "") &&
                      setPhone(e.target.value)
                    }
                  />
                </Grid> */}

                {/* Currency Code */}
                {/* <Grid item xs={5}>
                  <Typography
                    style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                  >
                    Currency Code*
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Autocomplete
                    options={countryCodeList}
                    getOptionLabel={(option) => option}
                    onChange={(e, object) => onCurrencyCodeSelect(object)}
                    value={currencycode}
                    PopperComponent={PopperMy}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // label="Select Country"
                        // variant="outlined"
                        fullWidth
                        required
                      />
                    )}
                  />
                </Grid> */}
                



                {/* <Grid item xs={5}>
                  <Typography
                    style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                  >
                    Display Phone Number*
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={displayPhone}
                        checked={displayPhone}
                        onChange={(e) => setDisplayPhone(!displayPhone)}
                      />
                    }
                    labelPlacement="end"
                    // label="Copy from my profile"
                  />
                </Grid> */}
              </Grid>
            </Grid>
            <Grid item xs={6} spacing={0}>
              <Grid container spacing={0}>
                <Grid item xs={5}>
                  <Typography
                    style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                  >
                    Copy from my profile
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={sameAddress}
                        checked={sameAddress}
                        onChange={(e) => setSameAddress(!sameAddress)}
                      />
                    }
                    labelPlacement="start"
                  // label="Copy from my profile"
                  />
                </Grid>
                <Grid item xs={5}>
                  <Typography
                    style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                  >
                    House No.*
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <TextField
                    // variant="outlined"
                    required
                    id="houseno-input"
                    // label="House No"
                    fullWidth
                    onChange={(e) => setHouseno(e.target.value)}
                    value={houseno}
                  />
                </Grid>

                <Grid item xs={5}>
                  <Typography
                    style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                  >
                    Street*
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <TextField
                    // variant="outlined"
                    required
                    id="street-input"
                    // label="Street No"
                    fullWidth
                    onChange={(e) => setStreet(e.target.value)}
                    value={street}
                  />
                </Grid>



                <Grid item xs={5}>
                  <Typography
                    style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                  >
                    Country*
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Autocomplete
                    options={countriesList}
                    getOptionLabel={(option) => option}
                    onChange={(e, object) => onCountrySelect(object)}
                    value={country}
                    PopperComponent={PopperMy}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // label="Select Country"
                        // variant="outlined"
                        fullWidth
                        required
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={5}>
                  <Typography
                    style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                  >
                    State*
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Autocomplete
                    id="state-input"
                    options={statesList}
                    fullwidth
                    size="small"
                    getOptionLabel={(option) => option}
                    value={state}
                    onChange={(e, object) => onStateSelect(object)}
                    disabled={!Boolean(country !== "")}
                    PopperComponent={PopperMy}
                    renderInput={(params) => (
                      <TextField
                        // variant="outlined"
                        {...params}
                        // label="Select State"
                        fullWidth
                        required
                      />
                    )}
                  />
                </Grid>

                <>
                    <Grid item xs={5}>
                      <Typography
                        style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                      >
                        District*
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Autocomplete
                        id="state-input"
                        options={districtsList}
                        fullwidth
                        size="small"
                        getOptionLabel={(option) => option}
                        value={district}
                        onChange={(e, object) => {
                          onDistrictSelect(object)
                          // setDistrict(object)
                        }}
                        disabled={!Boolean(state !== "")}
                        PopperComponent={PopperMy}
                        renderInput={(params) => (
                          <TextField
                            // variant="outlined"
                            {...params}
                            // label="Select State"
                            fullWidth
                            required
                          />
                        )}
                      />
                    </Grid>
                  </>
                  <>
                    <Grid item xs={5}>
                      <Typography
                        style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                      >
                        Neighbourhood*
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Autocomplete
                        id="state-input"
                        options={neighbourhoodList}
                        fullwidth
                        size="small"
                        getOptionLabel={(option) => option}
                        value={neighbourhood}
                        onChange={(e, object) => {
                          // onDistrictSelect(object)
                          setNeighbourhood(object)
                        }}
                        disabled={!Boolean(state !== "")}
                        PopperComponent={PopperMy}
                        renderInput={(params) => (
                          <TextField
                            // variant="outlined"
                            {...params}
                            // label="Select State"
                            fullWidth
                            required={neighbourhoodList.length}
                          />
                        )}
                      />
                    </Grid>
                  </>

                <Grid item xs={5}>
                  <Typography
                    style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                  >
                    Town/Village*
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <TextField
                    // variant="outlined"
                    required
                    id="city-input"
                    // label="City"
                    fullWidth
                    onChange={(e) => setCity(e.target.value)}
                    value={city}
                  />
                </Grid>

                <Grid item xs={5}>
                  <Typography
                    style={{ margin: 10, color: constants.PRIMARY_COLOR }}
                  >
                    Pincode*
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <TextField
                    // variant="outlined"
                    required
                    id="pincode-input"
                    // label="Pin Code"
                    fullWidth
                    onChange={(e) => setPincode(e.target.value)}
                    value={pincode}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <Typography
                style={{ margin: 10, color: constants.PRIMARY_COLOR }}
              >
                Description*
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <TextField
                variant="outlined"
                required
                multiline
                rows={6}
                fullWidth
                // label="Description"
                value={description}
                onChange={(e) =>
                  e.target.value.length < 150 && setDescription(e.target.value)
                }
              />
            </Grid>
            <UploadMedia uplaodImages={uplaodImages} setUplaodImages={setUplaodImages} />
            <Grid item xs={12} align="center">
              <Button
                variant="contained"
                type="submit"
                size="large"
                className={classes.submit}
                style={{ textTransform: "none" }}
                color="primary"
                disabled={!uplaodImages.length}
              >
                Post Ad!
              </Button>
              <Button
                variant="contained"
                size="large"
                color="secondary"
                className={classes.submit}
                style={{ textTransform: "none" }}
                onClick={() => history.push("/ads")}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    countriesList: state.registerReducer.countries
      ? state.registerReducer.countries.map((country) => country.country)
      : [],
    countryCodeList: state.registerReducer.countries
      ? state.registerReducer.countries.map((country) => `${country.country} - ${country?.currencycode || ''}`)
      : [],
    statesList: state.registerReducer.states,
    districtsList: state.registerReducer.districts
      ? state.registerReducer.districts.map((district) => district.district)
      : [],
    neighbourhoodList: state.registerReducer.neighbourhoods
      ? state.registerReducer.neighbourhoods.map((ele) => ele.name)
      : [],
    categories: state.appReducer.categories.map((item) => item.category) || [],
    subCategories: state.appReducer.subCategories.map((item) => item.subcategory) || [],
    manufacturerMakes: state.appReducer?.manufacturerMake?.map((item) => item.make) || [],
    manufacturerModels: state.appReducer?.manufacturerModel?.map((item) => item.model) || [],
    manufacturerYears: state.appReducer?.manuractureringYear?.map((item) => item.year) || [],
    fetchingCategories: state.appReducer.fetchingCategories,
    fetchingSubCategories: state.appReducer.fetchingSubCategories,
    userDetails: state.userDetailsReducer.userDetail,
  };
};

export default connect(mapStateToProps)(withRouter(CreateItemAd));
