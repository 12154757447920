import { isArray } from "util";

const NULL = null;
const UNDEFINED = undefined;
const EMPTY_STRING = "";
const UNDEFINED_STRING = "undefined";

// Returns if a value is a string
const isString = value => {
  return typeof value === "string" || value instanceof String;
};

const isNumber = value => {
  return typeof value === "number" && isFinite(value);
};

// Returns if a value is a function
const isFunction = value => {
  return typeof value === "function";
};

// Returns if a value is an object
const isObject = value => {
  return value && typeof value === "object" && value.constructor === Object;
};

// Returns if a value is null
const isNull = value => {
  return value === null;
};

// Returns if a value is undefined
const isUndefined = value => {
  return typeof value === "undefined";
};

// Returns if a value is a boolean
const isBoolean = value => {
  return typeof value === "boolean";
};

// Returns if a value is a regexp
const isRegExp = value => {
  return value && typeof value === "object" && value.constructor === RegExp;
};

// Returns if value is a date object
const isDate = value => {
  return value instanceof Date;
};

// Returns if a Symbol
const isSymbol = value => {
  return typeof value === "symbol";
};

// Returns if value is an error object
const isError = value => {
  return value instanceof Error && typeof value.message !== "undefined";
};

export const isValidParam = value => {
  let isValid = true;
  try {
    if (NULL === value || UNDEFINED === value || UNDEFINED_STRING === value) {
      isValid = false;
    }
  } catch (error) {
    console.error("Error in paramVerifier.js => isValidParam(): " + error);
  }
  return isValid;
};

export const getStringParam = value => {
  let rtnValue = "";
  try {
    if (isValidParam(value)) {
      rtnValue = new String(value).toString();
    }
  } catch (error) {
    console.error("Error in paramVerifier.js => getStringParam(): " + error);
  }
  return rtnValue;
};

export const getObjectParam = value => {
  let rtnValue = new Object();
  try {
    if (isValidParam(value) && isObject(value)) {
      rtnValue = value;
    }
  } catch (error) {
    console.error("Error in paramVerifier.js => getObjectParam(): " + error);
  }
  return rtnValue;
};

export const getArrayParam = value => {
  let rtnValue = new Array();
  try {
    if (isValidParam(value) && Array.isArray(value)) {
      rtnValue = value;
    }
  } catch (error) {
    console.error("Error in paramVerifier.js => getArrayParam(): " + error);
  }
  return rtnValue;
};

export const getIntParam = value => {
  let rtnValue = 0;
  try {
    if (isValidParam(value)) {
      if (!isNumber(value)) {
        try {
          rtnValue = parseInt(value);
        } catch (e) {
          console.error(
            "Error in paramVerifier.js => getIntParam() => !isNumber(): " + e
          );
        }
      } else {
        rtnValue = value;
      }
    }
  } catch (error) {
    console.error("Error in paramVerifier.js => getIntParam(): " + error);
  }
  return rtnValue;
};

export const getBooleanParam = value => {
  let rtnValue = false;
  try {
    if (isValidParam(value)) {
      if (isBoolean(value)) {
        rtnValue = value;
      } else if ("true") {
        rtnValue = true;
      } else if ("false") {
        rtnValue = false;
      }
    }
  } catch (error) {
    console.error("Error in paramVerifier.js => getIntParam(): " + error);
  }
  return rtnValue;
};
