import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Menu from "@material-ui/core/Menu";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import ListSubheader from "@material-ui/core/ListSubheader";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import CloseIcon from '@material-ui/icons/Close';
import { getCategories, getManufacturerMake, getManufacturerModel, getManufacturerYear, getSubCategories } from "../../actions/appActions";
import FilterListIcon from '@material-ui/icons/FilterList';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import {
  getItemAdsByCategory,
  getMyAdsByCategory,
  clearAds,
} from "../../actions/itemAdActions";
import getURL from "../../helpers/apis";
import {
  requestCountries,
  requestStates,
  requestDistricts,
  requestNeighbourhood,
} from "../../actions/registerActions";
import moment from "moment";
import { Badge, Grid, List, ListItem, ListItemText, Popover, Popper, Slider, TextField } from "@material-ui/core";
import ScrollTop from "../ScrollToTop";
import AlertDialog from "../AlertDialog";
import { Autocomplete } from "@material-ui/lab";
// import AdComponent from "../GoogleAdsComponent";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    marginTop: 20,
    backgroundColor: theme.palette.background.paper,
    // display: "flex",
    // flexWrap: "wrap",
    justifyContent: "space-evenly",
    // overflow: "hidden",
    // margin: "25px"
    // backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down('sm')]: {
      marginTop: localStorage.getItem('jwt') ? 150 : 20,
    },
    [theme.breakpoints.between("sm", "md")]: {
      marginTop: 20,
      // backgroundColor: "blue"
    },
  },
  gridList: {
    // width: "inherit",
    // padding: 6,
    // height: "100%",
    // width: "100%",
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    // alignItems: "center",
    // justifyContent: "center",
  },
  card: {
    height: "100%",
    paddingLeft: "100%",
    margin: 2,
    marginBottom: 3,
  },
  icon: {
    color: "rgba(255, 255, 255, 0.54)",
  },
  margin: {
    margin: theme.spacing(1),
    float: "right",
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  loadMoreButton: {
    // margin: theme.spacing(1),
    border: '1px solid'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const ItemAdsContainer = ({
  dispatch,
  categories,
  fetchingCategories,
  itemAds,
  fetchingAds,
  countriesList,
  statesList,
  districtList,
  history,
  match,
  userDetails,
  subCategories,
  isFromSearch,
  manufacturerMakes,
  manufacturerModels,
  manufacturerYears,
  neighbourhoodList
}) => {
  const classes = useStyles();
  const [categoryValue, setCategoryValue] = useState(0);
  const [subcategoryValue, setSubcategoryValue] = useState(0);
  const [countryValue, setCountryValue] = useState(0);
  const [stateValue, setStateValue] = useState(0);
  const [districtValue, setDistrictValue] = useState(0);
  const [categoryAnchorEl, setCategoryAnchorEl] = useState(null);
  const [subcategoryAnchorEl, setSubcategoryAnchorEl] = useState(null);
  const [countryAnchorEl, setCountryAnchorEl] = useState(null);
  const [stateAnchorEl, setStateAnchorEl] = useState(null);
  const [districtEl, setDistrictEl] = useState(null);
  const [makeValue, setmakeValue] = useState(0);
  const [makeAnchorEl, setmakeAnchorEl] = useState(null);
  const [modelValue, setmodelValue] = useState(0);
  const [modelAnchorEl, setmodelAnchorEl] = useState(null);
  const [makeYearValue, setmakeYearValue] = useState(0);
  const [makeYearAnchorEl, setmakeYearAnchorEl] = useState(null);
  const [neighbourhoodValue, setneighbourhoodValue] = useState(0);
  const [neighbourhoodAnchorEl, setneighbourhoodAnchorEl] = useState(null);
  const categoryOpen = Boolean(categoryAnchorEl);
  const subcategoryOpen = Boolean(subcategoryAnchorEl);
  const countryOpen = Boolean(countryAnchorEl);
  const stateOpen = Boolean(stateAnchorEl);
  const districtOpen = Boolean(districtEl);
  const neighbourhoodOpen = Boolean(neighbourhoodAnchorEl);
  const manuOpen = (fn) => {
    return Boolean(fn)
  }
  const [filteredAds, setFilteredAds] = useState([]);
  const rootUrl = getURL("UPLOADS");
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(clearAds());
    dispatch(getCategories());
    dispatch(requestCountries());
    setCountryValue(0)
  }, [dispatch, match.path]);

  useEffect(() => {
    countryValue !== 0 && 
      dispatch(
        requestStates(
          countryValue ? countryValue : "All"
        )
      );
  }, [countryValue]);

  useEffect(() => {
    if (
      countryValue &&
      countryValue === "India" &&
      statesList?.length !== 0 &&
      stateValue !== 0
    ) {
      const stateName = stateValue;
      dispatch(requestDistricts(countryValue, stateName));
    }
  }, [stateValue]);
  useEffect(() => {
    if (
      countryValue &&
      countryValue === "India" &&
      statesList?.length !== 0 &&
      districtValue !== 0
    ) {
      const districtName = districtValue;
      dispatch(requestNeighbourhood(countryValue, stateValue, districtName));
    }
  }, [districtValue]);

  // useEffect(() => {
  //   if (!isFromSearch) {

  //     !fetchingAds && fetchAds()
  //   }

  // }, [
    
  // ]);
  useEffect(() => {
    setPage(1)
    setCategoryValue(0)
    setCountryValue(0)
    !fetchingAds && fetchAds(true)
  }, [match.path,page,userDetails])
  useEffect(() => {
    return () => {
      setPage(1);
      clearAds()
      clearAllFilter()
      dispatch(getCategories())
    }
  }, [])

  useEffect(() => {
    categoryValue
      ?
      setFilteredAds(
        itemAds.filter(
          (ad) => ad.categoryname === categoryValue
        )
      )
      : setFilteredAds(itemAds);
  }, [itemAds, categoryValue, categories]);

  const fetchAds = isNewTab => {
    let params = {
      category: (categoryValue && !isNewTab && categories)
        ? categoryValue || "All"
        : "All",
      subcategory: subcategoryValue && !isNewTab
        ? subcategoryValue || "All"
        : "All",
      country: countryValue && !isNewTab ? countryValue || 'All' : "All",
      state: stateValue && !isNewTab ? stateValue || 'All' : "All",
      district: districtValue && !isNewTab
        ? districtValue || 'All'
        : "All",
        neighbourHood: neighbourhoodValue && !isNewTab
        ? neighbourhoodValue || 'All'
        : "All",
      page,

      offset: 20,
      priceFrom: priceFrom ? priceFrom : undefined,
      priceTo: priceTo ? priceTo : undefined
    }
    if (categoryValue === 'Automobiles' || categoryValue === 'Ships Boats') {
      params = {
        ...params,
        make: makeValue && !isNewTab
          ? makeValue || undefined
          : undefined,
        model: modelValue && !isNewTab
          ? modelValue || undefined
          : undefined,
        yearOfManufactureFrom: yearOfManufactureFrom ? yearOfManufactureFrom : undefined,
        yearOfManufactureTo: yearOfManufactureTo ? yearOfManufactureTo : undefined,
        mileageFrom: mileageFrom ? mileageFrom : undefined,
        mileageTo: mileageTo ? mileageTo : undefined,
        ownerOrDealer: "Owner",
      }
    }
    if (match.path === "/ads" || match.path === "/") {
      dispatch(getItemAdsByCategory(params));
    } else if (categories.length && match.path === "/myAds") {
      dispatch(getMyAdsByCategory(params));
    }
  }
  const handleCategorySelect = (newValue) => {
    setCategoryAnchorEl(null);
    if (newValue) {
      dispatch(getSubCategories(newValue))
      if (newValue && (newValue === 'Automobiles' || categories[newValue - 1]?.category === 'Ships Boats')) {
        dispatch(getManufacturerYear())
      };
    };
    setCategoryValue(newValue);
  };

  const handleCategoryMenu = (event) => {
    setCategoryAnchorEl(event.currentTarget);
  };

  const handleCategoryClose = () => {
    setCategoryAnchorEl(null);
  };

  const handleSubCategorySelect = (newValue) => {
    setSubcategoryAnchorEl(null);
    setSubcategoryValue(newValue);
    if (newValue && (categoryValue === 'Automobiles' || categoryValue === 'Ships Boats')) {
      dispatch(getManufacturerMake(newValue))
    };
  };

  const handleSubCategoryMenu = (event) => {
    setSubcategoryAnchorEl(event.currentTarget);
  };

  const handleSubCategoryClose = () => {
    setSubcategoryAnchorEl(null);
  };

  const handleCountrySelect = (newValue) => {
    setCountryAnchorEl(null);
    setCountryValue(newValue);
    setStateValue(0);
    setDistrictValue(0)
  };

  const handleCountryMenu = (event) => {
    setCountryAnchorEl(event.currentTarget);
  };

  const handleCountryClose = () => {
    setCountryAnchorEl(null);
  };

  const handleStateSelect = (newValue) => {
    setStateAnchorEl(null);
    setStateValue(newValue);
    setDistrictValue(0)
  };

  const handleDistrictSelect = (newValue) => {
    setDistrictEl(null);
    setDistrictValue(newValue);
    setneighbourhoodValue(0)
  };

  const handleNeighbourhoodSelect = (newValue) => {
    setneighbourhoodAnchorEl(null);
    setneighbourhoodValue(newValue);
  };

  const handleSelect = (newValue, fn1, fn2, fn3, value, isModel) => {
    // fn1(null);
    fn2(newValue);
    dispatch(fn3(value, isModel ? subcategoryValue : undefined))
  };

  const handleStateMenu = (event) => {
    setStateAnchorEl(event.currentTarget);
  };

  const handleDistrictMenu = (event) => {
    setDistrictEl(event.currentTarget);
  };

  const handleNeighbourhoodMenu = (event) => {
    setneighbourhoodAnchorEl(event.currentTarget);
  };

  const handleMenu = (event, fn) => {
    fn(event.currentTarget);
  };
  const handleStateClose = () => {
    setStateAnchorEl(null);
  };
  const handleDistrictClose = () => {
    setDistrictEl(null);
  };

  const handleNeighbourhoodClose = () => {
    setneighbourhoodAnchorEl(null);
  };
  const handelMenuClose = (fn) => {
    fn(null)
  }

  const Ad = (tile) => (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Card key={tile.img} onClick={() => {
        if (localStorage.getItem('jwt')) {
          history.push(`/ads/${tile.uid}`)
        } else {
          // setOpenDialog(true)
          history.push(`/ads/${tile.uid}`)
        }
      }}>
        <CardMedia
          component="img"
          alt={tile.itemName}
          height="140"
          // style={{ height: "10vw" }}
          image={rootUrl + tile.thumbnail}
          title={tile.itemName}
        />
        <CardActionArea>
          <CardContent
            style={{
              padding: 6,
              // display: "flex",
            }}
          >
            <div
              style={{
                width: "100%",
                // padding: 6,
                display: "flex",
                justifyContent: "space-between",
              }}
            >

              <Typography variant="body2">{tile?.subcategory || 'subcategory'}</Typography>
              <Typography
                variant="subtitle2"
                align="right"
                style={{ alignSelf: "center" }}
              >
                {"₹" + Number(tile.price).toLocaleString("en-IN")}
              </Typography>
            </div>
            <div
              style={{
                width: "100%",
                // padding: 6,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="caption">{tile.postedByUsername}</Typography>
              <Typography
                variant="body2"
                align="right"
                style={{ alignSelf: "center" }}
              >
                {moment(tile.createdAt).format("DD-MM-YYYY")}
              </Typography>
            </div>
            <div
              style={{
                width: "100%",
                // padding: 6,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="subtitle1" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{tile.itemName}</Typography>
            </div>
          </CardContent>
        </CardActionArea>
        {/* <CardActions disableSpacing={true}>
        <Button size="small" color="primary">
          Bid
        </Button>
        <Button size="small" color="primary">
          Learn More
        </Button>
      </CardActions> */}
      </Card>
    </Grid>
  );

  const [openDialog, setOpenDialog] = useState(false)
  const ForceLoginDialog = () => {
    return (
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ textTransform: "capitalize", textAlign: "center" }}
        >
          <InfoOutlinedIcon
            fontSize="large"
            color="primary"
            style={{ marginRight: 12, verticalAlign: "middle" }}
          />
          <Typography display="inline">Login Required!! Please login to continue</Typography>
        </DialogTitle>

        <DialogActions>
          <Button onClick={() => {
            setOpenDialog(false)
            history.push('/login')
          }} color="primary" variant="contained">
            Login
          </Button>
          <Button onClick={() => {
            setOpenDialog(false)
          }} variant="contained">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const [openAddressDialog, setOpenAddressDialog] = useState(false)
  const ForceAddressDialog = () => {
    return (
      <Dialog
        open={openAddressDialog}
        onClose={() => setOpenAddressDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ textTransform: "capitalize", textAlign: "center" }}
        >
          <InfoOutlinedIcon
            fontSize="large"
            color="primary"
            style={{ marginRight: 12, verticalAlign: "middle" }}
          />
          <Typography display="inline">Address Required!! Please fill the address</Typography>
        </DialogTitle>

        <DialogActions>
          <Button onClick={() => {
            setOpenAddressDialog(false)
            history.push('/editprofile')
          }} color="primary" variant="contained" style={{textTransform: "none"}}>
            Redirect me to edit profile page
          </Button>
          <Button onClick={() => {
            setOpenAddressDialog(false)
          }} variant="contained">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
  const clearAllFilter = () => {
    setCategoryValue(0)
    setSubcategoryValue(0)
    setCountryValue(0)
    setStateValue(0)
    setDistrictValue(0)
    setMileageFrom(0);
    setMileageTo(0);
    setYearOfManufactureFrom(0);
    setYearOfManufactureTo(0);
    setmakeValue(0);
    setmodelValue(0);
    fetchAds();
  }
  useEffect(() => {
    if (isFromSearch) {
      clearAllFilter()
    }
  }, [isFromSearch])

  const [openNotification, setOpenNotification] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [mileageFrom, setMileageFrom] = useState(0)
  const [mileageTo, setMileageTo] = useState(0)
  const [yearOfManufactureFrom, setYearOfManufactureFrom] = useState(0)
  const [yearOfManufactureTo, setYearOfManufactureTo] = useState(0)
  const [priceFrom, setPriceFrom] = useState(0)
  const [priceTo, setPriceTo] = useState(0)
  const [ownerOrDealer, setOwnerOrDealer] = useState("Owner")

  const handelTextChange = (value, fn) => {
    fn(value)
  }
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [openFilterDialog, setOpenFilterDialog] = useState(false)

  const DialogTitle = ((props) => {
    const { children, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography {...other}>
        <Typography variant="h6">{children}</Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={() => {
          setOpenFilterDialog(false)
        }}>
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>
    );
  });

  const PopperMy = function (props) {
    return (
      <Popper {...props} style={{ width: 212 }} placement="bottom-start" />
    );
  };

  const FilterDialog = () => {
    return (
      <Dialog
        open={openFilterDialog}
        onClose={() => setOpenFilterDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="customized-dialog-title" onClick={() => {
          setOpenFilterDialog(false)
        }}>
          Filters
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={1}>
            {/* Category */}
            <>
              <Grid item xs={5}>
                <Typography
                  style={{ margin: 10 }}
                >
                  Category
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Autocomplete
                  options={!fetchingCategories ? categories : []}
                  getOptionLabel={(option) => option}
                  onChange={(e, object) => handleCategorySelect(object)}
                  value={categoryValue || "All"}
                  PopperComponent={PopperMy}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth required />
                  )}
                />

              </Grid>
            </>
            {/* Sub Category */}
            <>
              <Grid item xs={5}>
                <Typography
                  style={{ margin: 10 }}
                >
                  Subcategory
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Autocomplete
                  options={subCategories}
                  getOptionLabel={(option) => option}
                  onChange={(e, object) => handleSubCategorySelect(object)}
                  value={subcategoryValue || "All"}
                  PopperComponent={PopperMy}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth required />
                  )}
                />

              </Grid>
            </>
            {
              categoryValue === "Automobiles" || categoryValue === "Ships Boats" ? <>
                {/* Make */}
                <>
                  <Grid item xs={5}>
                    <Typography
                      style={{ margin: 10 }}
                    >
                      Manufacturer
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Autocomplete
                      options={manufacturerMakes}
                      getOptionLabel={(option) => option}
                      onChange={(e, object) => handleSelect(object, setmakeAnchorEl, setmakeValue, getManufacturerModel, object, true)}
                      value={makeValue || "All"}
                      PopperComponent={PopperMy}
                      renderInput={(params) => (
                        <TextField {...params} fullWidth required />
                      )}
                    />

                  </Grid>
                </>
                {/* Model */}
                <>
                  <Grid item xs={5}>
                    <Typography
                      style={{ margin: 10 }}
                    >
                      Model
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Autocomplete
                      options={manufacturerModels}
                      getOptionLabel={(option) => option}
                      onChange={(e, object) => handleSelect(object, setmodelAnchorEl, setmodelValue, getManufacturerYear, object)}
                      value={modelValue || "All"}
                      PopperComponent={PopperMy}
                      renderInput={(params) => (
                        <TextField {...params} fullWidth required />
                      )}
                    />

                  </Grid>
                </>
                {/* Mileage */}
                <>
                  <Grid item xs={5}>
                    <Typography
                      style={{ margin: 10 }}
                    >
                      Mileage
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <div style={{ display: "flex", alignItems: 'center' }}>
                      <TextField value={mileageFrom} onChange={e => handelTextChange(e.target.value, setMileageFrom)} variant="outlined" style={{ width: 80 }} type="text" size="small" placeholder="000" />
                      <span style={{ padding: 5 }}>from</span>
                      <TextField value={mileageTo} onChange={e => handelTextChange(e.target.value, setMileageTo)} variant="outlined" style={{ width: 80 }} type="text" size="small" placeholder="000" />
                    </div>

                  </Grid>
                </>
                {/* Manufacture Year */}
                <>
                  <Grid item xs={5}>
                    <Typography
                      style={{ margin: 10 }}
                    >
                      Manufacture Year
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <div style={{ display: "flex", alignItems: 'center' }}>
                      <FormControl size="small">
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={yearOfManufactureFrom}
                          variant="outlined"
                          onChange={e => handelTextChange(e.target.value, setYearOfManufactureFrom)}
                          size="small"
                        >
                          {
                            manufacturerYears.map((year, i) => (
                              <MenuItem value={year.year} key={i}>{year.year}</MenuItem>
                            ))
                          }
                        </Select>
                      </FormControl>
                      {/* <TextField value={yearOfManufactureFrom} onChange={e => handelTextChange(e.target.value, setYearOfManufactureFrom)} variant="outlined" style={{ width: 80 }} type="text" size="small" placeholder="000" /> */}
                      <span style={{ padding: 5 }}>from</span>
                      <FormControl size="small">
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={yearOfManufactureTo}
                          variant="outlined"
                          onChange={e => handelTextChange(e.target.value, setYearOfManufactureTo)}
                          size="small"
                        >
                          {
                            manufacturerYears.filter(year => yearOfManufactureFrom <= year.year).map((year, i) => (
                              <MenuItem value={year.year} key={i}>{year.year}</MenuItem>
                            ))
                          }
                        </Select>
                      </FormControl>
                      {/* <TextField value={yearOfManufactureTo} onChange={e => handelTextChange(e.target.value, setYearOfManufactureTo)} variant="outlined" style={{ width: 80 }} type="text" size="small" placeholder="000" /> */}
                    </div>

                  </Grid>
                </>
                {/* Owner or Dealer */}
                <>
                  <Grid item xs={5}>
                    <Typography
                      style={{ margin: 10 }}
                    >
                      Owner or Dealer
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <div style={{ display: "flex", alignItems: 'center' }}>
                      <FormControl size="small">
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={ownerOrDealer}
                          variant="outlined"
                          onChange={e => handelTextChange(e.target.value, setOwnerOrDealer)}
                          size="small"
                        >
                          <MenuItem value="Owner" >Owner</MenuItem>
                          <MenuItem value="Dealer" >Dealer</MenuItem>
                        </Select>
                      </FormControl>
                    </div>

                  </Grid>
                </>

              </> : null
            }
            {/* Country */}
            <>
              <Grid item xs={5}>
                <Typography
                  style={{ margin: 10 }}
                >
                  Country
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Autocomplete
                  options={countriesList}
                  getOptionLabel={(option) => option}
                  onChange={(e, object) => handleCountrySelect(object)}
                  value={countryValue || "All"}
                  PopperComponent={PopperMy}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth required />
                  )}
                />

              </Grid>
            </>
            {/* State */}
            {
              countryValue !== 0 && <>
                <Grid item xs={5}>
                  <Typography
                    style={{ margin: 10 }}
                  >
                    State
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Autocomplete
                    options={statesList}
                    getOptionLabel={(option) => option}
                    onChange={(e, object) => handleStateSelect(object)}
                    value={stateValue || "All"}
                    PopperComponent={PopperMy}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth required />
                    )}
                  />

                </Grid>
              </>
            }
            {/* District */}
            {
              countriesList?.length > 0 &&
              districtList?.length > 0 &&
              countryValue === "India" && <>
                <Grid item xs={5}>
                  <Typography
                    style={{ margin: 10 }}
                  >
                    District
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Autocomplete
                    options={districtList}
                    getOptionLabel={(option) => option}
                    onChange={(e, object) => handleDistrictSelect(object)}
                    value={districtValue || "All"}
                    PopperComponent={PopperMy}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth required />
                    )}
                  />

                </Grid>
              </>
            }
            {/* Neighbourhood */}
            {
              countriesList?.length > 0 &&
              districtList?.length > 0 &&
              countryValue === "India" && <>
                <Grid item xs={5}>
                  <Typography
                    style={{ margin: 10 }}
                  >
                    Neighbourhood
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Autocomplete
                    options={neighbourhoodList}
                    getOptionLabel={(option) => option}
                    onChange={(e, object) => handleNeighbourhoodSelect(object)}
                    value={neighbourhoodValue || "All"}
                    PopperComponent={PopperMy}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth required />
                    )}
                  />

                </Grid>
              </>
            }
            {/* price */}
            <>
                <Grid item xs={5}>
                  <Typography
                    style={{ margin: 10 }}
                  >
                    Price
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                <div style={{ display: "flex", alignItems: 'center' }}>
                  <TextField value={priceFrom} onChange={e => handelTextChange(e.target.value, setPriceFrom)} variant="outlined" style={{ width: 80 }} type="text" size="small" placeholder="000" />
                  <span style={{ padding: 5 }}>from</span>
                  <TextField value={priceTo} onChange={e => handelTextChange(e.target.value, setPriceTo)} variant="outlined" style={{ width: 80 }} type="text" size="small" placeholder="000" />
                </div>

                </Grid>
              </>


          </Grid>
          
        </DialogContent>

        <DialogActions>
          <Button onClick={() => {
            setOpenFilterDialog(false)
            fetchAds()
          }} color="primary" variant="contained">
            Apply Filter
          </Button>
          <Button
            variant="contained"
            color="secondary"
            disabled={!(categoryValue || countryValue)}
            onClick={() => {
              clearAllFilter()
            }}
          >Clear</Button>
          <Button onClick={() => {
            setOpenFilterDialog(false)
          }} variant="contained">
            Cancel
          </Button>

        </DialogActions>
      </Dialog >
    )
  }
  return (
    <div className={classes.root}>
      <div id="back-to-top-anchor" />
      {localStorage.getItem('jwt') && <Fab
        variant="extended"
        color="primary"
        aria-label="add"
        className={classes.margin}
        onClick={() => {
          // history.push("/postAd")
          if ((userDetails.country && userDetails.state && userDetails.city && userDetails.street)) {
            history.push("/postAd")
          } else {
            setOpenAddressDialog(true)
          }

        }}
        style={{textTransform: "none"}}
      >
        <AddIcon className={classes.extendedIcon} />
        Post new AD
      </Fab>}
      {openAddressDialog && ForceAddressDialog()}
      {openDialog && ForceLoginDialog()}
      {openFilterDialog && FilterDialog()}

      <Button
        variant="contained"
        color="primary"
        onClick={() => setOpenFilterDialog(true)}
        style={{ margin: 10 }}
      >Filter</Button>

      {categoryValue && categories.length ? (
        <Grid container spacing={2}>
          {/* {match.path !== "/myAds" && (
            <Grid item xs={12}>
              <ListSubheader component="div">
                {categories[categoryValue - 1].category} ads in your Area
              </ListSubheader>
            </Grid>
          )} */}
          {!fetchingAds && filteredAds.length ? filteredAds?.map((tile) => Ad(tile)) : <span style={{ textAlign: 'center', margin: '8px', width: '100%' }}>No Ads Available</span>}
        </Grid>
      ) : (
        <Grid container spacing={2} >
          {/* {match.path !== "/myAds" && (
            <Grid item xs={12}>
              <ListSubheader component="div">
                All ads in your area
              </ListSubheader>
            </Grid>
          )} */}

          {!fetchingAds && filteredAds.length ? filteredAds?.map((tile) => Ad(tile)) : <span style={{ textAlign: 'center', margin: '8px', width: '100%' }}>No Ads Available</span>}
        </Grid>
      )}
      <div style={{ textAlign: 'center', marginTop: 15, marginBottom: 30 }}>
        {!fetchingAds && filteredAds.length === page * 20 && <Button onClick={() => { setPage(page + 1) }} className={classes.loadMoreButton} variant="outlined" color="primary">Load More</Button>}
      </div>
      <ScrollTop>
        <Button variant="contained" style={{ borderRadius: 40 }} color="primary">Back To Top</Button>
      </ScrollTop>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    countriesList: state.registerReducer.countries
      ? state.registerReducer.countries.map((country) => country.country)
      : [],
    statesList: state.registerReducer.states || [],
    districtList: state.registerReducer.districts
      ? state.registerReducer.districts.map((district) => district.district)
      : [],
    categories: state.appReducer.categories.map((item) => item.category) || [],
    fetchingCategories: state.appReducer.fetchingCategories,
    subCategories: state.appReducer.subCategories.map((item) => item.subcategory) || [],
    fetchingSubCategories: state.appReducer.fetchingSubCategories,
    itemAds: state.itemAdsReducer.itemAdsByCategory || [],
    fetchingAds: state.itemAdsReducer.fetchingAds,
    userDetails: state.userDetailsReducer.userDetail,
    isFromSearch: state.itemAdsReducer.isFromSearch,
    manufacturerMakes: state.appReducer?.manufacturerMake?.map((item) => item.make) || [],
    manufacturerModels: state.appReducer?.manufacturerModel?.map((item) => item.model) || [],
    manufacturerYears: state.appReducer?.manuractureringYear || [],
    neighbourhoodList: state.registerReducer.neighbourhoods
      ? state.registerReducer.neighbourhoods.map((ele) => ele.name)
      : [],
  };
};

export default connect(mapStateToProps)(withRouter(ItemAdsContainer));
