import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Typography,
  FormLabel,
  Divider,
  Popper,
} from "@material-ui/core";
import {
  requestCountries,
  requestStates,
  requestDistricts,
  requestNeighbourhood,
  // requestIndustries,
} from "../../actions/registerActions";
import { openDialog } from "../../actions/appActions";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { connect } from "react-redux";
import withRouter from "react-router-dom/es/withRouter";
import { updateProfile } from "../../actions/appActions";
import { useHistory } from "react-router-dom";
import { constants } from "../../helpers/constants";
import moment from "moment";
import InfoPopover from "../InfoPopover";
// import { getRoles } from "../../actions/userDetailsAction";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const EditProfileForm = ({
  userDetail,
  roles,
  countriesList,
  statesList,
  districtsList,
  industriesList,
  dispatch,
  neighbourhoodList
}) => {
  const [username, setUsername] = useState(
    (userDetail && userDetail.username) || ""
  );
  const [neighbourhood, setNeighbourhood] = useState(
    (userDetail && userDetail.neighbourHood) || ""
  );
  const [firstname, setFirstname] = useState(
    (userDetail && userDetail.firstname) || ""
  );
  const [dob, setDob] = useState(
    (userDetail && userDetail.dob)?.split("T")[0] || ""
  );
  const [gender, setGender] = useState(
    (userDetail && userDetail.gender) || "Male"
  );
  console.log(dob)
  const [lastname, setLastname] = useState(
    (userDetail && userDetail.lastname) || ""
  );
  const [bidAlias, setBidAlias] = useState(
    (userDetail && userDetail.bidAlias) || ""
  );
  const [houseno, setHouseno] = useState(
    (userDetail && userDetail.houseno) || ""
  );
  const [street, setStreet] = useState((userDetail && userDetail.street) || "");
  const [city, setCity] = useState((userDetail && userDetail.city) || "");
  const [state, setState] = useState((userDetail && userDetail.state) || "");
  const [country, setCountry] = useState(
    (userDetail && userDetail.country) || ""
  );
  const [pincode, setPincode] = useState(
    (userDetail && userDetail.pincode) || ""
  );
  const [industry, setIndustry] = useState(
    (userDetail && userDetail.industry) || ""
  );
  const [district, setDistrict] = useState(
    (userDetail && userDetail.district) || ""
  );

  const [managerName, setManagerName] = useState(
    (userDetail && userDetail.managerName) || ""
  );
  const [managerMobile, setManagerMobile] = useState(
    (userDetail && userDetail.managerMobile) || ""
  );
  const [mainRole, setMailRole] = useState(
    (userDetail && userDetail.mainrole) || ""
  );
  const [subs, setSubs] = useState([]);

  const [weight, setWeight] = useState((userDetail && userDetail.weight) || "");
  const [feet, setFeet] = useState((userDetail && userDetail.heightfoot) || "");
  const [passport, setPassport] = useState(
    (userDetail && userDetail.passport) || false
  );
  const [inches, setInches] = useState(
    (userDetail && userDetail.heightinches) || ""
  );
  const [education, setEducation] = useState(
    (userDetail && userDetail.education) || ""
  );
  const [link1Title, setLink1Title] = useState(
    (userDetail && userDetail.link1_title) || ""
  );
  const [link1Url, setLink1Url] = useState(
    (userDetail && userDetail.link1_url) || ""
  );
  const [link2Title, setLink2Title] = useState(
    (userDetail && userDetail.link2_title) || ""
  );
  const [link2Url, setLink2Url] = useState(
    (userDetail && userDetail.link2_url) || ""
  );
  const [link3Title, setLink3Title] = useState(
    (userDetail && userDetail.link3_title) || ""
  );
  const [link3Url, setLink3Url] = useState(
    (userDetail && userDetail.link3_url) || ""
  );
  const [link4Title, setLink4Title] = useState(
    (userDetail && userDetail.link4_title) || ""
  );
  const [link4Url, setLink4Url] = useState(
    (userDetail && userDetail.link4_url) || ""
  );
  const [companyName, setCompanyName] = useState(
    (userDetail && userDetail.bnr) || ""
  );
  const [otherRoles, setOtherRole] = useState(
    (userDetail && userDetail.otherroles) || []
  );
  const [waist, setWaist] = useState((userDetail && userDetail.waist) || "");
  const [hips, setHips] = useState((userDetail && userDetail.hips) || "");
  const [chest, setChest] = useState((userDetail && userDetail.chest) || "");

  var history = useHistory();

  const getAllSubstrings = (str, size) => {
    var i,
      j,
      result = [];
    size = size || 0;
    for (i = 0; i < str.length; i++) {
      for (j = str.length; j - i >= size; j--) {
        result.push(str.slice(i, j));
      }
    }
    return result;
  };

  useEffect(() => {
    if (userDetail) {
      var arr = getAllSubstrings(userDetail && userDetail.username, 3);
      setSubs(arr);
    }
  }, [userDetail, username]);

  useEffect(() => {
    // dispatch(getRoles());
    dispatch(requestCountries());
    dispatch(requestStates(country));
    // dispatch(requestIndustries());
    dispatch(requestDistricts(country, state));
    dispatch(requestNeighbourhood(country, state, district || ""));
  }, [dispatch, country, state, district]);

  // const handleEdit = (params) => {
  //   return dispatch(updateProfile(params));
  // };

  const handleOnSubmit = () => {
    // if (bidAlias.length < 3) {
    //   return dispatch(openDialog("Mininum bid username length is 3"));
    // }
    if (new RegExp(subs.join("|")).test(bidAlias)) {
      return dispatch(
        openDialog(
          "Bid username can’t contain any 3 consecutive characters of the login user name."
        )
      );
    }
    const params = {
      // education,
      // mainrole: mainRole,
      // link1_title: link1Title,
      // link1_url: link1Url,
      // link2_title: link2Title,
      // link2_url: link2Url,
      // link3_title: link3Title,
      // link3_url: link3Url,
      // link4_title: link4Title,
      // link4_url: link4Url,
      // heightfoot: feet,
      // heightinches: inches,
      // weight,
      // managerMobile,
      // managerName,
      bnr: companyName,
      // otherroles: otherRoles.filter((or) => or !== mainRole),
      // waist,
      // hips,
      // chest,
      // passport,
      username,
      firstname,
      lastname,
      bidAlias,
      houseno,
      street,
      city,
      country,
      state,
      // industry,
      district,
      pincode,
      dob: moment(dob).format("DD-MM-YYYY"),
      gender,
      neighbourHood: neighbourhood
    };
    dispatch(updateProfile(params)).then((res) => {
      !res.error && history.push("/profile-me");
    });
  };

  const PopperMy = function (props) {
    return (
      <Popper {...props} style={{ width: 264 }} placement="bottom-start" />
    );
  };

  return (
    <div
      style={{
        height: "95vh",
        overflow: "scroll",
      }}
    >
      <Grid
        container
        style={{ marginTop: 10 }}
        justify="center"
        alignItems="center"
      >
        <Grid container xs={12} justify="space-evenly" alignItems="center">
          <Grid item xs={12} align="center">
            <Button
              size="small"
              variant="contained"
              style={{
                marginLeft: "90%",
                marginTop: "20px",
                textTransform: "none",
              }}
              onClick={(e) => history.goBack()}
              color="secondary"
            >
              Back
            </Button>
            <Typography
              component="h1"
              variant="h5"
              display="block"
              style={{
                margin: 10,
                alignItems: "center",
                textTransform: "none",
              }}
            >
              Edit Profile
            </Typography>
            <Divider light variant="middle" />
          </Grid>
          <Grid container xs={4} spacing={1}>

            {/* username */}
            {/* <Grid item xs={5}>
              <Typography
                style={{ margin: "8px", color: constants.PRIMARY_COLOR }}
              >
                User Name*
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <TextField
                required
                fullWidth
                // label="User Name"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Grid> */}


            <Grid item xs={5}>
              <Typography
                style={{ margin: "8px", color: constants.PRIMARY_COLOR }}
              >
                First Name*
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <TextField
                required
                fullWidth
                // label="First Name"
                value={firstname}
                onChange={(e) => setFirstname(e.target.value)}
              />
            </Grid>
            <Grid item xs={5}>
              <Typography
                style={{ margin: "8px", color: constants.PRIMARY_COLOR }}
              >
                Last Name*
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <TextField
                required
                fullWidth
                // label="Last Name"
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
              />
            </Grid>
            <Grid item xs={5}>
              <Typography
                style={{ margin: "8px", color: constants.PRIMARY_COLOR }}
              >
                DOB*
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <TextField
                required
                fullWidth
                // label="Last Name"
                type="date"
                value={dob}
                onChange={(e) => setDob(e.target.value)}
              />
            </Grid>

            <Grid item xs={5}>
              <Typography
                style={{ margin: "8px", color: constants.PRIMARY_COLOR }}
              >
                Gender*
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Autocomplete
                options={["Male", "Female"]}
                getOptionLabel={(option) => option}
                onChange={(e, object) => { setGender(object) }}
                value={gender}
                PopperComponent={PopperMy}
                renderInput={(params) => (
                  <TextField {...params} fullWidth required={true} />
                )}
              />
            </Grid>


            {/* Company Name */}
            {/* <Grid item xs={5}>
              <Typography
                style={{ margin: "8px", color: constants.PRIMARY_COLOR }}
              >
                Company Name
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <TextField
                required
                fullWidth
                // label="Company Name"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </Grid> */}

            {/* Bid Alias Name */}
            {/* <Grid item xs={5}>
              <Typography
                style={{ margin: "8px", color: constants.PRIMARY_COLOR }}
              >
                Bid Alias Name
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <TextField
                required
                fullWidth
                // label="Company Name"
                value={bidAlias}
                onChange={(e) => setBidAlias(e.target.value)}
              />
            </Grid> */}
          </Grid>

          <Grid container xs={4} spacing={1}>
            <Grid item xs={5}>
              <Typography
                style={{ margin: "8px", color: constants.PRIMARY_COLOR }}
              >
                House Number*
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <TextField
                required
                fullWidth
                // label="House Number"
                value={houseno}
                onChange={(e) => setHouseno(e.target.value)}
              />
            </Grid>
            <Grid item xs={5}>
              <Typography
                style={{ margin: "8px", color: constants.PRIMARY_COLOR }}
              >
                Street*
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <TextField
                required
                fullWidth
                // label="Street"
                value={street}
                onChange={(e) => setStreet(e.target.value)}
              />
            </Grid>
            <Grid item xs={5}>
              <Typography
                style={{ margin: "8px", color: constants.PRIMARY_COLOR, display: "flex" }}
              >
                Town/Village* <InfoPopover info="Enter a subdivision of neighbourhood, if nothing available, keep neighbourhood" />
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <TextField
                required
                fullWidth
                // label="City"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </Grid>
            <Grid item xs={5}>
              <Typography
                style={{ margin: "8px", color: constants.PRIMARY_COLOR }}
              >
                Country*
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Autocomplete
                options={countriesList}
                getOptionLabel={(option) => option}
                onChange={(e, object) => {
                  setCountry(object);
                  setState("");
                }}
                value={country}
                PopperComponent={PopperMy}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={country}
                    // label="Select Country"
                    fullWidth
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={5}>
              <Typography
                style={{ margin: "8px", color: constants.PRIMARY_COLOR }}
              >
                State*
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Autocomplete
                options={statesList}
                getOptionLabel={(option) => option}
                onChange={(e, object) => setState(object)}
                value={state}
                disabled={!Boolean(country || country !== "")}
                PopperComponent={PopperMy}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={state}
                    // label="Select Country"
                    fullWidth
                    required
                  />
                )}
              />
            </Grid>

            <Grid item xs={5}>
              <Typography
                style={{ margin: "8px", color: constants.PRIMARY_COLOR }}
              >
                District*
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Autocomplete
                options={districtsList}
                getOptionLabel={(option) => option}
                onChange={(e, object) => setDistrict(object)}
                disabled={country !== "India"}
                value={district}
                PopperComponent={PopperMy}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={district}
                    disabled={country !== "India"}
                    // label="Select Country"
                    fullWidth
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={5}>
              <Typography
                style={{ margin: "8px", color: constants.PRIMARY_COLOR }}
              >
                Neighbourhood*
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Autocomplete
                options={neighbourhoodList}
                getOptionLabel={(option) => option}
                onChange={(e, object) => setNeighbourhood(object)}
                value={neighbourhood}
                disabled={!Boolean(country || country !== "")}
                PopperComponent={PopperMy}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={neighbourhood}
                    // label="Select Country"
                    fullWidth
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={5}>
              <Typography
                style={{ margin: "8px", color: constants.PRIMARY_COLOR }}
              >
                Pincode*
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <TextField
                required
                fullWidth
                // label="Street"
                value={pincode}
                onChange={(e) =>
                  (e.target.value.length <= 6 || e.target.value === "") &&
                  setPincode(e.target.value)
                }
              />
            </Grid>
            {/* {country === "India" && (
              <>
                <Grid item xs={5}>
                  <Typography
                    style={{ margin: "8px", color: constants.PRIMARY_COLOR }}
                  >
                    Industry*
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Autocomplete
                    options={industriesList}
                    getOptionLabel={(option) => option}
                    onChange={(e, object) => setIndustry(object)}
                    disabled={country !== "India"}
                    value={industry}
                    PopperComponent={PopperMy}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        value={industry}
                        disabled={country !== "India"}
                        // label="Select Country"
                        fullWidth
                        required
                      />
                    )}
                  />
                </Grid>
              </>
            )} */}
            {/* 
            <Grid item xs={5}>
              <Typography
                style={{ margin: "8px", color: constants.PRIMARY_COLOR }}
              >
                Link1 Title
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <TextField
                fullWidth
                name="link1Title"
                // label="Link1 Title"
                value={link1Title}
                onChange={(e) => setLink1Title(e.target.value)}
              />
            </Grid>
            <Grid item xs={5}>
              <Typography
                style={{ margin: "8px", color: constants.PRIMARY_COLOR }}
              >
                Link1 URL*
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <TextField
                fullWidth
                name="link1Url"
                // label="Link1 URL"
                value={link1Url}
                onChange={(e) => setLink1Url(e.target.value)}
              />
            </Grid>
            <Grid item xs={5}>
              <Typography
                style={{ margin: "8px", color: constants.PRIMARY_COLOR }}
              >
                Link2 Title
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <TextField
                fullWidth
                name="link2Title"
                // label="Link2 Title"
                value={link2Title}
                onChange={(e) => setLink2Title(e.target.value)}
              />
            </Grid>
            <Grid item xs={5}>
              <Typography
                style={{ margin: "8px", color: constants.PRIMARY_COLOR }}
              >
                Link2 URL*
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <TextField
                fullWidth
                name="link2Url"
                // label="Link2 URL"
                value={link2Url}
                onChange={(e) => setLink2Url(e.target.value)}
              />
            </Grid>
            <Grid item xs={5}>
              <Typography
                style={{ margin: "8px", color: constants.PRIMARY_COLOR }}
              >
                Link3 Title
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <TextField
                fullWidth
                name="link3Title"
                // label="Link3 Title"
                value={link3Title}
                onChange={(e) => setLink3Title(e.target.value)}
              />
            </Grid>
            <Grid item xs={5}>
              <Typography
                style={{ margin: "8px", color: constants.PRIMARY_COLOR }}
              >
                Link3 URL*
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <TextField
                fullWidth
                name="link3Url"
                // label="Link3 URL"
                value={link3Url}
                onChange={(e) => setLink3Url(e.target.value)}
              />
            </Grid>
            <Grid item xs={5}>
              <Typography
                style={{ margin: "8px", color: constants.PRIMARY_COLOR }}
              >
                Link4 Title
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <TextField
                fullWidth
                name="link4Title"
                // label="Link4 Title"
                value={link4Title}
                onChange={(e) => setLink4Title(e.target.value)}
              />
            </Grid>
            <Grid item xs={5}>
              <Typography
                style={{ margin: "8px", color: constants.PRIMARY_COLOR }}
              >
                Link4 URL*
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <TextField
                fullWidth
                name="link4Url"
                // label="Link4 URL"
                value={link4Url}
                onChange={(e) => setLink4Url(e.target.value)}
              />
            </Grid> */}
          </Grid>
          <Grid item xs={12} align="center">
            <Divider light variant="middle" />

            <Button
              variant="contained"
              type="submit"
              size="large"
              style={{ margin: "16px", textTransform: "none" }}
              onClick={handleOnSubmit}
              color="primary"
            >
              Update
            </Button>
            <Button
              variant="contained"
              type="submit"
              size="large"
              style={{ margin: "16px", textTransform: "none" }}
              onClick={(e) => history.goBack()}
              color="secondary"
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = ({
  rolesReducer,
  userDetailsReducer,
  registerReducer,
}) => ({
  roles:
    (rolesReducer &&
      rolesReducer.roles &&
      rolesReducer.roles.map((obj) => obj.role)) ||
    [],
  userDetail: userDetailsReducer.userDetail,
  countriesList:
    (registerReducer &&
      registerReducer.countries &&
      registerReducer.countries.map((obj) => obj.country)) ||
    [],
  statesList: registerReducer.states,
  districtsList:
    (registerReducer &&
      registerReducer.districts &&
      registerReducer.districts.map((obj) => obj.district)) ||
    [],
  industriesList:
    (registerReducer &&
      registerReducer.industries &&
      registerReducer.industries.map((obj) => obj.industry)) ||
    [],
  neighbourhoodList: registerReducer.neighbourhoods
    ? registerReducer.neighbourhoods.map((ele) => ele.name)
    : [],
});

export default connect(mapStateToProps)(withRouter(EditProfileForm));
