import React, { useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import Year from "./YearView";
// import { useHistory } from "react-router-dom";

import { connect } from "react-redux";
import { getBidEvents } from "../../../actions/AdbidActions";

const localizer = momentLocalizer(moment);
localizer.formats.yearHeaderFormat = "YYYY";

const Calender = ({ calendarState, getBidEvents, history }) => {
  useEffect(() => {
    getBidEvents();
  }, [getBidEvents]);

  var events = calendarState.map((event) => {
    var obj = {
      title: event.amount.toString(),
      start: moment(event.slotdate).format(),
      end: moment(event.slotdate).format(),
      allday: true,
    };
    return obj;
  });
  // console.log(events);
  // let history = useHistory();

  const onSelectSlot = (slotInfo) => {
    moment().subtract("1", "d").isSameOrBefore(moment(slotInfo.start)) &&
      history.push("/adbid/" + moment(slotInfo.start).format());
  };
  return (
    <React.Fragment style={{ width: "100%" }}>
      <div
        className={"card postcd calendar"}
        style={{ width: "100%", display: "inline-block" }}
      >
        <Calendar
          localizer={localizer}
          events={events}
          step={60}
          showMultiDayTimes
          selectable
          defaultView={"year"}
          views={{
            year: Year,
            month: true,
            day: true,
            week: true,
            agenda: true,
          }}
          messages={{ year: "Year" }}
          min={new Date(Date.now())}
          onSelectSlot={(slotInfo) => onSelectSlot(slotInfo)}
          onSelectEvent={(slotInfo) => onSelectSlot(slotInfo)}
        />
      </div>
    </React.Fragment>
  );
  //   }
};

const mapStateToProps = (state) => {
  return {
    calendarState: state.AdbidReducer.events,
  };
};

export default connect(mapStateToProps, { getBidEvents })(Calender);
