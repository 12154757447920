import React from "react";
import BidForm from "./BidForm";
import { connect } from "react-redux";
import BidFeed from "./BidFeed";
import { Typography, Button, Paper } from "@material-ui/core";
import withRouter from "react-router-dom/es/withRouter";
// import Moment from "react-moment";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  paper: {
    width: "100%",
    margin: 50,
  },
  profileTitle: {
    textAlign: "center",
  },
});

const Bid = (props) => {
  const { classes, userDetail } = props;
  // const baseamt = 200000;
  return (
    <Paper className={classes.paper} elevation={0}>
      <Typography component="div" variant="h4" className={classes.profileTitle}>
        Bid
      </Typography>
      <Button
        size="small"
        variant="contained"
        style={{
          float: "right",
          textTransform: "none",
        }}
        onClick={(e) => props.history.push("/bid-calendar")}
        color="secondary"
      >
        Back
      </Button>
      <div>
        {userDetail && <BidForm
          id={props.match.params.id}
          classes={classes}
          userDetail={userDetail}
        />}
        <BidFeed id={props.match.params.id} classes={classes} />
      </div>
    </Paper>
  );
};

const mapStateToProps = (state) => {
  return {
    userDetail: state.userDetailsReducer.userDetail,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(withRouter(Bid)));
