import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { TextField, withStyles, Typography } from "@material-ui/core";
import withRouter from "react-router-dom/es/withRouter";
import InputAdornment from "@material-ui/core/InputAdornment";

import { getUsersByKeyword } from "../../actions/userDetailsAction";
import { constants } from "../../helpers/constants";
import { SearchRounded } from "@material-ui/icons";
import { getItemAdsBySearch } from "../../actions/itemAdActions";

const SearchResultField = ({ classes, dispatch, users, history, userId }) => {
  const [keyword, setKeyword] = useState("");
  // useEffect(() => {
  //   if (keyword.length >= 3) {
  //     const params = { key: keyword, page: 0, limit: 10 };
  //     dispatch(getUsersByKeyword(params));
  //   }
  // }, [keyword.length]);

  const handleOnType = (e) => {
    setKeyword(e.target.value);
  };

  const searchKeyword = () => {
    dispatch(getItemAdsBySearch({search: keyword, page: 1}))
  }


  useEffect(() => {
    console.log("users", users);
  }, [users]);

  return (
      <React.Fragment>
            <form onSubmit={(e)=>{
              e.preventDefault();
              searchKeyword()
            }}>
            <TextField
              style={{ color: "white" }}
              margin="normal"
              variant="outlined"
              placeholder="Search..."
              fullWidth
              inputValue={keyword}
              onChange={(e) => handleOnType(e)}
              InputProps={{
                type: "search",
                className: classes.autocompleteField,
                endAdornment: (
                  <InputAdornment position="end" style={{cursor: "pointer"}} onClick={()=>{
                    console.log("Clicked")
                    searchKeyword()
                  }}>
                    <SearchRounded />
                  </InputAdornment>
                ),
              }}
            />
            </form>
    </React.Fragment>
  );
};

const styles = {
  autocomplete: {
    width: "30%",
  },
  autocompleteField: {
    background: "white",
  },
};

const mapStateToProps = (state) => {
  return {
    users: state.usersReducer.users || [],
  };
};

export default connect(mapStateToProps)(
  withRouter(withStyles(styles)(SearchResultField))
);
