import axios from "axios";
import getURL from "../helpers/apis";
// import snackbar from "snackbar";
import request from "../helpers/requestEngine";
import request1 from "../helpers/request";
import { openDialog, closeDialog, logout } from "./appActions";

export const contactDetailsSubmit = (formData) => {
  return (dispatch) => {
    if (
      formData.firstName === "" ||
      formData.lastName === "" ||
      formData.email === "" ||
      formData.mobile === "" ||
      formData.subject === "" ||
      formData.description === "" ||
      formData.country === ""
    ) {
      dispatch(openDialog("Please enter all details."));
    } else {
      dispatch(sendContactDetails());
      return dispatch(sendingContactDetails(formData));
    }
  };
};

export const sendingContactDetails = (params) => {
  return (dispatch) =>
    request1("post", getURL("SEND_CONTACT"), false, params, null).then(
      (response) => {
        if (response.data.error) {
          // snackbar.show(
          //   response.data.msg || "Something went wrong. Please try again."
          // );
          dispatch(
            openDialog(
              response.data.msg || "Something went wrong. Please try again."
            )
          );
        } else {
          // snackbar.show("Signup Completed successfully.");
          dispatch(
            openDialog(
              "Details Sent successfully. You will hear from us shortly."
            )
          );
        }

        return response.data;
      },
      (err) => {
        // snackbar.show(
        //   err.response.data.msg || "Something went wrong. Please try again."
        // );
        dispatch(
          openDialog(
            err.response.data.msg || "Something went wrong. Please try again."
          )
        );
        return err.response.data;
      }
    );
};

export const sendContactDetails = () => {
  return {
    type: "SEND_CONTACT_DETAILS",
  };
};

export const onFormSubmit = (e, formData) => {
  return (dispatch) => {
    dispatch(loggingIn());
    return dispatch(authenticateLoginCredentials(formData));
  };
};

export const authenticateLoginCredentials = ({ username, password }) => {
  return (dispatch) => {
    axios
      .post(getURL("SIGN_IN"), {
        username,
        password,
      })
      .then(
        (response) => {
          if (response.data.data) {
            const {
              emailValidated,
              mobileValidated,
              education,
            } = response.data.userDetail;
            if (emailValidated && mobileValidated && education) {
              window.localStorage.setItem("jwt", response.data.data);
              window.localStorage.setItem(
                "userId",
                response.data.userDetail._id
              );
              window.localStorage.setItem(
                "username",
                response.data.userDetail.email
              );
              // snackbar.show("Login Successfully.");
              dispatch(setInitialLoginState());
              return dispatch(loginSuccessful(response.data));
            }
            window.localStorage.setItem("jwt", response.data.data);
            window.localStorage.setItem("userId", response.data.userDetail._id);
            window.localStorage.setItem(
              "username",
              response.data.userDetail.username
            );
            return dispatch(loginSuccessful(response.data));
          } else {
            throw new Error("token not received.");
          }
        },
        (err) => {
          if (
            err.response.data.errors &&
            err.response.data.errors.length &&
            err.response.data.errors[0].msg
          ) {
            // snackbar.show(err.response.data.errors[0].msg);
            dispatch(openDialog(err.response.data.errors[0].msg));

            return;
          }
          // snackbar.show("Something went wrong. Please try again.");
          dispatch(
            openDialog(
              err.response.data.data ||
                "Something went wrong. Please try again."
            )
          );

          dispatch(loginFailed(err));
        }
      );
  };
};

export const loggingIn = () => {
  return {
    type: "LOGIN_GOING",
  };
};

export const loginSuccessful = ({ data, userDetail }) => {
  return {
    type: "LOGIN_SUCCESS",
    jwt: data,
    userDetail,
  };
};

export const loginFailed = (err) => {
  return {
    type: "LOGIN_FAILED",
  };
};

export const onEmailChange = (e) => {
  return {
    type: "LOGIN_USERNAME_CHANGED",
    username: e.target.value,
  };
};

export const onPasswordChange = (e) => {
  return {
    type: "LOGIN_PASSWORD_CHANGED",
    password: e.target.value,
  };
};

export const onCheckBoxToggle = (e) => {
  //console.log('checkbox toggle');
  return {
    type: "LOGIN_CHECKBOX_TOGGLED",
  };
};

const setInitialLoginState = () => {
  return {
    type: "SET_INITIAL_LOGIN_STATE",
  };
};

export const forgotPassword = (email) => {
  return (dispatch) =>
    request("post", "FORGOT_PASSWORD", false, { email }).then(
      (response) => {
        if (response.data.error) {
          // snackbar.show(
          //   response.data.msg || "You don't have an account. Signup first."
          // );
          dispatch(
            openDialog(
              response.data.msg || "You don't have an account. Signup first."
            )
          );
        } else {
          // snackbar.show("We have sent you an email to reset your password.");
          dispatch(
            openDialog("We have sent you an email to reset your password.")
          );
        }
        return response.data;
      },
      (err) => {
        // snackbar.show(
        //   err.response.data.msg || "Something went wrong. Please try again."
        // );
        dispatch(
          openDialog(
            err.response.data.msg || "Something went wrong. Please try again."
          )
        );

        return err.response.data;
      }
    );
};

export const resetPassword = (formData, history) => {
  return (dispatch) => {
    if (formData.rePassword !== formData.password) {
      // snackbar.show("Password do not match. Enter the same password again.");
      dispatch(
        openDialog("Password do not match. Enter the same password again.")
      );
      return;
    }
    request("post", "RESET_PASSWORD", false, formData).then(
      (response) => {
        if (response.data.error) {
          dispatch(
            openDialog(
              response.data.msg || "Something went wrong. Please try again."
            )
          );
          
        } else {
          // snackbar.show("Password changed successfully.");
          dispatch(openDialog("Password changed successfully."));
          history.push(`/login`);
        }
        return response.data;
      },
      (err) => {
        // snackbar.show(
        //   err.response.data.msg || "Something went wrong. Please try again."
        // );
        dispatch(
          openDialog(
            err.response.data.msg || "Something went wrong. Please try again."
          )
        );

        return err.response.data;
      }
    );
  };
};

export const changePassword = (formData, history) => {
  return (dispatch) => {
    if (!formData.oldPassword || !formData.password || !formData.rePassword) {
      // snackbar.show("Password do not match. Enter the same password again.");
      return dispatch(openDialog("Please enter all fields."));
    }
    if (formData.rePassword !== formData.password) {
      // snackbar.show("Password do not match. Enter the same password again.");
      return dispatch(
        openDialog("Password do not match. Enter the same password again.")
      );
    }
    return request("post", "CHANGE_PASSWORD", true, formData).then(
      (response) => {
        if (response.data.error) {
          // snackbar.show(
          //   response.data.msg || "Something went wrong. Please try again."
          // );
          dispatch(
            openDialog(
              response.data.msg || "Something went wrong. Please try again."
            )
          );
        } else {
          // snackbar.show("Password changed successfully.");
          history.push("/");
          dispatch(logout());
          dispatch(
            openDialog(
              "Password changed successfully. Please login with the new password."
            )
          );
        }
        return response.data;
      },
      (err) => {
        // snackbar.show(
        //   err.response.data.msg || "Something went wrong. Please try again."
        // );
        dispatch(
          openDialog(
            err.response.data.msg || "Something went wrong. Please try again."
          )
        );
        return err.response.data;
      }
    );
  };
};

export const verifyEmail = (formData) => {
  return (dispatch) => {
    return request("post", "VERIFY_EMAIL", false, formData).then(
      (response) => {
        if (response.data.error) {
          // snackbar.show(
          //   response.data.msg || "Something went wrong. Please try again."
          // );
          dispatch(
            openDialog(
              response.data.msg || "Something went wrong. Please try again."
            )
          );
        } else {
          // snackbar.show("Email verified successfully.");
          dispatch(openDialog("Email verified successfully."));
        }
        return response.data;
      },
      (err) => {
        // snackbar.show(
        //   err.response.data.msg || "Something went wrong. Please try again."
        // );
        dispatch(
          openDialog(
            err.response.data.msg || "Something went wrong. Please try again."
          )
        );
        return err.response.data;
      }
    );
  };
};

export const emailValidate = (id, token) => {
  return (dispatch) => {
    return request1(
      "get",
      `${getURL("EMAIL_VALIDATE")}?user_id=${id}`,
      false,
      null,
      null,
      { Authorization: token }
    ).then(
      (response) => {
        if (response.data.error) {
          // snackbar.show(
          //   response.data.msg || "Something went wrong. Please try again."
          // );
          dispatch(
            openDialog(
              response.data.msg || "Something went wrong. Please try again."
            )
          );
        } else if (!response.data.data.emailValidated) {
          // snackbar.show("Your email is not validated.");
          dispatch(openDialog("Your email is not validated."));
        }
        // snackbar.show("Email verified successfully.");
        dispatch(openDialog("Email verified successfully."));

        return response.data;
      },
      (err) => {
        // snackbar.show(
        //   err.response.data.msg || "Something went wrong. Please try again."
        // );
        dispatch(
          openDialog(
            err.response.data.msg || "Something went wrong. Please try again."
          )
        );
        return err.response.data;
      }
    );
  };
};

export const otpPhone = (formData) => {
  return (dispatch) =>
    request("post", "OTP_PHONE", false, formData).then(
      (response) => {
        if (response.data.error) {
          // snackbar.show(
          //   response.data.msg || "Something went wrong. Please try again."
          // );
          dispatch(
            openDialog(
              response.data.msg || "Something went wrong. Please try again."
            )
          );
        } else {
          // snackbar.show("Otp sent successfully.");
          if(response.data?.errors[0]?.message==="You reached daily limit") { 
            dispatch(openDialog("You reached daily limit, Try after 24 Hours."));

          } else {

            dispatch(openDialog("Otp sent successfully."));
          }
        }
        return response.data;
      },
      (err) => {
        // snackbar.show(
        //   err.response.data.msg || "Something went wrong. Please try again."
        // );
        dispatch(
          openDialog(
            err.response.data.msg || "Something went wrong. Please try again."
          )
        );
        return err.response.data;
      }
    );
};

export const resendOTP = (params, token) => {
  return (dispatch) => {
    return request1("post", getURL("RESEND_OTP"), false, params, null, {
      Authorization: token,
    }).then(
      (response) => {
        if (response.data.error) {
          // snackbar.show(
          //   response.data.msg || "Something went wrong. Please try again."
          // );
          dispatch(
            openDialog(
              response.data.msg || "Something went wrong. Please try again."
            )
          );
        }
        if (response.data.errors.length > 0) {
          // snackbar.show(
          //   response.data.msg || "Something went wrong. Please try again."
          // );
          dispatch(
            openDialog(
              response.data.msg || "Something went wrong. Please try again."
            )
          );
        } else {
          // snackbar.show("OTP sent successfully.");
          dispatch(openDialog("Otp sent successfully."));
        }

        return response.data;
      },
      (err) => {
        // snackbar.show(
        //   err.response.data.msg || "Something went wrong. Please try again."
        // );
        dispatch(
          openDialog(
            err.response.data.msg || "Something went wrong. Please try again."
          )
        );
        return err.response.data;
      }
    );
  };
};

export const resendEmail = (params, token) => {
  return (dispatch) => {
    return request1("post", getURL("RESEND_EMAIL"), false, params, null, {
      Authorization: token,
    }).then(
      (response) => {
        if (response.data.error) {
          // snackbar.show(
          //   response.data.msg || "Something went wrong. Please try again."
          // );
          dispatch(
            openDialog(
              response.data.msg || "Something went wrong. Please try again."
            )
          );
        } else {
          // snackbar.show("Email sent successfully.");
          dispatch(openDialog("Email sent successfully."));
        }

        return response.data;
      },
      (err) => {
        // snackbar.show(
        //   err.response.data.msg || "Something went wrong. Please try again."
        // );
        dispatch(
          openDialog(
            err.response.data.msg || "Something went wrong. Please try again."
          )
        );
        return err.response.data;
      }
    );
  };
};

export const otpPhoneVerify = (formData) => {
  return (dispatch) => {
    return request("post", "OTP_PHONE_VERIFY", false, formData).then(
      (response) => {
        if (response.data.error) {
          // snackbar.show(
          //   response.data.msg || "Something went wrong. Please try again."
          // );
          if(response.data.errors.length>0){
            dispatch(
              openDialog(
                response.data.errors[0].message || "Something went wrong. Please try again."
              )
            );
          } else {
            dispatch(
              openDialog(
                response.data.msg || "Something went wrong. Please try again.111"
              )
            );
          }
        } else {
          // snackbar.show("Phone verified successfully.");
          dispatch(openDialog("Phone verified successfully."));
        }
        return response.data;
      },
      (err) => {
        // snackbar.show(
        //   err.response.data.msg || "Something went wrong. Please try again."
        // );
        // console.log(err)
        dispatch(
          openDialog(
            err.response.data.msg || "Incorrect OTP."
          )
        );
        return err.response.data;
      }
    );
  };
};

export const completeSignUp = (params, token) => {
  return (dispatch) =>
    request1("post", getURL("COMPLETE_SIGNUP"), false, params, null, {
      Authorization: token,
    }).then(
      (response) => {
        if (response.data.error) {
          // snackbar.show(
          //   response.data.msg || "Something went wrong. Please try again."
          // );
          dispatch(
            openDialog(
              response.data.msg || "Something went wrong. Please try again."
            )
          );
        } else {
          const data = { data: token, userDetail: response.data.data };
          window.localStorage.setItem("jwt", token);
          // snackbar.show("Signup Completed successfully.");
          dispatch(openDialog("Signup Completed successfully."));
          return dispatch(loginSuccessful(data));
        }

        return response.data;
      },
      (err) => {
        // snackbar.show(
        //   err.response.data.msg || "Something went wrong. Please try again."
        // );
        dispatch(
          openDialog(
            err.response.data.msg || "Something went wrong. Please try again."
          )
        );
        return err.response.data;
      }
    );
};
