import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getBids } from "../../actions/AdbidActions";
import {
  Typography,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import { constants } from "../../helpers/constants";
import Moment from "react-moment";

const BidFeed = ({ getBids, bids, id, classes }) => {
  const baseamt = 200000;
  // console.log(id);
  const userId = localStorage.getItem("userId");
  useEffect(() => {
    // console.log(id);
    getBids(id);
  }, [getBids, id]);
  return (
    <Box style={{ margin: 24 }}>
      <Paper style={{ margin: 32 }} elevation={0}>
        <Typography variant="h5">
          Slot Date: <Moment>{id}</Moment>
        </Typography>
        <Typography variant="h5">Base Amount: ₹ {baseamt}</Typography>
        <Typography variant="h5" style={{ color: "red" }}>
          {bids &&
            bids.length > 0 &&
            bids.map(
              (bid, key) =>
                key === 0 &&
                "Highest Open Bid Amount: ₹ " +
                  Number(bid.bidamt).toLocaleString("en-IN")
            )}
        </Typography>
      </Paper>
      <Paper style={{ margin: 12 }} elevation={0}>
        {/* <Typography variant="h4">Previous Bids</Typography> */}
        <TableContainer>
          <Table style={{ width: "100%" }} size="small">
            <TableBody>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Company</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Bid Amount</TableCell>
              </TableRow>
              {bids &&
                bids.map((bid, key) => (
                  <TableRow key={key}>
                    <TableCell>
                      <Moment format="YYYY-MM-DD HH:mm:ss">
                        {bid.biddate}
                      </Moment>
                    </TableCell>
                    <TableCell>{bid.company}</TableCell>
                    {bid.type.toLowerCase() === "hidden" ? (
                      <TableCell style={{ color: "blue" }}>
                        {bid.type}
                      </TableCell>
                    ) : (
                      <TableCell>{bid.type}</TableCell>
                    )}
                    {key === 0 ? (
                      <TableCell style={{ color: "red" }}>
                        {bid.bidamt}
                      </TableCell>
                    ) : bid.type.toLowerCase() === "hidden" ? (
                      <TableCell style={{ color: "blue" }}>
                        {bid.bidamt}
                      </TableCell>
                    ) : (
                      <TableCell>{bid.bidamt}</TableCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  bids: state.AdbidReducer.bids,
});

export default connect(mapStateToProps, { getBids })(BidFeed);
