import React from "react";

import PropTypes from "prop-types";

import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";

const headCells = [
  { id: "itemname", numeric: false, disablePadding: false, label: "Item Name" },
  {
    id: "totalPriceAfterTax",
    numeric: true,
    disablePadding: false,
    label: "Total Price After Tax(₹)",
  },
  {
    id: "taxCGSTAmount",
    numeric: true,
    disablePadding: false,
    label: "CGST(₹)",
  },
  {
    id: "taxSGSTAmount",
    numeric: true,
    disablePadding: false,
    label: "SGST(₹)",
  },
  { id: "date", numeric: true, disablePadding: false, label: "Date" },
];

function UserProfileListViewTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {props.type !== "bid" && index === 0
                ? "Item Price(₹)"
                : headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

UserProfileListViewTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default UserProfileListViewTableHead;
