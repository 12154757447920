import request from "../helpers/requestEngine";
import { sendHTTPRequest } from "../helpers/requestEngine";
// // import snackbar from "snackbar";
import getUrl from "../helpers/apis";
import { openDialog, closeDialog } from "./appActions";

export const updateItemAd = (formData, history) => {
  return (dispatch) => {
    request("post", "UPDATE_ITEM_AD", true, formData).then(
      (response) => {
        dispatch(updateItemAdSuccess(response.data.data));
        dispatch(openDialog("Ad Updated!"));
        history.push(`/myAds`);
      },
      (err) => {
        dispatch(
          openDialog(
            err.response.data.data || "Something went wrong. Please try again."
          )
        );

        dispatch(updateItemAdFailed(err));
      }
    );
  };
};

function updateItemAdSuccess() {
  return {
    type: "UPDATE_ITEM_AD_SUCCESS",
  };
}

function updateItemAdFailed(err) {
  return {
    type: "UPDATE_ITEM_AD_FAILED",
  };
}

export const postItemAd = (formData, history) => {
  return (dispatch) => {
    request("post", "POST_ITEM_AD", true, formData, {}, { "Content-Type": "multipart/form-data" }).then(
      (response) => {
        dispatch(postItemAdSuccess(response.data.data));
        dispatch(openDialog("Ad posted successfully."));
        // history.push(`/postAd/media/${response.data.data.ad?._id}`);
        history.push("/myAds")
      },
      (err) => {
        dispatch(
          openDialog(
            err.response.data.data || "Something went wrong. Please try again."
          )
        );

        dispatch(postItemAdFailed(err));
      }
    );
  };
};

function postItemAdSuccess() {
  return {
    type: "POST_ITEM_AD_SUCCESS",
  };
}

function postItemAdFailed(err) {
  return {
    type: "POST_ITEM_AD_FAILED",
  };
}

export const getAdGalleryDetailsById = (params) => {
  return (dispatch) => {
    dispatch(fetchingAdGalleryDetailsById());

    return dispatch(fetchAdGalleryDetailsById(params));
  };
};

function fetchingAdGalleryDetailsById() {
  return {
    type: "GET_AD_GALLERIES_BY_ID",
  };
}

function fetchAdGalleryDetailsById(params) {
  return (dispatch) => {
    request("get", "GET_AD_GALLERIES_BY_ID", true, null, params).then(
      (response) => {
        console.log("response==========", response.data.data);
        dispatch(getAdGalleryDetailsByIdSuccess(response.data.data));
      },
      (err) => {
        dispatch(
          openDialog(
            err.response.data.data || "Something went wrong. Please try again."
          )
        );

        dispatch(getAdGalleryDetailsByIdFailed(err));
      }
    );
  };
}

function getAdGalleryDetailsByIdSuccess(data) {
  return {
    type: "GET_AD_GALLERIES_BY_ID_SUCCESS",
    payload: data,
  };
}

function getAdGalleryDetailsByIdFailed(err) {
  return {
    type: "GET_AD_GALLERIES_BY_ID_FAILED",
  };
}

export const clearAds = () => {
  return {
    type: "CLEAR_ITEM_ADS",
  };
};

export const getAdById = (params) => {
  return (dispatch) => {
    dispatch(fetchingAdById());

    return dispatch(fetchAdById(params));
  };
};

function fetchingAdById() {
  return {
    type: "GET_AD_BY_ID",
  };
}

function fetchAdById(id) {
  return (dispatch) => {
    let path = "GET_AD_BY_ID"
    let params = {
      item_id: id
    }
    if(id?.includes('-')){
      path = "GET_AD_BY_UID";
      params = {
        item_uid: id
      }
    }
    request("get", path, true, null, params).then(
      (response) => {
        console.log("response==========", response.data.data);
        dispatch(getAdGalleryDetailsById({ item_id: response.data.data?._id }));
        dispatch(getAdByIdSuccess(response.data.data));
      },
      (err) => {
        dispatch(
          openDialog(
            err.response.data.data || "Something went wrong. Please try again."
          )
        );

        dispatch(getAdByIdFailed(err));
      }
    );
  };
}

function getAdByIdSuccess(data) {
  return {
    type: "GET_AD_BY_ID_SUCCESS",
    payload: data,
  };
}

function getAdByIdFailed(err) {
  return {
    type: "GET_AD_BY_ID_FAILED",
  };
}

export const getMyAdsByCategory = (params) => {
  return (dispatch) => {
    params.page===1 && dispatch(fetchingMyAdsByCategory());

    return dispatch(fetchMyAdsByCategory(params));
  };
};

function fetchingMyAdsByCategory() {
  return {
    type: "GET_ITEM_ADS_BY_CATEGORY",
  };
}

function fetchMyAdsByCategory(params) {
  return (dispatch) => {
    request("get", "GET_MY_ADS_BY_CATEGORY", true, null, params).then(
      (response) => {
        console.log("response==========", response.data.data);
        dispatch(getMyAdsByCategorySuccess(response.data.data, params.page));
      },
      (err) => {
        dispatch(
          openDialog(
            err.response.data.data || "Something went wrong. Please try again."
          )
        );

        dispatch(getMyAdsByCategoryFailed(err));
      }
    );
  };
}

function getMyAdsByCategorySuccess(data, page) {
  return {
    type: "GET_ITEM_ADS_BY_CATEGORY_SUCCESS",
    payload: {data: data, page: page},
  };
}

function getMyAdsByCategoryFailed(err) {
  return {
    type: "GET_ITEM_ADS_BY_CATEGORY_FAILED",
  };
}

export const getItemAdsByCategory = (params) => {
  return (dispatch) => {
    params.page===1 && dispatch(fetchingItemAdsByCategory());

    return dispatch(fetchItemAdsByCategory(params));
  };
};

function fetchingItemAdsByCategory() {
  return {
    type: "GET_ITEM_ADS_BY_CATEGORY",
  };
}

function fetchItemAdsByCategory(params) {
  return (dispatch) => {
    const api = localStorage.getItem('jwt')? "GET_ITEM_ADS_BY_CATEGORY" : "GET_GLOBAL_ITEM_ADS_BY_CATEGORY"
    request("get", api, true, null, params).then(
      (response) => {
        console.log("response==========", response.data.data);
        dispatch(getItemAdsByCategorySuccess(response.data.data, params.page));
      },
      (err) => {
        dispatch(
          openDialog(
            err.response.data.data || "Something went wrong. Please try again."
          )
        );

        dispatch(getItemAdsByCategoryFailed(err));
      }
    );
  };
}

function getItemAdsByCategorySuccess(data, page) {
  return {
    type: "GET_ITEM_ADS_BY_CATEGORY_SUCCESS",
    payload: {data: data, page: page},
  };
}

function getItemAdsByCategoryFailed(err) {
  return {
    type: "GET_ITEM_ADS_BY_CATEGORY_FAILED",
  };
}

export const getItemAdsBySearch = (params) => {
  return (dispatch) => {
    console.log("Here.......")
    params.page===1 && dispatch(fetchingItemAdsByCategory());

    return dispatch(fetchItemAdsBySearch(params));
  };
};

function fetchItemAdsBySearch(params) {
  return (dispatch) => {
    const api = "GET_AD_BY_SEARCH"
    request("get", api, true, null, params).then(
      (response) => {
        console.log("response==========", response.data.data);
        dispatch(getItemAdsBySearchSuccess(response.data.data, params.page, true));
      },
      (err) => {
        dispatch(
          openDialog(
            err.response.data.data || "Something went wrong. Please try again."
          )
        );

        dispatch(getItemAdsBySearchFailed(err));
      }
    );
  };
}

function getItemAdsBySearchSuccess(data, page, isFromSearch=false) {
  return {
    type: "GET_ITEM_ADS_BY_CATEGORY_SUCCESS",
    payload: {data: data, page: page, isFromSearch: isFromSearch},
  };
}

function getItemAdsBySearchFailed(err) {
  return {
    type: "GET_ITEM_ADS_BY_CATEGORY_FAILED",
  };
}