import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Paper, Typography, Divider, Button, IconButton } from "@material-ui/core";
import {
  getAdById,
  getAdGalleryDetailsById,
} from "../../actions/itemAdActions";
import Bid from "../Bid/Bid";
import AdDetails from "./AdDetails";
import ItemMediaCarousel from "./ItemMediaCarousel";
import { constants } from "../../helpers/constants";
import PublicShare from "../UserProfile/PublicShare";
import { ShareRounded } from "@material-ui/icons";

const styles = (theme) => ({
  mainContent: {
    marginTop: 10,
  },
  paper: {
    fontSize: 14,
    width: "100%",
    minWidth: 275,
    margin: 10,
    maxWidth: 1200,
  },
  avatar: {
    fontSize: 100,
    margin: "auto",
    marginLeft: "8px",
    width: 120,
    height: 120,
  },
  columnContainer: {
    padding: 5,
  },
  fieldGridLabel: {
    // width: "30%",
    fontSize: 18,
    margin: 5,
  },
  fieldGridValue: {
    // width: "68%",
    fontSize: 18,
    margin: 5,
  },
  fieldLabel: {
    fontSize: 14,
    // fontWeight: 600,
  },
  fieldValue: {
    fontSize: 14,
  },
  actionsRow: {},
  actionContainer: {},
  actionElement: {
    fontSize: 16,
    fontWeight: 600,
    textAlign: "center",
    cursor: "pointer",
  },
  actionButton: {
    marginLeft: 10,
    marginTop: 10,
    paddingLeft: 30,
    paddingRight: 30,
    textTransform: "capitalize",
    fontSize: 14,
    height: 30,
    paddingTop: 4,
  },
  profilePicChange: {
    top: 140,
    width: 120,
    height: 56,
    zIndex: 1000,
    marginLeft: "8px",
    position: "absolute",
    backgroundColor: "#00000070",
    borderBottomLeftRadius: 100,
    borderBottomRightRadius: 100,
    textAlign: "center",
    cursor: "pointer",
  },
  profileCamera: {
    position: "relative",
    marginTop: 6,
    fontSize: 24,
    color: "#ffffff",
  },
  icon: {
    cursor: "pointer",
    fontSize: 32,
    // marginLeft: 8,
    // marginRight: 8,
  },
  actionCount: {
    fontSize: 16,
    marginLeft: 25,
    marginRight: 55,
  },
  profileTitle: {
    textAlign: "center",
    fontSize: 24,
    fontWeight: 500,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  tabPaper: {
    flexGrow: 1,
    borderTop: 0,
    // overflowY: "scroll",
    // height: "100vh",
  },
  tabIndicator: {
    top: "0px",
    height: "2px",
    backgroundColor: "#3f51b5",
  },
});

const ItemAd = ({
  dispatch,
  itemAd,
  fetchingCurrentAd,
  match,
  classes,
  adGalleries,
  fetchingAdGalleries,
  history,
  userDetail,
}) => {
  useEffect(() => {
    dispatch(getAdById(match.params.ad_id));
    // dispatch(getAdGalleryDetailsById({ item_uid: match.params.ad_id }));
  }, [dispatch, match.params.ad_id]);
  const [openShareDialog, setOpenShareDialog] = useState(false)

  return (
    <Grid container style={{ marginTop: 30 }} justify="center">
      {!fetchingCurrentAd && itemAd && (
        <>
          <Grid container justify="center" alignItems="center">
            <Grid item xs={9}>
              <Typography variant="h4" style={{ marginLeft: 48, marginTop: 6 }}>
                {itemAd.itemName}
              </Typography>
            </Grid>
            <Grid item xs={1} style={{textAlign: "right"}}>
              {/* <Button
                onClick={() => setOpenShareDialog(true)}
                size="small"
                variant="outlined"
                style={{
                  float: "right",
                  textTransform: "none",
                  marginRight: 12,
                }}
              >
                Share
              </Button> */}
              <IconButton>
                <ShareRounded onClick={() => setOpenShareDialog(true)}/>
              </IconButton>
            </Grid>
            <PublicShare profileUrl={`${window.location.origin}/ads/${itemAd._id}`} open={openShareDialog} handleClose={()=>{setOpenShareDialog(false)}}/>
            {userDetail?._id == itemAd.postedBy && (
              <>
                <Grid item xs={1} justify="center" alignItems="center">
                  <Button
                    onClick={() => history.push(`/editAd/${itemAd.uid}`)}
                    size="small"
                    variant="outlined"
                    style={{
                      float: "right",
                      textTransform: "none",
                      marginRight: 12,
                    }}
                  >
                    Edit
                  </Button>
                </Grid>
              </>
            )}
            <Grid xs={1} justify="center" alignItems="center">
              <Button
                variant="contained"
                color="secondary"
                size="small"
                style={{
                  float: "right",
                  textTransform: "none",
                  marginRight: 12,
                }}
                onClick={() => {
                  if (history.action !== 'POP'){
                    history.goBack()
                  } else {
                    history.push('/')
                  }
                }}
              >
                Back
              </Button>
            </Grid>

            <Grid item xs={5}>
              <Paper elevation={0} style={{ margin: 6 }}>
                <AdDetails itemAd={itemAd} classes={classes} userDetail={userDetail} />
              </Paper>
            </Grid>
            <Grid item xs={7}>
              <Paper
                style={{
                  marginLeft: 48,
                  marginTop: 6,
                  marginBottom: 6,
                  height: "fit-content",
                  width: "fit-content",
                  maxHeight: "87%",
                  maxWidth: "87%",
                }}
              >
                {!fetchingAdGalleries && adGalleries && (
                  <ItemMediaCarousel galleries={adGalleries} />
                )}
              </Paper>
            </Grid>
          </Grid>
          <Grid container justify="center" style={{ minWidth: "100%" }}>
            <Paper
              elevation={0}
              style={{ margin: 6, minWidth: "100%", minHeight: "75px" }}
            >
              <Grid
                container
                xs={10}
                justify="space-around"
                alignItems="flex-start"
              >
                <Grid item xs={2} className={classes.fieldGridLabel}>
                  <Typography
                    variant="span"
                    style={{ color: constants.PRIMARY_COLOR }}
                    noWrap={true}
                    variant="h6"
                  >
                    Price
                  </Typography>
                </Grid>
                <Grid item xs={8} className={classes.fieldGridValue}>
                  <Typography variant="span" variant="h6">
                    {itemAd.currencycode || '₹'} {Number(itemAd.price).toLocaleString("en-IN")}
                  </Typography>
                </Grid>
                <Grid item xs={2} className={classes.fieldGridLabel}>
                  <Typography
                    variant="h6"
                    style={{ color: constants.PRIMARY_COLOR }}
                    noWrap={true}
                  >
                    Description
                  </Typography>
                </Grid>
                <Grid item xs={8} className={classes.fieldGridValue}>
                  <div style={{maxHeight: 150, overflow: "auto"}}>
                  <Typography variant="h6" style={{whiteSpace: "pre-line"}}>{itemAd.description}</Typography>
                  </div>
                </Grid>
              </Grid>
            </Paper>
            <Divider style={{ width: "90%" }} />
          </Grid>
          <Grid container>
            <Bid itemAd={itemAd} />
          </Grid>
        </>
      )}
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    itemAd: state.itemAdsReducer.currentAd,
    fetchingCurrentAd: state.itemAdsReducer.fetchingCurrentAd,
    adGalleries: state.itemAdsReducer.adGalleries,
    fetchingAdGalleries: state.itemAdsReducer.fetchingAdGalleries,
    userDetail: state.userDetailsReducer.userDetail,
  };
};

export default connect(mapStateToProps)(withRouter(withStyles(styles)(ItemAd)));
