import request from "../helpers/requestEngine";
import request1 from "../helpers/request";
import getURL from "../helpers/apis";
import { openDialog, closeDialog } from "./appActions";

export const addBid = (formData, history, baseamt) => {
  return (dispatch) => {
    if (
      formData.type === "" ||
      formData.type === "Select Type" ||
      formData.bidamt === ""
    ) {
      dispatch(openDialog("Enter all bid details"));
    } else if (Number(formData.bidamt) <= Number(baseamt)) {
      dispatch(openDialog("Ad Bid Amount has to be higher than Base Amount"));
    } else {
      request("post", "POST_ADBID", true, formData).then(
        (response) => {
          dispatch(postBidSuccess(response.data.data));
          // history.goBack();
          dispatch(openDialog("Ad Bid Posted!"));
          const { slotdate } = formData;
          dispatch(getBids(slotdate));
          dispatch(clearEvents());
        },
        (err) => {
          dispatch(
            openDialog(
              err.response.data.data ||
                "Something went wrong. Please try again."
            )
          );

          dispatch(postBidFailed(err));
          return err;
        }
      );
    }
    // const response = await request("post", "POST_BID", true, formData);
    // if (response.data) window.alert("Success");
    // const { slotdate } = formData;
    // const bids =  request1(
    //   "get",
    //   `${getURL("GET_BIDS")}${slotdate}`,
    //   false
    // );
  };
};

function postBidSuccess() {
  return {
    type: "POST_ADBID_SUCCESS",
  };
}

function postBidFailed(err) {
  return {
    type: "POST_ADBID_FAILED",
  };
}

export const getBids = (params) => {
  return (dispatch) => {
    dispatch(fetchingBids());

    return dispatch(fetchBids(params));
  };
};

function fetchingBids() {
  return {
    type: "GET_ADBIDS",
  };
}

function fetchBids(slotdate) {
  return (dispatch) => {
    request1("get", `${getURL("GET_ADBIDS")}${slotdate}`, true).then(
      (response) => {
        dispatch(getBidsSuccess(response.data.data));
        dispatch(clearEvents());
      },
      (err) => {
        dispatch(
          openDialog(
            err.response.data.data || "Something went wrong. Please try again."
          )
        );

        dispatch(getBidsFailed(err));
      }
    );
  };
}

function getBidsSuccess(data) {
  return {
    type: "GET_ADBIDS_SUCCESS",
    payload: data,
  };
}

function getBidsFailed(err) {
  return {
    type: "GET_ADBIDS_FAILED",
  };
}

export const getBidEvents = () => async (dispatch) => {
  try {
    // const res = await axios.get("http://localhost:3001/api/bid/bid-events");
    const res = await request("get", "GET_ADBID_EVENTS", true);
    dispatch(getBidEventsData(res.data.data));
    dispatch(clearBids());
    // console.log(res.data);
  } catch (err) {
    dispatch(bidError(err));
    console.log(err.message);
  }
};

const getBidEventsData = (res) => {
  return {
    type: "GET_ADBIDEVENTS",
    payload: res,
  };
};

const bidError = (err) => {
  return {
    type: "ADBID_ERROR",
    payload: { msg: err.message },
  };
};

const clearEvents = () => {
  return {
    type: "CLEAR_ADEVENTS",
  };
};

const clearBids = () => {
  return {
    type: "CLEAR_ADBIDS",
  };
};
