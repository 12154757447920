const initialState = {
  bids: [],
  errors: [],
  events: [],
};

export default function (state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case "GET_ADBIDS_SUCCESS":
      return {
        ...state,
        bids: payload,
      };
    case "GET_ADBIDEVENTS":
      return {
        ...state,
        events: payload,
      };
    case "ADBID_ERROR":
      return {
        ...state,
        errors: payload,
      };
    case "CLEAR_ADBIDS":
      return {
        ...state,
        bids: [],
      };
    case "CLEAR_ADEVENTS":
      return {
        ...state,
        events: [],
      };
    default:
      return { ...state };
  }
}
