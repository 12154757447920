const initialState = {
  bids: {
    afterFinalCallBids:[],
    beoreFinalCallBids:[]
  },
  fetchingBids: false,
  errors: [],
  events: [],
};

export default function (state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case "GET_BIDS": {
      return { ...state, fetchingBids: true };
    }
    case "GET_BIDS_SUCCESS": {
      return {
        ...state,
        bids: action.payload.data,
        highestOpenBidAmount:action.payload.highestOpenBidAmount,
        highestHiddenBidAmount:action.payload.highestHiddenBidAmount,
        fetchingBids: false,
      };
    }
    case "GET_BIDS_FAILED": {
      return { ...state, fetchingBids: false };
    }
    case "GET_BIDEVENTS":
      return {
        ...state,
        events: payload,
      };
    case "BID_ERROR":
      return {
        ...state,
        errors: payload,
      };
    case "CLEAR_BIDS":
      return {
        ...state,
        bids: {
          afterFinalCallBids: [],
          beoreFinalCallBids: [],
        },
      };
    case "CLEAR_EVENTS":
      return {
        ...state,
        events: [],
      };
    default:
      return { ...state };
  }
}
