// dependencies import
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

//components import
import Home from "../containers/Home";
// import UserComponent from "../components/UserComponent";
import RegisterForm from "../containers/RegisterForm";
import SignupForm from "../containers/SignupForm";
import LoginForm from "../containers/LoginForm";
import ForgetPasswordComponent from "../containers/ForgetPasswordComponent";
import ResetPassword from "../containers/ResetPassword";

import VerifyEmail from "../containers/verifyEmail";
import OtpPhone from "../containers/otp";
import OtpVerify from "../containers/OtpPhoneVerify";
import EmailVerifyMessage from "../containers/EmailVerifyMessage";
import ContactUs from "../containers/ContactUs";
import ItemAdsContainer from "../components/ItemAds/ItemAdsContainer";

export default class Routes extends React.Component {
  render() {
    return (
      <Switch>
        {/* <Route path="/contactus" exact render={() => <ContactUs />} />
        <Route
          path="/register"
          exact
          render={() =>
            localStorage.getItem("jwt") ? (
              <Redirect to={"/"} />
            ) : (
              <SignupForm />
            )
          }
        />
        <Route
          path="/login"
          exact
          render={() =>
            localStorage.getItem("jwt") ? <Redirect to={"/"} /> : <LoginForm />
          }
        />
        <Route
          path="/forgot-password"
          exact
          render={() =>
            localStorage.getItem("jwt") ? (
              <Redirect to={"/"} />
            ) : (
              <ForgetPasswordComponent />
            )
          }
        />

        <Route
          path="/reset-password/:q"
          exact
          render={() =>
            localStorage.getItem("jwt") ? (
              <Redirect to={"/"} />
            ) : (
              <ResetPassword />
            )
          }
        />

        <Route
          path="/email-verify/:q"
          exact
          render={() =>
            localStorage.getItem("jwt") ? (
              <Redirect to={"/"} />
            ) : (
              <VerifyEmail />
            )
          }
        />
        <Route
          path="/email-verify-message"
          exact
          render={() =>
            localStorage.getItem("jwt") ? (
              <Redirect to={"/"} />
            ) : (
              <EmailVerifyMessage />
            )
          }
        />
        <Route
          path="/otp-phone-verify"
          exact
          render={() =>
            localStorage.getItem("jwt") ? <Redirect to={"/"} /> : <OtpVerify />
          }
        />

        <Route
          path="/otp-phone"
          exact
          render={() =>
            localStorage.getItem("jwt") ? <Redirect to={"/"} /> : <OtpPhone />
          }
        /> */}

        <Route
          render={(props) =>
            (<Home {...props} />)
            // localStorage.getItem("jwt") ? (
            //   <Home {...props} />
            // ) : (
            //   <Redirect to={"/login"} />
            // )
          }
        />
        <Route
          render={() => (
            <div>
              <center>404: Page not found</center>
            </div>
          )}
        />
      </Switch>
    );
  }
}
