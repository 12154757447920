import React, { useEffect, useState } from 'react';
import Badge from '@material-ui/core/Badge';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import NotificationsIcon from '@material-ui/icons/Notifications';
import { Button, ButtonGroup, IconButton, List, ListItem, ListItemText, Popover } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { getNotification, updateNotificationStatus } from '../actions/userDetailsAction';


const NotificationComponent = ({ notifications, dispatch }) => {

    const [openNotification, setOpenNotification] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {
        console.log('Call Notification API')
        const a = setInterval(callNotificationAPI, 1 * 60 * 1000);
        callNotificationAPI();
        return () => {
            console.log('notification clear')
            clearInterval(a)
        }
    }, []);

    const callNotificationAPI = () => {
        dispatch(getNotification())
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const callChangeNotificationStatusAPI = (id, status) => {
        dispatch(updateNotificationStatus({ requestId: id, status }))
    }

    return (
        <>
            <Badge badgeContent={notifications?.list?.filter(i => i.requestStatus !== 'approved').length} color="secondary" style={{ margin: 'auto 20px' }} onClick={handleClick}>
                <NotificationsIcon />
            </Badge>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                style={{ maxHeight: '500px' }}
            >
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    {
                        notifications?.list && notifications?.list?.filter(i => i.requestStatus !== 'approved').length ? notifications?.list.map((n) => (
                            <ListItem
                                key={n._id}
                                style={{ width: '530px', borderBottom: '1px solid black' }}
                            >
                                <ListItemText primary={`${n.message}`} />

                                <Button variant="contained" color="primary" onClick={() => callChangeNotificationStatusAPI(n.requestId, 'approved')} style={{marginRight:10}}>Approve</Button>
                                <Button variant="contained" color="secondary" onClick={() => callChangeNotificationStatusAPI(n.requestId, 'rejected')}>Reject</Button>

                            </ListItem>
                        )) : <ListItem
                            key={'no_notification'}
                            style={{ width: '530px', textAlign: 'center' }}
                        >
                            <ListItemText primary={`No Notifications`} />
                        </ListItem>
                    }
                </List>
            </Popover>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        notifications: state.userDetailsReducer.notifications,
    };
};

export default connect(mapStateToProps)(withRouter(NotificationComponent));
// export default NotificationComponent;