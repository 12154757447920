const initialState = {
  adSpaceDetailsLoading: false,
  userAdSpaceListLoading: false,
  userAdSpaceList: null,
  purchaseDetailsLoading: true,
  purchaseDetails: null,
  adSpaceDetails: null,
  //   chooseSubscriptionPlanLoading: false,
  //   chooseSubscriptionPlanList: [],
  addAdSpacetoCartLoading: false,
  razorPaySuccessCaptureLoading: false,
  razorPayData: {},
};

const adSpaceReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET PRODUCT FEATURES BY USER TYPE

    case "GET_ADSPACE_DETAILS":
      return {
        ...state,
        adSpaceDetailsLoading: true,
      };
    case "GET_ADSPACE_DETAILS_SUCCESS":
      return {
        ...state,
        adSpaceDetailsLoading: false,
        adSpaceDetails: action.payload,
      };
    case "GET_ADSPACE_DETAILS_FAILED":
      return {
        ...state,
        adSpaceDetailsLoading: false,
      };
    //GET PURCHASE INFORMATION
    case "GET_ADSPACE_PURCHASE_DETAILS":
      return {
        ...state,
        purchaseDetailsLoading: true,
      };
    case "GET_ADSPACE_PURCHASE_DETAILS_SUCCESS":
      return {
        ...state,
        purchaseDetailsLoading: false,
        purchaseDetails: action.payload,
      };
    case "GET_ADSPACE_PURCHASE_DETAILS_FAILED":
      return {
        ...state,
        purchaseDetailsLoading: false,
      };

    //GET USERT SUBSCRIPTION LIST
    case "GET_USER_ADSPACE":
      return {
        ...state,
        userAdSpaceListLoading: true,
      };
    case "GET_USER_ADSPACE_SUCCESS":
      return {
        ...state,
        userAdSpaceListLoading: false,
        userAdSpaceList: action.payload,
      };
    case "GET_USER_ADSPACE_FAILED":
      return {
        ...state,
        userAdSpaceListLoading: false,
      };

    // //USER CHOOSE SUBSCRIPTIN PLAN
    // case "USER_CHOOSE_SUBSCRIPTION_PLAN":
    //   return {
    //     ...state,
    //     chooseSubscriptionPlanLoading: true,
    //   };
    // case "USER_CHOOSE_SUBSCRIPTION_PLAN_SUCCESS":
    //   return {
    //     ...state,
    //     chooseSubscriptionPlanLoading: false,
    //     chooseSubscriptionPlanList: action.payload,
    //   };
    // case "USER_CHOOSE_SUBSCRIPTION_PLAN_FAILED":
    //   return {
    //     ...state,
    //     chooseSubscriptionPlanLoading: false,
    //   };

    //USER ADD SUBSCRIPTIO PLAN

    case "ADD_ADSPACE_TO_CART":
      return {
        ...state,
        addAdSpacetoCartLoading: true,
      };
    case "ADD_ADSPACE_TO_CART_SUCCESS":
      return {
        ...state,
        addAdSpacetoCartLoading: false,
        // addAdSpacetoCartDetails: action.payload,
      };
    case "ADD_ADSPACE_TO_CART_FAILED":
      return {
        ...state,
        addAdSpacetoCartLoading: false,
      };

    //RAZOR PAY CAPTURE CCAVENUE

    case "ADSPACE_RAZORPAY_PAYMENT_CAPTURE":
      return {
        ...state,
        razorPaySuccessCaptureLoading: true,
      };
    case "ADSPACE_RAZORPAY_PAYMENT_CAPTURE_SUCCESS":
      return {
        ...state,
        razorPaySuccessCaptureLoading: false,
        razorPayData: action.payload,
      };
    case "ADSPACE_RAZORPAY_PAYMENT_CAPTURE_FAILED":
      return {
        ...state,
        razorPaySuccessCaptureLoading: false,
      };
    default:
      return state;
  }
};

export default adSpaceReducer;
