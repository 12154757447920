//dependencies import
import React, { Component } from "react";
import { connect } from "react-redux";

import Routes from "../Routes/rootRoute";
import { withRouter } from "react-router-dom";
// import "./style.css";
import {
  refillJWT,
  closeDialog,
  closeAvatarModal,
} from "../actions/appActions";

import bindActionCreators from "redux/es/bindActionCreators";
import AlertDialog from "./AlertDialog";
import ImageDialog from "./ImageDialog";
import { SnackbarProvider, withSnackbar } from "notistack";

class App extends Component {
  constructor(props) {
    super(props);
    if (localStorage.getItem("jwt")) {
      this.props.appReducer.snackBar = {
        enqueueSnackbar: this.props.enqueueSnackbar,
        closeSnackbar: this.props.closeSnackbar,
      };
      this.props.refillJWT();
    }
  }
  render() {
    return (
      <React.Fragment>
        {this.props.appReducer.dialog && (
          <AlertDialog
            open={this.props.appReducer.dialog}
            handleClose={() => {
              this.props.closeDialog();
            }}
            text={this.props.appReducer.dialogText}
            description={this.props.appReducer.dialogDescription}
          />
        )}
        {this.props.appReducer.avatarModal && (
          <ImageDialog
            open={this.props.appReducer.avatarModal}
            handleClose={() => {
              this.props.closeAvatarModal();
            }}
            src={this.props.appReducer.imageSrc}
          />
        )}
        <Routes />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    appReducer: state.appReducer,
  };
}

function mapActionsToProps(dispatch) {
  return bindActionCreators(
    {
      refillJWT,
      closeDialog,
      closeAvatarModal,
    },
    dispatch
  );
}

App = connect(mapStateToProps, mapActionsToProps)(App);
const MyApp = withSnackbar(App);

function IntegrationNotistack() {
  return (
    <SnackbarProvider maxSnack={3}>
      <MyApp />
    </SnackbarProvider>
  );
}

// let IntegrationNotistack = <SnackbarProvider maxSnack={3}>
// <MyApp />
// </SnackbarProvider>;
export default withRouter(IntegrationNotistack);
//export default withRouter(connect(mapStateToProps, mapActionsToProps)(IntegrationNotistack));
